import { createSlice } from "@reduxjs/toolkit";
import { IRootState } from "../redux-store/rootReducer";

let Config = {
  selectedThemeColor: "#009ef7",
  orderChanged: true,
  topManuChanged: false,
  bottomManuChanged: false,
  bottomMapOptionDistanceWithOnlyNumber : false,
  topMapOptionDistanceWithOnlyNumber: false,
  sideMapOptionDistanceWithOnlyNumber : false,
  isDivideMapsIntotwoParts: false,
  isUseNumbers: false,
  showImages: false,
  iconChanged: "#009ef7",
  limitchanges: 10,
  serviceChanged: "",
  isGoogleMap:true,
  isOpenStreetMap:true,
  isBingMap:true,
  displayMap: true,
  distanceChanged: true,
  MapPointerIcon: undefined,
  HomePointerIcon: undefined,
  isLogoShow: true,
  isTopHours : false,
  isSideHours : false,
  isMapHours : false,
  isSelectIcon: null,
  isDuplicateRelayPoint : true,
  MobileLook: [
    { label: "Map", value: "Map", id: "map", icon: "/media/avatars/M_Text_New.png" },
    { label: "Horizontal Address Section", value: "Horizontal Address Section", id: "horizontalAddressSection", icon: "/media/avatars/H_Text_New.png" },
    { label: "Vertical Address Section", value: "Vertical Address Section", id: "verticalAddressSection", icon: "/media/avatars/V_Text_New.png" }
  ],
  LanguageSelection: false,
  userLanguage:"",
  bingMapKey: "AjrA_VMdgQRybZoZEgIW8qSgWRwcFoy0qEXiaKSA-u84uQ53nMW8GoXjVQm17j-2",
  googleMapKey:"AIzaSyA54LGF_f6bz3I99GUpbJDATjwKFNKG_D4",
  selectMap : {}
}
let updateThemeIntialValues = {
  desktopConfig: Config,
  mobileConfig: Config
}

const initialState: IRootState = {
  products: [],
  isRelayPoints: false,
  updateTheme: updateThemeIntialValues,
  updateLang: undefined,
  localization: [],
  servicePointList :  []
};

export const ProductSlice = createSlice({
  name: "productReport",
  initialState: initialState,
  reducers: {
    GetProducts: (state, action) => {
      const { data } = action.payload;
      state.products.push(data);
    },
    RemoveProduct: (state, action) => {
      const { ProductData } = action.payload;
      state.products = ProductData;
    },
    GetPopupPermission: (state, action) => {
      const { i } = action.payload;
      state.isRelayPoints = i;
    },
    UpdateTheme: (state, action) => {
      const { updateTheme } = action.payload;
      state.updateTheme = updateTheme
    },
    UpdateLanguage: (state, action) => {
      const { updateLang } = action.payload;
      state.updateTheme = updateLang
    },
    GetServicePointList:(state, action)=>{
      state.servicePointList = action.payload
    }
  }
});