/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Formik } from 'formik'
import { Link } from 'react-router-dom'
import { Product } from '../../Interfaces/ShopifyInterface';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ProductSlice } from '../../../redux/ProductSlice';
import { GetLable } from "../../../Config/GetLabels";
import { config } from "../../../Config/Appsettings";
import { useIntl } from 'react-intl'
const BuilderPage = () => {
  const obj = {
    contact: "",
    description: "",
    fade: "",
    point: ""
  };
  const { actions } = ProductSlice;
  const [isRelayPointsLocalstorage, setIsRelayPointsLocalstorage] = useState(true);
  const data: Product[] = useSelector((state: any) => state?.product?.products, shallowEqual);
  const dispatch = useDispatch();
  const intl = useIntl();

  useEffect(() => {
    let i = false;
    dispatch(actions.GetPopupPermission({ i }));
    let isRelayPoint = localStorage.getItem("isRelayPoints");
    if (isRelayPoint === "true" || isRelayPoint === null) {
      setIsRelayPointsLocalstorage(true);
      localStorage.setItem("isRelayPoints", "true");
    }
    else {
      setIsRelayPointsLocalstorage(false);
      localStorage.setItem("isRelayPoints", "false");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const OnRemove = (item: Product) => {
    let ProductData = data.filter((i) => i.id !== item.id);
    dispatch(actions.RemoveProduct({ ProductData }));
  };

  const OnChange = () => {
    let isRelayPointsValue = localStorage.getItem("isRelayPoints");
    if (isRelayPointsValue === "true") {
      setIsRelayPointsLocalstorage(false);
      localStorage.setItem("isRelayPoints", "false");
    }
    else {
      setIsRelayPointsLocalstorage(true);
      localStorage.setItem("isRelayPoints", "true");
    };
    dispatch(actions.GetPopupPermission({ isRelayPointsValue }));
  };

  return (
    <>
      <Formik
        initialValues={obj}
        onSubmit={() => { }}>
        {({
          values,
          handleChange
        }) => (
          <>
            <div className='row g-5 g-xxl-8'>
              <div className='col-xl-12'>
                <div className='card mb-5 mb-xl-10'>
                  <div className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'>
                    <div className='d-flex flex-wrap flex-stack col-12'>
                      <h3 className='fw-bolder my-2'><GetLable label="ProductList" /></h3>
                      <Link to='/'>
                        <button className="btn btn-primary align-self-center">
                          <GetLable label="BackToProducts" /></button>
                      </Link>
                    </div>
                  </div>
                  <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                      <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" role={"table"}>
                        <thead>
                          <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                            <th></th>
                            <th className="min-w-120px" style={{ cursor: "pointer" }}><GetLable label="Title" /></th>
                            <th className="min-w-120px" style={{ cursor: "pointer" }}><GetLable label="Description" /></th>
                            <th className="min-w-120px" style={{ cursor: "pointer" }}><GetLable label="Price" /></th>
                            <th className="min-w-120px" style={{ cursor: "pointer" }}><GetLable label="Remove" /></th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            data?.map((item, index) => {
                              return (
                                <tr role={"row"} key={index}>
                                  {
                                    item.images.map((item, index) => {
                                      return (
                                        <td key={index}>
                                          <img src={item.src} className="p-3" key={index} height='50px' width='50px' alt="item">
                                          </img>
                                        </td>
                                      )
                                    })
                                  }
                                  <td>
                                    <div className="col-form-label fw-bold fs-6'" role={"cell"}>{item.title}</div>
                                  </td>
                                  <td>
                                    <div className="col-form-label fw-bold fs-6'" role={"cell"}>{item.body_html}</div>
                                  </td>
                                  {
                                    item.variants.map((item, index) => {
                                      return (
                                        <td role={"row"} key={index}>
                                          <div className="col-form-label fw-bold fs-6'" role={"cell"}>{item.price}</div>
                                        </td>
                                      )
                                    })
                                  }
                                  <td className='min-w-120px'>
                                    <button className='btn btn-primary' onClick={() => { OnRemove(item); }}><GetLable label="Remove" /></button>
                                  </td>
                                </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row g-5 g-xxl-8'>
              <div className='col-xl-12'>
                <div className='card mb-5 mb-xl-10'>
                  <div className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'><GetLable label="Summary" /></h3>
                    </div>
                  </div>
                  <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                      <div className='row mb-6'>
                        <label className='col-lg-3 col-form-label fw-bold fs-6'><GetLable label="Contact" /></label>
                        <input
                          type='text' className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 col mb-3'
                          placeholder={config.GetLables(intl, "Contact")}
                          name='contact'
                          value={values.contact}
                          onChange={handleChange} />
                      </div>
                      <div className='row mb-6'>
                        <label className='col-lg-3 col-form-label fw-bold fs-6'><GetLable label="Description" /></label>
                        <input
                          type='textarea' className='form-control form-control-lg form-control-solid mb-3 mb-lg-0 col mb-3'
                          placeholder={config.GetLables(intl, "Description")}
                          name='description'
                          value={values.description}
                          onChange={handleChange} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row g-5 g-xxl-8'>
              <div className='col-xl-12'>
                <div className='card mb-5 mb-xl-10'>
                  <div className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'>
                    <div className='card-title m-0'>
                      <h3 className='fw-bolder m-0'><GetLable label="Mode" /></h3>
                    </div>
                  </div>
                  <div id='kt_account_profile_details' className='collapse show'>
                    <div className='card-body border-top p-9'>
                      <div className='d-flex align-items-center mt-3'>
                        <label className='form-check form-check-inline form-check-solid me-5'>
                          <input className='form-check-input'
                            name='fade'
                            type='checkbox'
                            value={values.fade}
                            onChange={handleChange}
                          />
                          <span className='fw-bold ps-2 fs-6'><GetLable label="DHLFedex" />
                          </span>
                        </label>
                      </div>
                      <div className='d-flex align-items-center mt-3'>
                        <label className='form-check form-check-inline form-check-solid'>
                          <input className='form-check-input'
                            name='point'
                            type='checkbox'
                            value={values.point}
                            onChange={OnChange}
                            checked={isRelayPointsLocalstorage}
                          />
                          <span className='fw-bold ps-2 fs-6'><GetLable label="NearestRelayPoints" /></span>
                        </label>
                      </div>
                      <div className='d-flex justify-content-end py-6 px-9'>
                        <Link to="/map">
                          <button type='submit' className='btn btn-primary'>
                            <span><GetLable label="Choosemyrelaypoint" /></span>
                          </button>
                        </Link>
                      </div>
                      <div className='d-flex justify-content-end py-6 px-9'>
                        <Link to="/usermap">
                          <button type='submit' className='btn btn-primary'>
                            User Page
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </>
  )
}
export { BuilderPage }
