export const Languages: any = {
    de: {
        FindLocation: "Standort finden",
        SearchLocation: "Standort suchen",
        OpenStreetMap: "OpenStreetMap",
        GoogleMap: "Google Maps",
        BingMap: "Bing Maps",
        Products: "Produkte",
        RelayPointLists: "Liste der Abholpunkte",
        Recherche: "Forschung",
        DisplayMap: "Karte anzeigen",
        SelectMap: "Karte auswählen",
        SelectDesign: "Design auswählen",
        SelectColorOfMarker: "Farbe des Markers auswählen",
        Limit: "Limit",
        Carrierservice: "Frachtdienst",
        LocationPanel: "Ortungsfeld",
        RightLeftPanelOption: "Option des rechten / linken Feldes",
        WithImages: "Mit Bildern",
        TopMapOption: "Option der oberen Karte",
        BottomMapOption: "Untere Kartenoption",
        Distance: "Entfernung",
        Desktop: "Desktop",
        Mobile: "Mobil",
        Theme: "Thema",
        DesktopTheme: "Desktop-Thema",
        DesktopConfiguration: "Desktop-Konfiguration",
        MobileConfiguration: "Mobile Konfiguration",
        Yes: "Ja",
        No: "Nein",
        Design1: "Design  1",
        Design2: "Design  2",
        Design3: "Design  3",
        BlueMarker: "Blauer Marker",
        PinkMarker: "Rosa Marker",
        Left: "Links",
        Right: "Rechts",
        Miles: "Meilen",
        KM: "km",
        Meter: "Meters",
        Default: "km",
        Back: "Zurück",
        Save: "Speichern",
        Language: "Sprache",
        MapConfiguration: "Kartenkonfiguration",
        ProductList: "Produktliste",
        GoToCart: "Zum Warenkorb gehen",
        UpdateLogo: "Logo aktualisieren",
        Image: "Bild",
        Name: "Name",
        Actions: "Aktionen",
        SignOut: "Abmelden",
        Title: "Titel",
        Description: "Beschreibung",
        Price: "Preis",
        Remove: "Entfernen",
        Summary: "Zusammenfassung",
        Contact: "Kontakt",
        Mode: "Modus",
        DHLFedex: "DHL / FedEx",
        NearestRelayPoints: "Nächste Abholpunkte",
        Choosemyrelaypoint: "Meinen Abholpunkt wählen",
        BackToProducts: "Zurück zu Produkten",
        ForgotPassword: "Passwort vergessen",
        resetyourpassword: "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zurückzusetzen.",
        Sorry: "Es sieht aus, als ob einige Fehler erkannt wurden, bitte versuchen Sie es erneut.",
        passwordreset: "Passwort zurücksetzen gesendet. Bitte überprüfen Sie Ihre E-Mail",
        Email: "E-Mail",
        Password: "Passwort",
        Submit: "Absenden",
        Cancel: "Abbrechen",
        Pleasewait: "Bitte warten...",
        SignInMetronic: "Anmelden bei Metronic",
        NewHere: "Neu hier?",
        CreateAccount: "Ein Konto erstellen",
        Useaccount: "Verwenden Sie Konto ",
        andpassword: "und Passwort",
        tocontinue: "um fortzufahren.",
        Continue: "Weiter",
        Select: "AUSWÄHLEN",
        selected: "AUSGEWÄHLT",
        RelayPoint: "Abholstation",
        SelectHomeIcon: "Wähle Home-Symbol",
        SelectPointerIcon: "Wähle Zeiger-Symbol",
        Languages: "Sprachen",
        DefaultLanguage: "Standardsprache",
        ManualLanguage: "Handbuchsprache",
        Update: "Aktualisierung",
        ShowLogoOption: "Logo-Option anzeigen",
        View: "Ansicht",
        VerticalAddressSection: "Vertikaler Adressbereich",
        HorizontalAddressSection: "Horizontale Adresssektion",
        RemoveLogo: "Logo entfernen",
        Map: "Karte",
        ServicePoint: "Servicepunkt",
        Services: "Dienstleistungen",
        Sunday: "Sonntag",
        Tuesday: "Dienstag",
        Wednesday: "Mittwoch",
        Thursday: "Donnerstag",
        Friday: "Freitag",
        Saturday: "Samstag",
        Monday: "Montag",
        DisplayTopHours: "Top-Stunden anzeigen",
        DisplaySideHours: "Seitenstunden anzeigen",
        DisplayMapHours: "Kartenstunden anzeigen",
        PleaseEnterLocationToGetnearByRelayPoints: "Bitte geben Sie den Ort ein, um nahegelegene Relaispunkte zu erhalten.",
        DivideMapsIntoTwoParts: "Teilen Sie Karten in zwei Teile",
        UseNumbers : "Verwende Zahlen",
        UserIcon : "Benutzersymbol", 
        RelayPointIcon: "Relaispunkt-Symbol", 
        ServiceIcon : "Servicesymbol", 
        SelectIcon : "Symbol auswählen",
        DistanceWithOnlyANumber : "Entfernung mit nur einer Nummer",
    },
    en: {
        FindLocation: "Find Location",
        SearchLocation: "Search Location",
        OpenStreetMap: "Open Street Map",
        GoogleMap: "Google Map",
        BingMap: "Bing Map",
        Products: "Products",
        RelayPointLists: "Relay Point Lists",
        Recherche: "Research",
        DisplayMap: "Display Map",
        SelectMap: "Select Map",
        SelectDesign: "Select Design",
        SelectColorOfMarker: "Select Color Of Marker",
        Limit: "Limit",
        Carrierservice: "Carrier service",
        LocationPanel: "Location Panel",
        RightLeftPanelOption: "Right / Left Panel Option",
        WithImages: "With Images",
        TopMapOption: "Top Map Option",
        BottomMapOption: "Bottom Map Option",
        Distance: "Distance",
        Desktop: "Desktop",
        Mobile: "Mobile",
        Theme: "Theme",
        DesktopTheme: "Desktop Theme",
        DesktopConfiguration: "Desktop Configuration",
        MobileConfiguration: "Mobile Configuration",
        Yes: "Yes",
        No: "No",
        Design1: "Design 1",
        Design2: "Design 2",
        Design3: "Design 3",
        BlueMarker: "Blue Marker",
        PinkMarker: "Pink Marker",
        Left: "Left",
        Right: "Right",
        KM: "km",
        Meter: "Meters",
        Miles: "Miles",
        Default: "km",
        Back: "Back",
        Save: "Save",
        Language: "Language",
        MapConfiguration: "Map Configuration",
        ProductList: "Product List",
        GoToCart: "Go To Cart",
        UpdateLogo: " Update Logo",
        Image: "Image",
        Name: "Name",
        Actions: "Actions",
        SignOut: "Sign Out",
        Title: "Title",
        Description: "Description",
        Price: "Price",
        Remove: "Remove",
        Summary: "Summary",
        Contact: "Contact",
        Mode: "Mode",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Nearest Relay Points",
        Choosemyrelaypoint: "Choose my relay point",
        BackToProducts: "Back To Products",
        ForgotPassword: "Forgot Password",
        resetyourpassword: "Enter your email to reset your password.",
        Sorry: "Sorry, looks like there are some errors detected, please try again.",
        passwordreset: "Sent password reset. Please check your email",
        Email: "Email",
        Password: "Password",
        Submit: "Submit",
        Cancel: "Cancel",
        Pleasewait: "Please wait...",
        SignInMetronic: "Sign In to Metronic",
        NewHere: "New Here?",
        CreateAccount: "Create an Account",
        Useaccount: "Use account",
        andpassword: "and password",
        tocontinue: "to continue.",
        Continue: "Continue",
        Select: "SELECT",
        selected: "SELECTED",
        RelayPoint: "Relay Point",
        SelectHomeIcon: "Select Home Icon",
        SelectPointerIcon: "Select Pointer Icon",
        Languages: "Languages",
        DefaultLanguage: "Default Language",
        ManualLanguage: "Manual Language",
        Update: "Update",
        ShowLogoOption: "Show Logo Option",
        View: "View",
        VerticalAddressSection: "Vertical Address Section",
        HorizontalAddressSection: "Horizontal Address Section",
        RemoveLogo: "Remove Logo",
        Map: "Map",
        ServicePoint: "Service Point",
        Services: "Services",
        Sunday: "Sunday",
        Tuesday: "Tuesday",
        Wednesday: "Wednesday",
        Thursday: "Thursday",
        Friday: "Friday",
        Saturday: "Saturday",
        Monday: "Monday",
        DisplayTopHours: "Display Top Hours",
        DisplaySideHours: "Display Side Hours",
        DisplayMapHours: "Display Map Hours",
        PleaseEnterLocationToGetnearByRelayPoints: "Please enter location to get nearby relay points.",
        DivideMapsIntoTwoParts: "Divide maps into two parts",
        UseNumbers : "Use Numbers",  
        UserIcon : "User Icon", 
        RelayPointIcon: "Relay Point Icon", 
        ServiceIcon : "Service Icon", 
        SelectIcon : "Select Icon",
        DistanceWithOnlyANumber : "Distance with only a number"
    },
    fr: {
        FindLocation: "Trouver l'emplacement",
        SearchLocation: "Rechercher un emplacement",
        OpenStreetMap: "Open Street Map",
        GoogleMap: "Google Map",
        BingMap: "Bing Map",
        Products: "Produits",
        RelayPointLists: "Listes de points relais",
        Recherche: "Recherche",
        DisplayMap: "Afficher la carte",
        SelectMap: "Sélectionner la carte",
        SelectDesign: "Sélectionner la conception",
        SelectColorOfMarker: "Sélectionner la couleur du marqueur",
        Limit: "Limite",
        Carrierservice: "Service de transporteur",
        LocationPanel: "Panneau d'emplacement",
        RightLeftPanelOption: "Option panneau droit/gauche",
        WithImages: "Avec des images ",
        TopMapOption: "Option de carte supérieure",
        BottomMapOption: "Option de carte en bas",
        Distance: "Distance",
        Desktop: "Bureau",
        Mobile: "Mobile",
        Theme: "Thème",
        DesktopTheme: "Thème de bureau",
        DesktopConfiguration: "Configuration du bureau",
        MobileConfiguration: "Configuration mobile",
        Yes: "Oui",
        No: "Non",
        Design1: "Conception 1",
        Design2: "Conception 2",
        Design3: "Conception 3",
        BlueMarker: "Marqueur bleu",
        PinkMarker: "Marqueur rose",
        Left: "Gauche",
        Right: "Droite",
        KM: "km ",
        Meter: "mètres",
        Miles: "Miles",
        Default: "km",
        Back: "Retour",
        Save: "Enregistrer",
        Language: "Langue",
        MapConfiguration: "Configuration de la carte",
        ProductList: "Liste de produits",
        GoToCart: "Aller au panier",
        UpdateLogo: " Mettre à jour le logo",
        Image: "Image",
        Name: "Nom",
        Actions: "Actions",
        SignOut: "Se déconnecter",
        Title: "Titre",
        Description: "Description",
        Price: "Prix",
        Remove: "Supprimer",
        Summary: "Résumé",
        Contact: "Contact",
        Mode: "Mode",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Points relais les plus proches",
        Choosemyrelaypoint: "Choisir mon point relais",
        BackToProducts: "Retour aux produits",
        ForgotPassword: "Mot de passe oublié",
        resetyourpassword: "Entrez votre e-mail pour réinitialiser votre mot de passe.",
        Sorry: "Désolé, il semble qu'il y ait des erreurs détectées, veuillez réessayer.",
        passwordreset: "Mot de passe réinitialisé envoyé. Veuillez vérifier votre e-mail",
        Email: "Email",
        Password: "Mot de passe",
        Submit: "Soumettre",
        Cancel: "Annuler",
        Pleasewait: "Veuillez patienter...",
        SignInMetronic: "Se connecter à Metronic",
        NewHere: "Nouveau venu?",
        CreateAccount: "Créer un compte",
        Useaccount: "Utiliser le compte",
        andpassword: "et mot de passe",
        tocontinue: "pour continuer.",
        Continue: "continuer",
        Select: "Sélectionner",
        selected: "Sélectionner",
        RelayPoint: "Point Relais",
        SelectHomeIcon: "Sélectionner l'icône Accueil",
        SelectPointerIcon: "Sélectionner l'icône Pointeur",
        Languages: "Langues",
        DefaultLanguage: "Langue par défaut",
        ManualLanguage: "Langue du manuel",
        Update: "Mise à jour",
        ShowLogoOption: " Afficher l'option de logo",
        View: "Vue",
        VerticalAddressSection: "Section d'adresse verticale",
        HorizontalAddressSection: "Section d'adresse horizontale",
        RemoveLogo: "Supprimer le logo",
        Map: "Carte",
        ServicePoint: "Point de service",
        Services: "Services",
        Sunday: "Dimanche",
        Tuesday: "Mardi",
        Wednesday: "Mercredi",
        Thursday: "Jeudi",
        Friday: "Vendredi",
        Saturday: "Samedi",
        Monday: "Lundi",
        DisplayTopHours: "Afficher les heures les plus populaires",
        DisplaySideHours: "Afficher les heures latérales",
        DisplayMapHours: "Afficher les heures sur la carte",
        PleaseEnterLocationToGetnearByRelayPoints: "Pour trouver les points de relais les plus proches, merci de renseigner votre localisation.",
        DivideMapsIntoTwoParts: "Diviser les cartes en deux parties",
        UseNumbers : "Utiliser les chiffres",  
        UserIcon : "Icône utilisateur", 
        RelayPointIcon: "Icône de point de relais", 
        ServiceIcon : "Icône de service", 
        SelectIcon : "Sélectionner l'icône",
        DistanceWithOnlyANumber : "Distance avec seulement un numéro"
    },
    es: {
        FindLocation: "Encontrar ubicacion",
        SearchLocation: "Buscar ubicación",
        OpenStreetMap: "Open Streets Map",
        GoogleMap: "Google Maps",
        BingMap: "Bing Maps",
        Products: "Productos",
        RelayPointLists: "Listas de puntos de relevo",
        Recherche: "Investigación",
        DisplayMap: "Mostrar mapa",
        SelectMap: "Seleccionar mapa",
        SelectDesign: "Seleccionar diseño",
        SelectColorOfMarker: "Seleccionar color del marcador",
        Limit: "Límite",
        Carrierservice: "Servicio de transportista",
        LocationPanel: "Panel de ubicación",
        RightLeftPanelOption: "Opción de panel derecho/izquierdo",
        WithImages: "Con imágenes",
        TopMapOption: "Opción de mapa superior",
        BottomMapOption: "Opción de mapa inferior",
        Distance: "Distancia",
        Desktop: "Escritorio",
        Mobile: "Móvil",
        Theme: "Tema",
        DesktopTheme: "Tema de escritorio",
        DesktopConfiguration: "Configuración de escritorio",
        MobileConfiguration: "Configuración móvil",
        Yes: "Sí",
        No: "No",
        Design1: "Diseño 1",
        Design2: "Diseño 2",
        Design3: "Diseño 3",
        BlueMarker: "marcador azul",
        PinkMarker: "marcador rosa",
        Left: "Izquierdo",
        Right: "Derecho",
        KM: "Kilómetros ",
        Meter: "Metros",
        Miles: "Millas",
        Default: "Kilómetros",
        Back: "Atrás",
        Save: "Guardar",
        Language: "Idioma",
        MapConfiguration: "Configuración del mapa",
        ProductList: "Lista de productos",
        GoToCart: "Ir al carrito",
        UpdateLogo: "Actualizar el logo",
        Image: "Imagen",
        Name: "Nombre",
        Actions: "Acciones",
        SignOut: "Cerrar sesión",
        Title: "Título",
        Description: "Descripción",
        Price: "Precio",
        Remove: "Eliminar",
        Summary: "Resumen",
        Contact: "Contacto",
        Mode: "Modo",
        DHLFedex: "DHL/Fedex",
        NearestRelayPoints: "Puntos de relevo más cercanos",
        Choosemyrelaypoint: "Elegir mi punto de relevo",
        BackToProducts: "Volver a los productos",
        ForgotPassword: "Olvidé mi contraseña",
        resetyourpassword: "Ingrese su correo electrónico para restablecer su contraseña.",
        Sorry: "Lo sentimos, parece que se han detectado algunos errores, inténtalo de nuevo.",
        passwordreset: "Restablecimiento de contraseña enviado. Por favor revise su correo electrónico",
        Email: "Correo electrónico",
        Password: "Contraseña",
        Submit: "Enviar",
        Cancel: "Cancelar",
        Pleasewait: "Por favor espera...",
        SignInMetronic: "Iniciar sesión en Metronic",
        NewHere: "Nuevo aqui?",
        CreateAccount: "Crea una cuenta",
        Useaccount: "Usar cuenta",
        andpassword: "y contraseña",
        tocontinue: "para continuar.",
        Continue: "Continuar",
        Select: "SELECCIONAR",
        selected: "SELECCIONADO",
        RelayPoint: "Punto de Recogida",
        SelectHomeIcon: "Seleccionar ícono de inicio",
        SelectPointerIcon: "Seleccionar ícono de puntero",
        Languages: "Idiomas",
        DefaultLanguage: "Idioma predeterminado",
        ManualLanguage: " Idioma del manual",
        Update: "Actualización",
        ShowLogoOption: "Mostrar opción de logotipo",
        View: "Ver",
        VerticalAddressSection: "Sección de dirección vertical",
        HorizontalAddressSection: "Sección de dirección horizontal",
        RemoveLogo: "Eliminar logotipo",
        Map: "Mapa",
        ServicePoint: "Punto de servicio",
        Services: "Servicios",
        Sunday: "Domingo",
        Tuesday: "Martes",
        Wednesday: "Miércoles",
        Thursday: "Jueves",
        Friday: "Viernes",
        Saturday: "Sábado",
        Monday: "Lunes",
        DisplayTopHours: "Mostrar las horas más concurridas",
        DisplaySideHours: "Mostrar las horas menos concurridas",
        DisplayMapHours: "Mostrar las horas en el mapa",
        PleaseEnterLocationToGetnearByRelayPoints: "Por favor, introduzca la ubicación para obtener puntos de retransmisión cercanos.",
        DivideMapsIntoTwoParts: "Dividir los mapas en dos partes",
        UseNumbers : "Usar números",  
        UserIcon : "Icono de usuario", 
        RelayPointIcon: "Icono de punto de retransmisión", 
        ServiceIcon : "Icono de servicio", 
        SelectIcon : "Seleccionar icono",
        DistanceWithOnlyANumber : "Distancia con solo un número"
    },
    it: {
        FindLocation: "Cerca Luogo",
        SearchLocation: "Cerca Luogo",
        OpenStreetMap: "Mappa di OpenStreet",
        GoogleMap: "Google Maps",
        BingMap: "Mappa Bing",
        Products: "Prodotti",
        RelayPointLists: "Elenco di Punti di Ritiro",
        Recherche: "Ricerca",
        DisplayMap: "Visualizza mappa",
        SelectMap: "Seleziona Mappa",
        SelectDesign: "Seleziona Progettazione",
        SelectColorOfMarker: "Seleziona Colore del Segnalino",
        Limit: "Limite",
        Carrierservice: "Servizio di Trasporto",
        LocationPanel: "Pannello di Localizzazione",
        RightLeftPanelOption: "Opzione Pannello Destra / Sinistra",
        WithImages: "Con Immagini",
        TopMapOption: "Opzione Mappa Principale",
        BottomMapOption: "Opzione mappa in basso",
        Distance: "Distanza",
        Desktop: "Desktop",
        Mobile: "Mobile",
        Theme: "Tema",
        DesktopTheme: "Tema desktop",
        DesktopConfiguration: "Configurazione Desktop",
        MobileConfiguration: "Configurazione mobile",
        Yes: "SÌ",
        No: "NO",
        Design1: "Design  1",
        Design2: "Design  2",
        Design3: "Design  3",
        BlueMarker: "Segnalino Blu",
        PinkMarker: "Segnalino Rosa",
        Left: "Sinistra",
        Right: "Destro",
        KM: "Chilometri",
        Meter: "MetrI",
        Miles: "Miglia",
        Default: "Chilometri",
        Back: "Indietro",
        Save: "Salva",
        Language: "Lingua",
        MapConfiguration: "Configurazione Mappa",
        ProductList: "Lista Prodotti",
        GoToCart: "Vai al Carrello",
        UpdateLogo: "Aggiorna logo",
        Image: "Immagine",
        Name: "Nome",
        Actions: "Azioni",
        SignOut: "Esci",
        Title: "Titolo",
        Description: "Descrizione",
        Price: "Prezzo",
        Remove: "Rimuovi",
        Summary: "Sommario",
        Contact: "Contatto",
        Mode: "Modalità",
        DHLFedex: "DHL/ Fedex",
        NearestRelayPoints: "Punti di Ritiro più Vicini",
        Choosemyrelaypoint: "Scegli il mio punto di ritiro",
        BackToProducts: "Torna ai prodotti",
        ForgotPassword: "Password Dimenticata",
        resetyourpassword: "Inserisci la tua email per reimpostare la password.",
        Sorry: "Spiacenti, sembra che siano stati rilevati alcuni errori, riprova.",
        passwordreset: "Reimpostazione della password inviata. Si prega di controllare la tua email",
        Email: "Email",
        Password: "Password",
        Submit: "Invia",
        Cancel: "Annulla",
        Pleasewait: "Attendi...",
        SignInMetronic: "Accedi a Metronic",
        NewHere: "Nuovo qui?",
        CreateAccount: "Creare un account",
        Useaccount: "Usa conto",
        andpassword: "e parola d'ordine",
        tocontinue: "continuare.",
        Continue: "Continua",
        Select: "SELEZIONA",
        selected: "SELEZIONATO",
        RelayPoint: "Punto di Ritiro",
        SelectHomeIcon: "Seleziona icona Home",
        SelectPointerIcon: "Seleziona icona Puntatore",
        Languages: "Lingue",
        DefaultLanguage: "Lingua predefinita",
        ManualLanguage: "Lingua del manuale",
        Update: "Aggiornamento",
        ShowLogoOption: "Mostra opzione logo",
        View: "Visualizza",
        VerticalAddressSection: "Sezione indirizzo verticale",
        HorizontalAddressSection: "Sezione indirizzo orizzontale",
        RemoveLogo: "Rimuovi logo",
        Map: "Mappa",
        ServicePoint: "Punto di servizio",
        Services: "Servizi",
        Sunday: "Domenica",
        Tuesday: "Martedì",
        Wednesday: "Mercoledì",
        Thursday: "Giovedì",
        Friday: "Venerdì",
        Saturday: "Sabato",
        Monday: "Lunedì",
        DisplayTopHours: "Visualizza le ore migliori",
        DisplaySideHours: "Visualizza le ore laterali",
        DisplayMapHours: "Visualizza le ore della mappa",
        PleaseEnterLocationToGetnearByRelayPoints: "Inserisci la posizione per ottenere i punti di inoltro nelle vicinanze.",
        DivideMapsIntoTwoParts: "Dividere le mappe in due parti",
        UseNumbers : "Usa numeri",  
        UserIcon : "Icona utente", 
        RelayPointIcon: "Icona del punto di inoltro", 
        ServiceIcon : "Icona di servizio", 
        SelectIcon : "Seleziona icona",
        DistanceWithOnlyANumber : "Distanza con solo un numero"
    },
    el: {
        FindLocation: "Εύρεση Τοποθεσίας",
        SearchLocation: "Αναζήτηση Τοποθεσίας",
        OpenStreetMap: "Άνοιγμα Χάρτη Οδών",
        GoogleMap: "Χάρτης Google",
        BingMap: "Χάρτης Bing",
        Products: "Προϊόντα",
        RelayPointLists: "Λίστες σημείων αναμετάδοσης",
        Recherche: "Ερευνα",
        DisplayMap: "Εμφάνιση χάρτη",
        SelectMap: "Επιλέξτε Χάρτης",
        SelectDesign: "Επιλέξτε Σχεδίαση",
        SelectColorOfMarker: "Επιλέξτε Color Of Marker",
        Limit: "Οριο",
        Carrierservice: "Υπηρεσία μεταφορέα",
        LocationPanel: "Πίνακας τοποθεσίας",
        RightLeftPanelOption: "Επιλογή Δεξιού / Αριστερού Πίνακα",
        WithImages: "με εικόνες",
        TopMapOption: "Κορυφαία επιλογή χάρτη",
        BottomMapOption: "Κάτω επιλογή χάρτη (Káto epilogí chárti)",
        Distance: "Απόσταση",
        Desktop: "Επιφάνεια εργασίας",
        Mobile: "Κινητό",
        Theme: "Θέμα",
        DesktopTheme: "Θέμα επιφάνειας εργασίας",
        DesktopConfiguration: "Διαμόρφωση Επιφάνειας Εργασίας",
        MobileConfiguration: "Διαμόρφωση Κινητού",
        Yes: "Ναί",
        No: "Οχι",
        Design1: "Σχεδιασμός 1",
        Design2: "Σχεδιασμός 2",
        Design3: "Σχεδιασμός 3",
        BlueMarker: "Μπλε μαρκαδόρος",
        PinkMarker: "Ροζ μαρκαδόρος",
        Left: "Αριστερά",
        Right: "σωστά",
        KM: "Χιλιόμετρα ",
        Meter: "Μετρητής",
        Miles: "Μίλια",
        Default: "Χιλιόμετρα",
        Back: "Πίσω",
        Save: "Αποθηκεύσετε",
        Language: "Γλώσσα",
        MapConfiguration: "Διαμόρφωση χάρτη",
        ProductList: "Λίστα προϊόντων",
        GoToCart: "Μετάβαση στο καλάθι",
        UpdateLogo: "Ενημέρωση λογότυπου",
        Image: "Εικόνα",
        Name: "Ονομα",
        Actions: "Ενέργειες",
        SignOut: "Αποσύνδεση",
        Title: "Τίτλος",
        Description: "Περιγραφή",
        Price: "Τιμή",
        Remove: "Αφαιρώ",
        Summary: "Περίληψη",
        Contact: "Επικοινωνία",
        Mode: "Τρόπος",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Πλησιέστερα σημεία αναμετάδοσης",
        Choosemyrelaypoint: "Επιλέξτε το σημείο αναμετάδοσης μου",
        BackToProducts: "Επιστροφή στα Προϊόντα",
        ForgotPassword: "Ξεχάσατε τον κωδικό",
        resetyourpassword: "Εισάγετε το email σας για να επαναφέρετε τον κωδικό πρόσβασής σας.",
        Sorry: "Λυπούμαστε, φαίνεται ότι εντοπίστηκαν κάποια σφάλματα, δοκιμάστε ξανά.",
        passwordreset: "Απεστάλη επαναφορά κωδικού πρόσβασης. Παρακαλούμε ελέγξτε το email σας",
        Email: "ΗΛΕΚΤΡΟΝΙΚΗ ΔΙΕΥΘΥΝΣΗ",
        Password: "Κωδικός πρόσβασης",
        Submit: "υποβάλλουν",
        Cancel: "Ματαίωση",
        Pleasewait: "Παρακαλώ περιμένετε...",
        SignInMetronic: "Συνδεθείτε στη Metronic",
        NewHere: "ΝΕΟΣ εδω?",
        CreateAccount: "Δημιουργία λογαριασμού",
        Useaccount: "Χρήση λογαριασμού",
        andpassword: "και κωδικό πρόσβασης",
        tocontinue: "να συνεχίσει.",
        Continue: "Να συνεχίσει",
        Select: "ΕΠΙΛΕΞΤΕ",
        selected: "ΕΠΙΛΕΓΜΕΝΟΣ",
        RelayPoint: "Σημείο Αναμετάδοσης (Simeío Anametádosis)",
        SelectHomeIcon: "Επιλογή εικονιδίου Αρχικής σελίδας (Epilogí eikonidíou Archikís selídas)",
        SelectPointerIcon: "Επιλογή εικονιδίου δείκτη (Epilogí eikonidíou deíkti)",
        Languages: "Γλώσσες (Glósses)",
        DefaultLanguage: "Προεπιλεγμένη γλώσσα (Proepilegméni glóssa)",
        ManualLanguage: "Γλώσσα εγχειριδίου (Glóssa encheiridíou)",
        Update: "Ενημέρωση (Enimérosi)",
        ShowLogoOption: "Εμφάνιση επιλογής λογότυπου (Emfánisi epilogís logótypou)",
        View: "Προβολή (Provolí)",
        VerticalAddressSection: "Κατακόρυφη ενότητα διεύθυνσης (Katakóryfi enótita diefthýnsis)",
        HorizontalAddressSection: "Οριζόντια ενότητα διεύθυνσης (Orizóntia enótita diefthýnsis)",
        RemoveLogo: "Αφαίρεση λογότυπου (Aféresi logótypou)",
        Map: "Χάρτης (Chártis)",
        ServicePoint: "Σημείο εξυπηρέτησης (Simeío exypērétēsis)",
        Services: " Υπηρεσίες (Ypiresíes)",
        Sunday: "Κυριακή",
        Tuesday: "Τρίτη",
        Wednesday: "Τετάρτη",
        Thursday: "Πέμπτη",
        Friday: "Παρασκευή",
        Saturday: "Σάββατο",
        Monday: "Δευτέρα",
        DisplayTopHours: "Εμφάνιση Ώρων Κορυφής",
        DisplaySideHours: "Εμφάνιση Ώρων Πλευράς",
        DisplayMapHours: "Εμφάνιση Ώρων Χάρτη",
        PleaseEnterLocationToGetnearByRelayPoints: "Παρακαλώ εισάγετε τοποθεσία για να λάβετε κοντινά σημεία αποκλεισμού.",
        DivideMapsIntoTwoParts: "Διαίρεση χαρτών σε δύο μέρη",
        UseNumbers : "Χρήση αριθμών",  
        UserIcon : "Εικονίδιο χρήστη", 
        RelayPointIcon: "Εικονίδιο σημείου διαμεταφοράς", 
        ServiceIcon : "Εικονίδιο υπηρεσίας", 
        SelectIcon : "Επιλογή εικονιδίου",
        DistanceWithOnlyANumber : "Απόσταση με μόνο έναν αριθμό"
    },
    pt: {
        FindLocation: "Encontrar localização",
        SearchLocation: "Pesquisar Localização",
        OpenStreetMap: "Open Street Map",
        GoogleMap: "Google Map",
        BingMap: "Bing Map",
        Products: "Produtos",
        RelayPointLists: "Lista de Pontos de Revezamento",
        Recherche: "Pesquisar",
        DisplayMap: "Exibir Mapa",
        SelectMap: "Selecionar Mapa",
        SelectDesign: "Selecione o desenho",
        SelectColorOfMarker: "Selecione a cor do marcador",
        Limit: "Limite",
        Carrierservice: "serviço de transportadora",
        LocationPanel: "Painel de Localização",
        RightLeftPanelOption: "Opção de painel direito/esquerdo",
        WithImages: "Com Imagens",
        TopMapOption: "Opção de mapa superior",
        BottomMapOption: "Opção de mapa inferior",
        Distance: "Distância",
        Desktop: "Área de Trabalho",
        Mobile: "Móvel",
        Theme: "Tema",
        DesktopTheme: "Tema de Desktop",
        DesktopConfiguration: "Configuração de Desktop",
        MobileConfiguration: "Configuração móvel",
        Yes: "Sim",
        No: "Não",
        Design1: "Design  1",
        Design2: "Design  2",
        Design3: "Design  3",
        BlueMarker: "Marcador Azul",
        PinkMarker: "Marcador Rosa",
        Left: "Esquerdo",
        Right: "Direita",
        KM: "Kilômetros ",
        Meter: "Metros",
        Miles: "Milhas",
        Default: "Quilômetros",
        Back: "Voltar",
        Save: "Salvar",
        Language: "Idioma",
        MapConfiguration: "Configuração do mapa",
        ProductList: "Lista de produtos",
        GoToCart: "Ir para o carrinho",
        UpdateLogo: "Atualizar Logo",
        Image: "Imagem",
        Name: "Nome",
        Actions: "Ações",
        SignOut: "Sair",
        Title: "Título",
        Description: "Descrição",
        Price: "Preço",
        Remove: "Remover",
        Summary: "Resumo",
        Contact: "Contato",
        Mode: "Modo",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Pontos de Revezamento Mais Próximos",
        Choosemyrelaypoint: "Escolha meu ponto de retransmissão",
        BackToProducts: "Voltar aos produtos",
        ForgotPassword: "Esqueceu sua senha",
        resetyourpassword: "Digite seu e-mail para redefinir sua senha.",
        Sorry: "Desculpe, parece que alguns erros foram detectados, tente novamente.",
        passwordreset: "Senha redefinida enviada. Por favor, verifique seu e-mail",
        Email: "E-mail",
        Password: "Senha",
        Submit: "Enviar",
        Cancel: "Cancelar",
        Pleasewait: "Por favor, aguarde...",
        SignInMetronic: "Entrar no Metronic",
        NewHere: "Novo aqui?",
        CreateAccount: "Crie a sua conta aqui",
        Useaccount: "Usar conta",
        andpassword: "e senha",
        tocontinue: "continuar.",
        Continue: "Continuar",
        Select: "SELECIONAR",
        selected: "SELECIONADO",
        RelayPoint: "Ponto de Recolha",
        SelectHomeIcon: "Selecionar ícone de Início",
        SelectPointerIcon: "Selecionar ícone de ponteiro",
        Languages: "Idiomas",
        DefaultLanguage: "Idioma padrão",
        ManualLanguage: " Idioma do manual",
        Update: "Atualização",
        ShowLogoOption: "Mostrar opção de logotipo",
        View: "Visualizar",
        VerticalAddressSection: "Seção de endereço vertical",
        HorizontalAddressSection: "Seção de endereço horizontal",
        RemoveLogo: "Remover logotipo",
        Map: "Mapa",
        ServicePoint: "Ponto de serviço",
        Services: "Serviços",
        Sunday: "Domingo",
        Tuesday: "Terça-feira",
        Wednesday: "Quarta-feira",
        Thursday: "Quinta-feira",
        Friday: "Sexta-feira",
        Saturday: "Sábado",
        Monday: "Segunda-feira",
        DisplayTopHours: "Exibir Horas Principais",
        DisplaySideHours: "Exibir Horas Laterais",
        DisplayMapHours: "Exibir Horas do Mapa",
        PleaseEnterLocationToGetnearByRelayPoints: "Por favor, insira o local para obter pontos de retransmissão próximos.",
        DivideMapsIntoTwoParts: "Dividir mapas em duas partes",
        UseNumbers : "Usar números",  
        UserIcon : "Ícone de usuário", 
        RelayPointIcon: "Ícone de ponto de retransmissão", 
        ServiceIcon : "Ícone de serviço", 
        SelectIcon : "Selecionar ícone",
        DistanceWithOnlyANumber : "Distância com apenas um número"
    },
    bg: {
        FindLocation: "Намерете местоположение",
        SearchLocation: "Търсете местоположение",
        OpenStreetMap: "Отвори карта на улиците",
        GoogleMap: "Google карти",
        BingMap: "Bing карти",
        Products: "Продукти",
        RelayPointLists: "Списъци с релейни точки",
        Recherche: "Проучване",
        DisplayMap: "Показване на карта",
        SelectMap: "Изберете Карта",
        SelectDesign: "Изберете Дизайн",
        SelectColorOfMarker: "Изберете Цвят на маркера",
        Limit: "Лимит",
        Carrierservice: "Куриерска услуга",
        LocationPanel: "Панел за местоположени",
        RightLeftPanelOption: "Десен / Ляв Панелен избор",
        WithImages: "С изображения",
        TopMapOption: "Горен избор на карта",
        BottomMapOption: "Долна опция за карта (Dolna optsiya za karta)",
        Distance: "Дистанция",
        Desktop: "Настолен компютър",
        Mobile: "Мобилен",
        Theme: "Тема",
        DesktopTheme: "Тема за работен плот",
        DesktopConfiguration: "Конфигурация за настолен компютър",
        MobileConfiguration: "Мобилна конфигурация",
        Yes: "да",
        No: "Не",
        Design1: "Дизайн 1",
        Design2: "Дизайн 2",
        Design3: "Дизайн 3",
        BlueMarker: "Син маркер",
        PinkMarker: "Розов маркер",
        Left: "Ляво",
        Right: "Дясно",
        KM: "километри ",
        Meter: "Метри",
        Miles: "Майлс",
        Default: "километри",
        Back: "обратно",
        Save: "Запазване",
        Language: "език",
        MapConfiguration: "Конфигурация на картата",
        ProductList: "Списък с продукти",
        GoToCart: "Отидете в кошницата",
        UpdateLogo: "Актуализиране на логото",
        Image: "Изображение",
        Name: "Име",
        Actions: "Действия",
        SignOut: "Отписване",
        Title: "Заглавие",
        Description: "Описание",
        Price: "Цена",
        Remove: "Премахване",
        Summary: "Резюме",
        Contact: "Контакт",
        Mode: "Режим",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Най-близки реле точки",
        Choosemyrelaypoint: "Изберете моята релейна точка",
        BackToProducts: "Назад към продуктите",
        ForgotPassword: "Забравена парола",
        resetyourpassword: "Въведете своя имейл, за да нулирате паролата си.",
        Sorry: "Съжаляваме, изглежда, че има открити грешки. Моля, опитайте отново.",
        passwordreset: "Изпратено нулиране на паролата. Моля, проверете електронната си поща",
        Email: "електронна поща",
        Password: "Парола",
        Submit: "Изпращане",
        Cancel: "Отказ",
        Pleasewait: "Моля Изчакай...",
        SignInMetronic: "Влезте в Metronic",
        NewHere: "Нов тук?",
        CreateAccount: "Създай профил",
        Useaccount: "Използвайте акаунт",
        andpassword: "и парола",
        tocontinue: "продължавам.",
        Continue: "продължи",
        Select: "ИЗБЕРЕТЕ",
        selected: "ИЗБРАНИ",
        RelayPoint: " Пункт за Предаване (Punkt za Predavane)",
        SelectHomeIcon: "Изберете икона за начална страница (Izberete ikona za nachalna stranitsa)",
        SelectPointerIcon: "Изберете икона за показалец (Izberete ikona za pokazatelts)",
        Languages: "Езици (Ezitsi)",
        DefaultLanguage: "Основен език (Osnoven ezik)",
        ManualLanguage: "Език на ръководството (Ezik na rǎkovodstvoto)",
        Update: "Актуализация (Aktualizatsiya)",
        ShowLogoOption: "Показване на опцията за лого (Pokazvane na optsiyata za logo)",
        View: "Преглед (Pregled)",
        VerticalAddressSection: "Вертикална секция за адрес (Vertikalna sektsiya za adres)",
        HorizontalAddressSection: "Хоризонтална секция за адрес (Horizontalna sektsiya za adres)",
        RemoveLogo: "Премахване на логото (Premahvane na logoto)",
        Map: "Карта (Karta)",
        ServicePoint: "Обслужващ пункт (Obsluzhvasht punkt)",
        Services: "Услуги (Uslugi)",
        Sunday: "Неделя",
        Tuesday: "Вторник",
        Wednesday: "Сряда",
        Thursday: "Четвъртък",
        Friday: "Петък",
        Saturday: "Събота",
        Monday: "понеделник",
        DisplayTopHours: "Показване на най-добрите часове",
        DisplaySideHours: "Показване на страничните часове",
        DisplayMapHours: "Показване на часовете на картата",
        PleaseEnterLocationToGetnearByRelayPoints: "Моля, въведете местоположение, за да получите близки ретранслационни точки.",
        DivideMapsIntoTwoParts: "Разделете картите на две части",
        UseNumbers : "Използвайте числа",  
        UserIcon : "Икона на потребител", 
        RelayPointIcon: "Икона на точка за усилване", 
        ServiceIcon : "Икона на услуга", 
        SelectIcon : "Изберете икона",
        DistanceWithOnlyANumber : "Разстояние само с число"
    },
    sv: {
        FindLocation: "Hitta plats",
        SearchLocation: "Sök plats",
        OpenStreetMap: "Open Street Map",
        GoogleMap: "Google Maps",
        BingMap: "Bing Maps",
        Products: "Produkter",
        RelayPointLists: "Reläpoänglistor",
        Recherche: "Forskning",
        DisplayMap: "Visa karta",
        SelectMap: "Välj Karta",
        SelectDesign: "Välj Design",
        SelectColorOfMarker: "Välj Markeringsfärg",
        Limit: "Begränsning",
        Carrierservice: "Transporttjänst",
        LocationPanel: "Platspanel",
        RightLeftPanelOption: "Höger/vänster panelalternativ",
        WithImages: "Med bilder",
        TopMapOption: "Övre Kartalternativ",
        BottomMapOption: "Nedre kartalternativ",
        Distance: "Avstånd",
        Desktop: "Stationär dator",
        Mobile: "Mobil",
        Theme: "Tema",
        DesktopTheme: "Stationär datorstema",
        DesktopConfiguration: "Stationär dator-konfiguration",
        MobileConfiguration: "Mobilkonfiguration",
        Yes: "Ja",
        No: "Nej",
        Design1: "Design 1",
        Design2: "Design 2",
        Design3: "Design 3",
        BlueMarker: "Blå markör",
        PinkMarker: "Rosa markör",
        Left: "Vänster",
        Right: "Höger",
        KM: "km ",
        Meter: "Meters",
        Miles: "Miles",
        Default: "km",
        Back: "Tillbaka",
        Save: "Spara",
        Language: "Språk",
        MapConfiguration: "Kartkonfiguration",
        ProductList: "Produktlista",
        GoToCart: "Gå till varukorgen",
        UpdateLogo: "Uppdatera logotyp",
        Image: "Bild",
        Name: "namn",
        Actions: "Handlingar",
        SignOut: "Logga ut",
        Title: "Titel",
        Description: "Beskrivning",
        Price: "Pris",
        Remove: "Avlägsna",
        Summary: "Sammanfattning",
        Contact: "Kontakt",
        Mode: "Läge",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Närmaste reläpunkter",
        Choosemyrelaypoint: "Välj min reläpunkt",
        BackToProducts: "Tillbaka till produkter",
        ForgotPassword: "Glömt ditt lösenord",
        resetyourpassword: "Ange din e-postadress för att återställa ditt lösenord.",
        Sorry: "Tyvärr, det verkar som om några fel har upptäckts, försök igen.",
        passwordreset: "Skickat lösenordsåterställning. Kontrollera din e-post",
        Email: "E-post",
        Password: "Lösenord",
        Submit: "Skicka in",
        Cancel: "Annullera",
        Pleasewait: "Vänta...",
        SignInMetronic: "Logga in på Metronic",
        NewHere: "Ny här?",
        CreateAccount: "Skapa ett konto",
        Useaccount: "Använd konto",
        andpassword: "och lösenord",
        tocontinue: "att fortsätta.",
        Continue: "Fortsätta",
        Select: "VÄLJ",
        selected: "VALD",
        RelayPoint: "Hämtställe",
        SelectHomeIcon: "Välj hemikon",
        SelectPointerIcon: "Välj pekarikon",
        Languages: "Språk",
        DefaultLanguage: "Standardspråk",
        ManualLanguage: "Manualspråk",
        Update: "Uppdatering",
        ShowLogoOption: "Visa logotypsalternativ",
        View: "Visa",
        VerticalAddressSection: "Vertikal adresssektion",
        HorizontalAddressSection: "Horisontell adresssektion",
        RemoveLogo: "Ta bort logotypen",
        Map: "Karta",
        ServicePoint: "Servicepunkt",
        Services: "Tjänster",
        Sunday: "Söndag",
        Tuesday: "Tisdag",
        Wednesday: "Onsdag",
        Thursday: "Torsdag",
        Friday: "Fredag",
        Saturday: "Lördag",
        Monday: "måndag",
        DisplayTopHours: "Visa topptimmar",
        DisplaySideHours: "Visa sidotimmar",
        DisplayMapHours: "Visa karttimmar",
        PleaseEnterLocationToGetnearByRelayPoints: "Ange plats för att få närliggande omkopplingspunkter.",
        DivideMapsIntoTwoParts: "Dela upp kartor i två delar",
        UseNumbers : "Använd siffror",  
        UserIcon : "Användarikon", 
        RelayPointIcon: "Symbol för omkopplingspunkt", 
        ServiceIcon : "Serviceikon", 
        SelectIcon : "Välj ikon",
        DistanceWithOnlyANumber : "Avstånd med bara ett nummer"
    },
    ro: {
        FindLocation: "Găsiți locația",
        SearchLocation: "Caută locație",
        OpenStreetMap: "Harta Străzii Deschisă",
        GoogleMap: "Harta Google",
        BingMap: "Harta Bing",
        Products: "Produse",
        RelayPointLists: "Liste de puncte de releu",
        Recherche: "Cercetare",
        DisplayMap: "Afișează harta",
        SelectMap: "Selectați Hartă",
        SelectDesign: "Selectați design-ul",
        SelectColorOfMarker: "Selectați culoarea markerului",
        Limit: "Limită",
        Carrierservice: "Serviciu transportator",
        LocationPanel: "Panoul de locație",
        RightLeftPanelOption: "Opțiunea panoului dreapta/stânga",
        WithImages: "Cu Imagini",
        TopMapOption: "Opțiunea hărții de top",
        BottomMapOption: "Opțiune hartă jos",
        Distance: "Distanţă",
        Desktop: "Desktop",
        Mobile: "Mobil",
        Theme: "Temă",
        DesktopTheme: "Tema desktop",
        DesktopConfiguration: "Configurare desktop",
        MobileConfiguration: "Configurație mobilă",
        Yes: "Da",
        No: "Nu",
        Design1: "Design  1",
        Design2: "Design  2",
        Design3: "Design 3",
        BlueMarker: "Marker albastru",
        PinkMarker: "Marker roz",
        Left: "Stânga",
        Right: "Dreapta",
        KM: "km ",
        Meter: "Metrus",
        Miles: "Mile",
        Default: "km",
        Back: "Înapoi",
        Save: "Salvați",
        Language: "Limba",
        MapConfiguration: "Configurare hartă",
        ProductList: "Lista de produse",
        GoToCart: "Mergi la Coș",
        UpdateLogo: "Actualizați logo-ul",
        Image: "Imagine",
        Name: "Nume",
        Actions: "Acțiuni",
        SignOut: "Sign out",
        Title: "Titlu",
        Description: "Deconectare",
        Price: "Preț",
        Remove: "Elimina",
        Summary: "rezumat",
        Contact: "Contact",
        Mode: "Mod",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Cele mai apropiate puncte de releu",
        Choosemyrelaypoint: "Alege punctul meu de releu",
        BackToProducts: "Înapoi la produse",
        ForgotPassword: "Aţi uitat parola",
        resetyourpassword: "Introduceți adresa de e-mail pentru a vă reseta parola.",
        Sorry: "Ne pare rău, se pare că au fost detectate unele erori, vă rugăm să încercați din nou.",
        passwordreset: "S-a trimis resetarea parolei. Vă rugăm să verificați email-ul",
        Email: "E-mail",
        Password: "Parola",
        Submit: "Trimite",
        Cancel: "Anulare",
        Pleasewait: "Vă rugăm să așteptați...",
        SignInMetronic: "Conectați-vă la Metronic",
        NewHere: "Nou aici?",
        CreateAccount: "Creați un cont",
        Useaccount: "Utilizați contul",
        andpassword: "si parola",
        Continue: "Continua",
        Select: "SELECTAȚI",
        selected: "SELECTAT",
        RelayPoint: "Punct de Releu",
        SelectHomeIcon: "Selectează pictograma Acasă",
        SelectPointerIcon: "Selectează pictograma Indicator",
        Languages: "Limbi",
        DefaultLanguage: "Limbă implicită",
        ManualLanguage: "Limbă manual",
        Update: "Actualizare",
        ShowLogoOption: "Afișează opțiunea de logo",
        View: "Vizualizare",
        VerticalAddressSection: "Secțiunea verticală de adresă",
        HorizontalAddressSection: "Secțiunea orizontală de adresă",
        RemoveLogo: "Elimină logo-ul",
        Map: "Hartă",
        ServicePoint: "Punct de service",
        Services: "Servicii",
        Sunday: "Duminică",
        Tuesday: "Marți",
        Wednesday: "Miercuri",
        Thursday: "Joi",
        Friday: "Vineri",
        Saturday: "Sâmbătă",
        Monday: "luni",
        DisplayTopHours: "Afișare ore de vârf",
        DisplaySideHours: "Afișare ore laterale",
        DisplayMapHours: "Afișare ore pe hartă",
        PleaseEnterLocationToGetnearByRelayPoints: "Introduceți locația pentru a obține punctele de retransmisie din apropiere.",
        DivideMapsIntoTwoParts: "Împărțiți hărțile în două părți",
        UseNumbers : "Utilizați numerele",  
        UserIcon : "Pictogramă utilizator", 
        RelayPointIcon: "Pictogramă punct de retransmisie", 
        ServiceIcon : "Pictogramă serviciu", 
        SelectIcon : "Selectați pictogramă",
        DistanceWithOnlyANumber : "Distanța cu doar un număr"
    },
    hr: {
        FindLocation: "Pronađite lokaciju",
        SearchLocation: "Pretraži lokaciju",
        OpenStreetMap: "Open Street Map",
        GoogleMap: "Google karta",
        BingMap: "Bing karta",
        Products: "Proizvodi",
        RelayPointLists: "Popis Relay točaka",
        Recherche: "Istraživanje",
        DisplayMap: "Prikaz karte",
        SelectMap: "Odaberite Karta",
        SelectDesign: "Odaberite Dizajn",
        SelectColorOfMarker: "Odaberite boju markera",
        Limit: "Ograničenje",
        Carrierservice: "Prijevozna usluga",
        LocationPanel: "Panel s lokacijama",
        RightLeftPanelOption: "Opcija desnog/lijevog panela",
        WithImages: "S slikama",
        TopMapOption: "Opcija vrha karte",
        BottomMapOption: "Donja opcija karte",
        Distance: "Udaljenost",
        Desktop: "Radna površina",
        Mobile: "Mobilni uređaj",
        Theme: "Tema",
        DesktopTheme: "Tema radne površine",
        DesktopConfiguration: "Konfiguracija radne površine",
        MobileConfiguration: "Konfiguracija mobilnog uređaja",
        Yes: "Da",
        No: "Ne",
        Design1: "Dizajn  1",
        Design2: "Dizajn  2",
        Design3: "Dizajn  3",
        BlueMarker: "Plavi marker",
        PinkMarker: "Ružičasti marker",
        Left: "Lijevo",
        Right: "Desno",
        KM: "km",
        Meter: "Metars",
        Miles: "Miles",
        Default: "km",
        Back: "leđa",
        Save: "Uštedjeti",
        Language: "Jezik",
        MapConfiguration: "Konfiguracija karte",
        ProductList: "Popis proizvoda",
        GoToCart: "Idi u košaricu",
        UpdateLogo: "Ažuriraj logo",
        Image: "Slika",
        Name: "Ime",
        Actions: "Radnje",
        SignOut: "Odjavi",
        Title: "Naslov",
        Description: "Opis",
        Price: "Cijena",
        Remove: "Ukloniti",
        Summary: "Sažetak",
        Contact: "Kontakt",
        Mode: "Način rada",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Najbliže relejne točke",
        Choosemyrelaypoint: "Odaberite moju relejnu točku",
        BackToProducts: "Natrag na proizvode",
        ForgotPassword: "Zaboravili ste lozinku",
        resetyourpassword: "Unesite svoju e-poštu za poništavanje lozinke.",
        Sorry: "Nažalost, izgleda da su otkrivene neke pogreške, pokušajte ponovo.",
        passwordreset: "Poslano ponovno postavljanje lozinke. Provjerite svoju e-poštu",
        Email: "E-pošta",
        Password: "Lozinka",
        Submit: "podnijeti",
        Cancel: "Otkazati",
        Pleasewait: "Molimo pričekajte...",
        SignInMetronic: "Prijavite se na Metronic",
        NewHere: "Novi ste ovdje?",
        CreateAccount: "Stvorite račun",
        Useaccount: "Koristi račun",
        andpassword: "i lozinku",
        tocontinue: "za nastavak.",
        Continue: "Nastaviti",
        Select: "ODABERITE",
        selected: "ODABRANO",
        RelayPoint: "Prijelazna Točka",
        SelectHomeIcon: "Odaberi ikonu Početna stranica",
        SelectPointerIcon: "Odaberi ikonu Pokazivač",
        Languages: "Jezici",
        DefaultLanguage: "Zadani jezik",
        ManualLanguage: "Jezik priručnika",
        Update: "Ažuriranje",
        ShowLogoOption: "Prikaz opcije logotipa",
        View: "Prikaz",
        VerticalAddressSection: "Vertikalni odjeljak adrese",
        HorizontalAddressSection: "Horizontalni odjeljak adrese",
        RemoveLogo: "Ukloni logo",
        Map: "Karta",
        ServicePoint: "Mjesto usluge",
        Services: "Usluge",
        Sunday: "Nedjelja",
        Tuesday: "Utorak",
        Wednesday: "Srijeda",
        Thursday: "Četvrtak",
        Friday: "Petak",
        Saturday: "Subota",
        Monday: "ponedjeljak",
        DisplayTopHours: "Prikazati vrhunske sate",
        DisplaySideHours: "Prikazati bočni sati",
        DisplayMapHours: "Prikazati sate mape",
        PleaseEnterLocationToGetnearByRelayPoints: "Unesite lokaciju za pronalaženje obližnjih relejskih točaka.",
        DivideMapsIntoTwoParts: "Podijelite karte na dva dijela",
        UseNumbers : "Koristite brojeve",  
        UserIcon : "Ikona korisnika", 
        RelayPointIcon: "Ikona točke prebacivanja", 
        ServiceIcon : "Ikona usluge", 
        SelectIcon : "Odaberi ikonu",
        DistanceWithOnlyANumber : "Udaljenost s samo jednim brojem"
    },
    lt: {
        FindLocation: "Rasti vietą",
        SearchLocation: "Ieškoti vietos",
        OpenStreetMap: " Atidaryti „Open Street Map“",
        GoogleMap: "Google žemėlapis",
        BingMap: "Bing žemėlapis",
        Products: "Produktai",
        RelayPointLists: "Relės taškų sąrašai",
        Recherche: "Tyrimas",
        DisplayMap: "Rodyti žemėlapį",
        SelectMap: "Pasirinkite Žemėlapį",
        SelectDesign: "Pasirinkite Dizainas",
        SelectColorOfMarker: "Pasirinkite žymeklio spalvą",
        Limit: "Limitas",
        Carrierservice: "Pervežimo paslaugos",
        LocationPanel: "Vietos skydelis",
        RightLeftPanelOption: "Dešiniojo / Kairiojo skydelio parinktis",
        WithImages: "Su vaizdais",
        TopMapOption: "Viršutinė žemėlapio parinktis",
        BottomMapOption: "Apatinė žemėlapio parinktis",
        Distance: "Atstumas",
        Desktop: "Darbalaukis",
        Mobile: "Mobilusis",
        Theme: "Tema",
        DesktopTheme: "Stacionaraus kompiuterio tema",
        DesktopConfiguration: "Stacionaraus kompiuterio konfigūracija",
        MobileConfiguration: " Mobiliojo konfigūracija",
        Yes: "Taip",
        No: "Ne",
        Design1: "Dizainas 1",
        Design2: "Dizainas 2",
        Design3: "Dizainas 3",
        BlueMarker: "Mėlynas žymeklis",
        PinkMarker: "Rožinis žymeklis",
        Left: "Kairėje",
        Right: "Dešinėje",
        KM: "km ",
        Meter: "Metrai",
        Miles: "Mylia",
        Default: "km",
        Back: "Grįžti",
        Save: "Išsaugoti",
        Language: "Kalba",
        MapConfiguration: "Žemėlapio konfigūracija",
        ProductList: "Produktų sąrašas",
        GoToCart: "Eiti į krepšelį",
        UpdateLogo: "Atnaujinti logotipą",
        Image: "Paveikslėlis",
        Name: "Pavadinimas",
        Actions: "Veiksmai",
        SignOut: "Atsijungti",
        Title: "Pavadinimas",
        Description: "Aprašymas",
        Price: "Kaina",
        Remove: "Pašalinti",
        Summary: "Santrauka",
        Contact: "kontaktas",
        Mode: "Režimas",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Artimiausi siuntų skyriai",
        Choosemyrelaypoint: "Pasirinkite savo siuntų skyrių",
        BackToProducts: "Grįžti į produktus",
        ForgotPassword: "Pamiršote slaptažodį",
        resetyourpassword: "Įveskite savo el. pašto adresą, kad iš naujo nustatytumėte slaptažodį.",
        Sorry: "Atsiprašome, atrodo, kad aptikta klaidų. Bandykite dar kartą.",
        passwordreset: "Išsiųstas slaptažodžio nustatymas iš naujo. Prašau, patikrink savo el. paštą",
        Email: "El. paštas",
        Password: "Slaptažodis",
        Submit: "Pateikti",
        Cancel: "Atšaukti",
        Pleasewait: "Prašau palauk...",
        SignInMetronic: "Prisijunkite prie Metronic",
        NewHere: "Naujas Čia?",
        CreateAccount: "Sukurti paskyrą",
        Useaccount: "Naudokite paskyrą",
        andpassword: "ir slaptažodį",
        tocontinue: "kad tęstumėte.",
        Continue: "Tęsti",
        Select: "PASIRINKTI",
        selected: "PASIRINKTA",
        RelayPoint: "Perdavimo Taškas",
        SelectHomeIcon: "Pasirinkite pradinio puslapio piktogramą",
        SelectPointerIcon: "Pasirinkite rodyklės piktogramą",
        Languages: "Kalbos",
        DefaultLanguage: "Numatytasis kalbos",
        ManualLanguage: "Instrukcijų kalba",
        Update: "Atnaujinimas",
        ShowLogoOption: "Rodyti logotipo parinktį",
        View: "Peržiūra",
        VerticalAddressSection: "Vertikali adreso sekcija",
        HorizontalAddressSection: "Horizontali adreso sekcija",
        RemoveLogo: "Pašalinti logotipą",
        Map: "Žemėlapis",
        ServicePoint: "Aptarnavimo punktas",
        Services: "Paslaugos",
        Sunday: "Sekmadienis",
        Tuesday: "Antradienis",
        Wednesday: "Trečiadienis",
        Thursday: "Ketvirtadienis",
        Friday: "Penktadienis",
        Saturday: "Šeštadienis",
        Monday: "pirmadienis",
        DisplayTopHours: "Rodyti viršutines valandas",
        DisplaySideHours: "Rodyti šonines valandas",
        DisplayMapHours: "Rodyti žemėlapio valandas",
        PleaseEnterLocationToGetnearByRelayPoints: "Prašome įvesti vietą, kad gautumėte netoliese esančius relay taškus.",
        DivideMapsIntoTwoParts: "Padalyti žemėlapius į dvi dalis",
        UseNumbers : "Naudokite skaičius",  
        UserIcon : "Vartotojo piktograma", 
        RelayPointIcon: "Perjungimo taško piktograma", 
        ServiceIcon : "Paslaugos piktograma", 
        SelectIcon : "Pasirinkite piktogramą",
        DistanceWithOnlyANumber : "Atstumas su tik vienu skaičiumi"
    },
    cs: {
        FindLocation: "Najít umístění",
        SearchLocation: "Hledat umístění",
        OpenStreetMap: "Open Street Mapy",
        GoogleMap: "Google Mapy",
        BingMap: "Bing Mapy",
        Products: "produkty",
        RelayPointLists: "Seznamy reléových bodů",
        Recherche: "Výzkum",
        DisplayMap: "Zobrazit mapu",
        SelectMap: "Vyberte možnost Mapa",
        SelectDesign: "Vyberte Návrh",
        SelectColorOfMarker: "Vyberte Barva značky",
        Limit: "Omezit",
        Carrierservice: "Služba přepravce",
        LocationPanel: "Panel umístění",
        RightLeftPanelOption: "Možnost pravého/levého panelu",
        WithImages: "S obrázky",
        TopMapOption: "Možnost horní mapy",
        BottomMapOption: "Možnost dolní mapy",
        Distance: "Vzdálenost",
        Desktop: "Stolní počítač",
        Mobile: "Mobilní zařízení",
        Theme: "Téma",
        DesktopTheme: "Téma pro stolní počítač",
        DesktopConfiguration: "Konfigurace pro stolní počítač",
        MobileConfiguration: "Konfigurace pro mobilní zařízení",
        Yes: "Ano",
        No: "Ne",
        Design1: "Design 1",
        Design2: "Design 2",
        Design3: "Design 3",
        BlueMarker: "Modrá značka",
        PinkMarker: "Růžová značka",
        Left: "Vlevo",
        Right: "Vpravo",
        KM: "km ",
        Meter: "Metrs",
        Miles: "Miles",
        Default: "km",
        Back: "Zpět",
        Save: "Uložit",
        Language: "Jazyk",
        MapConfiguration: "Konfigurace mapy",
        ProductList: "Seznam produktů",
        GoToCart: "Přejít do košíku",
        UpdateLogo: "Aktualizovat logo",
        Image: "Obrázek",
        Name: "Název",
        Actions: "Akce",
        SignOut: "Odhlásit se",
        Title: "Titul",
        Description: "Popis",
        Price: "Cena",
        Remove: "Obrázek",
        Summary: "Shrnutí",
        Contact: "Kontakt",
        Mode: "Režim",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Nejbližší přepážková místa",
        Choosemyrelaypoint: "Vybrat mé přepážkové místo",
        BackToProducts: "Zpět na produkty",
        ForgotPassword: "Zapomenuté heslo",
        resetyourpassword: "Zadejte svůj e-mail pro obnovení hesla.",
        Sorry: "Je nám líto, zdá se, že byly zjištěny nějaké chyby, zkuste to prosím znovu.",
        passwordreset: "Bylo odesláno resetování hesla. Prosím zkontrolujte svůj e-mail",
        Email: "E-mail",
        Password: "Heslo",
        Submit: "Odeslat",
        Cancel: "Zrušit",
        Pleasewait: "Čekejte prosím...",
        SignInMetronic: "Přihlaste se do Metronic",
        NewHere: "Nový tady?",
        CreateAccount: "Vytvořit účet",
        Useaccount: "Použít účet",
        andpassword: "a heslo",
        tocontinue: "pokračovat.",
        Continue: "Pokračovat",
        Select: "Vybrat",
        selected: "Vybráno",
        RelayPoint: "Překladiště",
        SelectHomeIcon: "Vyberte ikonu Domů",
        SelectPointerIcon: "Vyberte ikonu ukazatele",
        Languages: "Jazyky",
        DefaultLanguage: "Výchozí jazyk",
        ManualLanguage: "Jazyk manuálu",
        Update: "Aktualizace",
        ShowLogoOption: "Zobrazit možnost loga",
        View: "Zobrazení",
        VerticalAddressSection: "Vertikální sekce adresy",
        HorizontalAddressSection: "Horizontální sekce adresy",
        RemoveLogo: "Odebrat logo",
        Map: "Mapa",
        ServicePoint: "Servisní bod",
        Services: "Služby",
        Sunday: "Neděle",
        Tuesday: "Úterý",
        Wednesday: "Středa",
        Thursday: "Čtvrtek",
        Friday: "Pátek",
        Saturday: "Sobota",
        Monday: "pondělí",
        DisplayTopHours: "Zobrazit top hodiny",
        DisplaySideHours: "Zobrazit boční hodiny",
        DisplayMapHours: "Zobrazit mapové hodiny",
        PleaseEnterLocationToGetnearByRelayPoints: "Zadejte umístění, abyste získali blízké relay body.",
        DivideMapsIntoTwoParts: "Rozdělte mapy na dvě části",
        UseNumbers : "Používejte čísla",
        UserIcon : "Ikona uživatele", 
        RelayPointIcon: "Symbol sběrného bodu", 
        ServiceIcon : "Ikona služby", 
        SelectIcon : "Vybrat ikonu",
        DistanceWithOnlyANumber : "Vzdálenost pouze s číslem"
    },
    hu: {
        FindLocation: "Hely keresése",
        SearchLocation: "Helykeresés",
        OpenStreetMap: "Open Street Térkép",
        GoogleMap: "Google Térkép",
        BingMap: "Bing Térkép",
        Products: "Termékek",
        RelayPointLists: "Csomagpont lista",
        Recherche: "Kutatás",
        DisplayMap: "Térkép megjelenítése",
        SelectMap: "Térkép kiválasztása",
        SelectDesign: "Design kiválasztása",
        SelectColorOfMarker: "Jelölő színének kiválasztása",
        Limit: "Korlátozás",
        Carrierservice: "Szállítási szolgáltatás",
        LocationPanel: "Hely panel",
        RightLeftPanelOption: "Jobb/Bal oldali panel opció",
        WithImages: "Képekkel",
        TopMapOption: "Alsó térkép lehetőség",
        BottomMapOption: "",
        Distance: "Távolság",
        Desktop: "Asztali számítógép",
        Mobile: "Mobil",
        Theme: "Téma",
        DesktopTheme: "Asztali téma",
        DesktopConfiguration: "Asztali konfiguráció",
        MobileConfiguration: "Mobil konfiguráció",
        Yes: "Igen",
        No: "Nem",
        Design1: "Design  1",
        Design2: "Design  2",
        Design3: "Design  3",
        BlueMarker: "Kék Marker",
        PinkMarker: "Rózsaszín marker",
        Left: "Balra",
        Right: "Jobbra",
        KM: "km ",
        Meter: "Méters",
        Miles: "Mérföld",
        Default: "km",
        Back: "Vissza",
        Save: "Mentés",
        Language: "Nyelv",
        MapConfiguration: "Térkép konfiguráció",
        ProductList: "Terméklista",
        GoToCart: "Tovább a kosárhoz",
        UpdateLogo: "Logó frissítése",
        Image: "Kép",
        Name: "Név",
        Actions: "Műveletek",
        SignOut: "Kijelentkezés",
        Title: "Cím",
        Description: "Leírás",
        Price: "Ár",
        Remove: "Törlés",
        Summary: "Összegzés",
        Contact: "Kapcsolatba lépni",
        Mode: "Mód",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Legközelebbi átvételi pontok",
        Choosemyrelaypoint: " Válassza ki a relay pontomat",
        BackToProducts: "Vissza a termékekhez",
        ForgotPassword: "Elfelejtett jelszó",
        resetyourpassword: "Adja meg e-mail címét a jelszó visszaállításához.",
        Sorry: "Sajnáljuk, úgy tűnik, hogy a rendszer hibákat észlelt. Kérjük, próbálja újra.",
        passwordreset: "Jelszó visszaállítása elküldve. Kérem nézze meg email-jeit",
        Email: "Email",
        Password: "Jelszó",
        Submit: "Beküldés",
        Cancel: "Megszünteti",
        Pleasewait: "Kérlek várj...",
        SignInMetronic: "Jelentkezzen be a Metronicba",
        NewHere: "Új itt?",
        CreateAccount: "Hozzon létre egy fiókot",
        Useaccount: "Fiók használata",
        andpassword: "és jelszót",
        tocontinue: "folytatni.",
        Continue: "Folytatni",
        Select: "KIVÁLASZTÁS",
        selected: "KIVÁLASZTOTT",
        RelayPoint: "Átvevőpont",
        SelectHomeIcon: "Otthon ikon kiválasztása",
        SelectPointerIcon: "Mutató ikon kiválasztása",
        Languages: "Nyelvek",
        DefaultLanguage: "Alapértelmezett nyelv",
        ManualLanguage: "Kézikönyv nyelve",
        Update: "Frissítés",
        ShowLogoOption: "Logó opció megjelenítése",
        View: "Megtekintés",
        VerticalAddressSection: "Vertikális címszekció",
        HorizontalAddressSection: "Horizontális címszekció",
        RemoveLogo: "Logó eltávolítása",
        Map: "Térké",
        ServicePoint: "Szolgáltatási pont",
        Services: "Szolgáltatások",
        Sunday: "Vasárnap",
        Tuesday: "Kedd",
        Wednesday: "Szerda",
        Thursday: "Csütörtök",
        Friday: "Péntek",
        Saturday: "Szombat",
        Monday: "hétfő",
        DisplayTopHours: "Főórák megjelenítése",
        DisplaySideHours: "Oldalsó órák megjelenítése",
        DisplayMapHours: "Térképórák megjelenítése",
        PleaseEnterLocationToGetnearByRelayPoints: "Kérjük, adja meg a helyét a közeli átjátszópontok megtalálásához.",
        DivideMapsIntoTwoParts: "Oszd meg a térképeket két részre",
        UseNumbers : "Használjon számokat",  
        UserIcon : "Felhasználói ikon", 
        RelayPointIcon: "Átviteli pont ikon", 
        ServiceIcon : "Szolgáltatásikon", 
        SelectIcon : "Ikon kiválasztása",
        DistanceWithOnlyANumber : "Távolság csak egy számmal"
    },
    sk: {
        FindLocation: "Nájsť umiestnenie",
        SearchLocation: "Hľadať umiestnenie",
        OpenStreetMap: "Open Street Mapy",
        GoogleMap: "Google Mapy",
        BingMap: "Bing Mapy",
        Products: "Produkty",
        RelayPointLists: "Zoznam miest na odovzdanie",
        Recherche: "Výskum",
        DisplayMap: "Zobraziť mapu",
        SelectMap: "Vyberte položku Mapa",
        SelectDesign: "Vyberte položku Dizajn",
        SelectColorOfMarker: "Vyberte farbu značky",
        Limit: "Obmedzenie",
        Carrierservice: "Služba prepravcu",
        LocationPanel: "Panel umiestnenia",
        RightLeftPanelOption: "Možnosť pravý / ľavý panel",
        WithImages: "S obrázkami",
        TopMapOption: "Možnosť horného panela",
        BottomMapOption: "Možnosť dolnej mapy",
        Distance: "Vzdialenosť",
        Desktop: "Počítačová verzia",
        Mobile: "Mobilná verzia",
        Theme: "Téma",
        DesktopTheme: "Počítačová téma",
        DesktopConfiguration: "Konfigurácia pre počítač",
        MobileConfiguration: "Konfigurácia pre mobil",
        Yes: "Áno",
        No: "Nie",
        Design1: "Dizajn 1",
        Design2: "Dizajn 2",
        Design3: "Dizajn 3",
        BlueMarker: "Modrá značka",
        PinkMarker: "Ružová značka",
        Left: "Vľavo",
        Right: "Vpravo",
        KM: "km",
        Meter: "Meters",
        Miles: "Miles",
        Default: "km",
        Back: "späť",
        Save: "Uložiť",
        Language: "Jazyk",
        MapConfiguration: "Konfigurácia mapy",
        ProductList: "Zoznam produktov",
        GoToCart: "Prejsť do košíka",
        UpdateLogo: "Aktualizovať logo",
        Image: "Obrázok",
        Name: "Názov",
        Actions: "Akcie",
        SignOut: "Odhlásiť Sa",
        Title: "Názov",
        Description: "Popis",
        Price: "cena",
        Remove: "Odstrániť",
        Summary: "Súhrn",
        Contact: "Kontakt",
        Mode: "Mód",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Najbližšie miesta na odovzdanie",
        Choosemyrelaypoint: "Vybrať miesto na odovzdanie",
        BackToProducts: "Späť na produkty",
        ForgotPassword: "Zabudol si heslo",
        resetyourpassword: "Zadajte svoj e-mail na obnovenie hesla.",
        Sorry: "Ľutujeme, zdá sa, že boli zistené nejaké chyby, skúste to znova.",
        passwordreset: "Odoslané obnovenie hesla. Prosím, skontrolujte si email",
        Email: "E-mail",
        Password: "Heslo",
        Submit: "Predložiť",
        Cancel: "Zrušiť",
        Pleasewait: "Prosím čakajte...",
        SignInMetronic: "Prihláste sa do Metronic",
        NewHere: "Nové tu?",
        CreateAccount: "Vytvoriť účet",
        Useaccount: "Použiť účet",
        andpassword: "a heslo",
        tocontinue: "účtu a hesla.",
        Continue: "Pokračovať",
        Select: "VYBRAŤ",
        selected: "VYBRANÉ",
        RelayPoint: "Prenosové Miesto",
        SelectHomeIcon: "Vyberte ikonu Domov",
        SelectPointerIcon: "Vyberte ikonu ukazovateľa",
        Languages: "Jazyky",
        DefaultLanguage: "Predvolený jazyk",
        ManualLanguage: "Jazyk príručky",
        Update: "Aktualizácia",
        ShowLogoOption: "Zobraziť možnosť loga",
        View: "Zobraziť",
        VerticalAddressSection: "Vertikálna časť adresy",
        HorizontalAddressSection: "Horizontálna časť adresy",
        RemoveLogo: "Odstrániť logo",
        Map: "Mapa",
        ServicePoint: "Servisný bod",
        Services: "Služby",
        Sunday: "Nedeľa",
        Tuesday: "Utorok",
        Wednesday: "Streda",
        Thursday: "Štvrtok",
        Friday: "Piatok",
        Saturday: "Sobota",
        Monday: "hétfő",
        DisplayTopHours: "Zobraziť top hodiny",
        DisplaySideHours: "Zobraziť bočné hodiny",
        DisplayMapHours: "Zobraziť mapové hodiny",
        PleaseEnterLocationToGetnearByRelayPoints: "Zadajte polohu, aby ste získali blízke retransmisné body.",
        DivideMapsIntoTwoParts: "Rozdelte mapy na dve časti",
        UseNumbers : "Použite čísla",  
        UserIcon : "Ikona používateľa", 
        RelayPointIcon: "Ikona retransmisného bodu", 
        ServiceIcon : "Ikona služby", 
        SelectIcon : "Vyberte ikonu",
        DistanceWithOnlyANumber : "Vzdialenosť iba s číslom"
    },
    sl: {
        FindLocation: "Najdi lokacijo",
        SearchLocation: "Iskanje lokacije",
        OpenStreetMap: "Odpri ulični zemljevid",
        GoogleMap: "Google zemljevid",
        BingMap: "Bing zemljevid",
        Products: "Izdelki",
        RelayPointLists: "Seznami relejnih točk",
        Recherche: "Raziskava",
        DisplayMap: "Prikaži zemljevid",
        SelectMap: "Izberite Zemljevid",
        SelectDesign: "Izberite Oblikovanje",
        SelectColorOfMarker: "Izberi barvo oznake",
        Limit: "Omejitev",
        Carrierservice: "Storitev prevoznika",
        LocationPanel: "Plošča z lokacijami",
        RightLeftPanelOption: "Opcija desne/leve plošče",
        WithImages: "S slikami",
        TopMapOption: "Opcija zgornjega zemljevida",
        BottomMapOption: "Možnost spodnje karte",
        Distance: "Razdalja",
        Desktop: "Namizje",
        Mobile: "Mobilna naprava",
        Theme: "Tema",
        DesktopTheme: "Tema namizja",
        DesktopConfiguration: "Konfiguracija namizja",
        MobileConfiguration: "Konfiguracija mobilne naprave",
        Yes: "Ja",
        No: "Ne",
        Design1: "Oblikovanje 1",
        Design2: "Oblikovanje 2",
        Design3: "Oblikovanje 3",
        BlueMarker: "Modra oznaka",
        PinkMarker: "Roza oznaka",
        Left: "Levo",
        Right: "Desno",
        KM: "km",
        Meter: "Meters",
        Miles: "Milje",
        Default: "km",
        Back: "Nazaj",
        Save: "Shrani",
        Language: "Jezik",
        MapConfiguration: "Konfiguracija zemljevida",
        ProductList: "Seznam izdelkov",
        GoToCart: "Pojdi v košarico",
        UpdateLogo: "Posodobi logotip",
        Image: "Slika",
        Name: "Ime",
        Actions: "Dejanja",
        SignOut: "Odjava",
        Title: "Naslov",
        Description: "Opis",
        Price: "Cena",
        Remove: "Odstrani",
        Summary: "Povzetek",
        Contact: "Kontakt",
        Mode: "Način",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Najbližja prevzemna mesta",
        Choosemyrelaypoint: " Izberite svoje prevzemno mesto",
        BackToProducts: "Nazaj na izdelke",
        ForgotPassword: "Ste pozabili geslo",
        resetyourpassword: "Vnesite svoj e-poštni naslov za ponastavitev gesla.",
        Sorry: "Oprostite, videti je, da je zaznanih nekaj napak, poskusite znova.",
        passwordreset: "Poslana ponastavitev gesla. Prosim, preglej svojo e-pošto",
        Email: "E-pošta",
        Password: "Geslo",
        Submit: "Predloži",
        Cancel: "Prekliči",
        Pleasewait: "Prosim počakaj...",
        SignInMetronic: "Prijavite se v Metronic",
        NewHere: "Novo tukaj?",
        CreateAccount: "Ustvari račun",
        Useaccount: "Uporabi račun",
        andpassword: "in geslo",
        tocontinue: "nadaljevati.",
        Continue: "Nadaljuj",
        Select: "IZBERI",
        selected: "IZBRANO",
        RelayPoint: "Prehodna Točka",
        SelectHomeIcon: " Izberi ikono za Domov",
        SelectPointerIcon: "Izberi ikono kazalca",
        Languages: "Jeziki",
        DefaultLanguage: "Privzeti jezik",
        ManualLanguage: "Jezik priročnika",
        Update: "Posodobitev",
        ShowLogoOption: "Prikaz možnosti logotipa",
        View: "Ogled",
        VerticalAddressSection: "Vertikalni del naslova",
        HorizontalAddressSection: "Horizontalni del naslova",
        RemoveLogo: "Odstrani logotip",
        Map: "Zemljevid",
        ServicePoint: "Servisna točka",
        Services: "Storitve",
        Sunday: "Nedelja",
        Tuesday: "Torek",
        Wednesday: "Sreda",
        Thursday: "Četrtek",
        Friday: "Petek",
        Saturday: "Sobota",
        Monday: "ponedeljek",
        DisplayTopHours: "Prikaz najboljših ur",
        DisplaySideHours: "Prikaz stranskih ur",
        DisplayMapHours: "Prikaz ur na zemljevidu",
        PleaseEnterLocationToGetnearByRelayPoints: "Prosimo, vnesite lokacijo, da dobite bližnje relejne točke.",
        DivideMapsIntoTwoParts: "Razdelite zemljevide na dva dela",
        UseNumbers : "Uporabite številke",  
        UserIcon : "Ikona uporabnika", 
        RelayPointIcon: "Ikona preklopne točke", 
        ServiceIcon : "Ikona storitve", 
        SelectIcon : "Izberite ikono",
        DistanceWithOnlyANumber : "Razdalja z le eno številko"
    },
    pl: {
        FindLocation: "Znajdź lokalizację",
        SearchLocation: "Wyszukaj lokalizację",
        OpenStreetMap: "Otwórz mapę ulic",
        GoogleMap: "Google Mapy",
        BingMap: "Bing Mapy",
        Products: "Produkty",
        RelayPointLists: "Listy punktów odbioru",
        Recherche: "Badania",
        DisplayMap: "Wyświetl mapę",
        SelectMap: "Wybierz mapę",
        SelectDesign: "Wybierz projekt",
        SelectColorOfMarker: "Wybierz kolor znacznika",
        Limit: "Limit",
        Carrierservice: "Usługa przewoźnika",
        LocationPanel: "Panel lokalizacji",
        RightLeftPanelOption: "Opcja panelu prawego / lewego",
        WithImages: "Z obrazami",
        TopMapOption: "Górna opcja mapy",
        BottomMapOption: "Dolna opcja mapy",
        Distance: "Odległość",
        Desktop: "Komputer",
        Mobile: "Mobilny",
        Theme: "Motyw",
        DesktopTheme: "Motyw na komputerze",
        DesktopConfiguration: "Konfiguracja komputera",
        MobileConfiguration: "Konfiguracja mobilna",
        Yes: "Tak",
        No: "NIE",
        Design1: "Projekt 1",
        Design2: "Projekt 2",
        Design3: "Projekt 3",
        BlueMarker: "Niebieski znacznik",
        PinkMarker: "Różowy znacznik",
        Left: "Lewy",
        Right: "Prawidłowy",
        KM: "km",
        Meter: "Metry",
        Miles: "Mile",
        Default: "km",
        Back: "Powrót",
        Save: "Zapisz",
        Language: "Język",
        MapConfiguration: "Konfiguracja mapy",
        ProductList: "Lista produktów",
        GoToCart: "Przejdź do koszyka",
        UpdateLogo: "Zaktualizuj logo",
        Image: "Obraz",
        Name: "Nazwa",
        Actions: "Działania",
        SignOut: "Wyloguj się",
        Title: "Tytuł",
        Description: "Opis",
        Price: "Cena",
        Remove: "Usuń",
        Summary: "Podsumowanie",
        Contact: "Kontakt",
        Mode: "Tryb",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Najbliższe punkty odbioru",
        Choosemyrelaypoint: "Wybierz mój punkt odbioru",
        BackToProducts: "Powrót do produktów",
        ForgotPassword: "Zapomniałeś hasła",
        resetyourpassword: "Wpisz swój adres e-mail, aby zresetować hasło.",
        Sorry: "Przepraszamy, wygląda na to, że wykryto jakieś błędy, spróbuj ponownie.",
        passwordreset: "Wysłano zresetowanie hasła. Proszę sprawdzić email",
        Email: "E-mail",
        Password: "Hasło",
        Submit: "Składać",
        Cancel: "Anulować",
        Pleasewait: "Proszę czekać...",
        SignInMetronic: "Zaloguj się do Metronic",
        NewHere: "Nowy tutaj?",
        CreateAccount: "Utwórz konto",
        Useaccount: "Użyj konta",
        andpassword: "i hasło",
        tocontinue: "kontynuować.",
        Continue: "Kontynuować",
        Select: "WYBIERZ",
        selected: "WYBRANO",
        RelayPoint: "Punkt Odbioru",
        SelectHomeIcon: "Wybierz ikonę Startu",
        SelectPointerIcon: "Wybierz ikonę wskaźnika",
        Languages: "Języki",
        DefaultLanguage: "Język domyślny",
        ManualLanguage: "Język instrukcji",
        Update: "Aktualizacja",
        ShowLogoOption: "Pokaż opcję logo",
        View: "Widok",
        VerticalAddressSection: "Pionowy blok adresowy",
        HorizontalAddressSection: "Poziomy blok adresowy",
        RemoveLogo: "Usuń logo",
        Map: "Mapa",
        ServicePoint: "Punkt obsługi",
        Services: "Usługi",
        Sunday: "Niedziela",
        Tuesday: "Wtorek",
        Wednesday: "Środa",
        Thursday: "Czwartek",
        Friday: "Piątek",
        Saturday: "Sobota",
        Monday: "poniedziałek",
        DisplayTopHours: "Wyświetlanie Top Godzin",
        DisplaySideHours: "Wyświetlanie Godzin Bocznych",
        DisplayMapHours: "Wyświetlanie Godzin Mapy",
        PleaseEnterLocationToGetnearByRelayPoints: "Wpisz lokalizację, aby uzyskać pobliskie punkty retransmisji.",
        DivideMapsIntoTwoParts: "Podziel mapy na dwie części",
        UseNumbers : "Użyj liczb",  
        UserIcon : "Ikona użytkownika", 
        RelayPointIcon: "Ikona punktu retransmisji", 
        ServiceIcon : "Ikona usługi", 
        SelectIcon : "Wybierz ikonę",
        DistanceWithOnlyANumber : "Odległość tylko z liczbą"
    },
    nl: {
        FindLocation: "Find Locatie",
        SearchLocation: "Zoek Locatie",
        OpenStreetMap: "Open Street Maps",
        GoogleMap: "Google Maps",
        BingMap: "Bing Maps",
        Products: "Producten",
        RelayPointLists: "Lijst met relay-punten",
        Recherche: "Onderzoek",
        DisplayMap: "Kaart weergeven",
        SelectMap: "Selecteer Kaart",
        SelectDesign: "Selecteer Ontwerp",
        SelectColorOfMarker: "Selecteer kleur van de marker",
        Limit: "Limiet",
        Carrierservice: "Vervoerdersservice",
        LocationPanel: "Locatiepaneel",
        RightLeftPanelOption: "Rechter-/linkerpaneeloptie",
        WithImages: "Met afbeeldingen",
        TopMapOption: "Onderste kaartoptie",
        BottomMapOption: "",
        Distance: "Afstand",
        Desktop: "Desktop",
        Mobile: "Mobiel",
        Theme: "Thema",
        DesktopTheme: "Desktop-thema",
        DesktopConfiguration: "Desktop-configuratie",
        MobileConfiguration: "Mobiele configuratie",
        Yes: "Ja",
        No: "Nee",
        Design1: "Ontwerp 1",
        Design2: "Ontwerp 2",
        Design3: "Ontwerp 3",
        BlueMarker: "Blauwe markering",
        PinkMarker: "Roze markering",
        Left: "Links",
        Right: "Rechts",
        KM: "km ",
        Meter: "Meters",
        Miles: "mijlen",
        Default: "km",
        Back: "Rug",
        Save: "Redden",
        Language: "Taal",
        MapConfiguration: "Kaart configuratie",
        ProductList: "Product lijst",
        GoToCart: "Ga naar winkelwagen",
        UpdateLogo: "Logo bijwerken",
        Image: "Afbeelding",
        Name: "Naam",
        Actions: "Acties",
        SignOut: "Afmelden",
        Title: "Titel",
        Description: "Beschrijving",
        Price: "Prijs",
        Remove: "Verwijderen",
        Summary: "Samenvatting",
        Contact: "Contact",
        Mode: "Modus",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Dichtstbijzijnde relaispunten",
        Choosemyrelaypoint: "Kies mijn relaispunt",
        BackToProducts: "Terug naar producten",
        ForgotPassword: "Wachtwoord vergeten",
        resetyourpassword: "Voer uw e-mailadres in om uw wachtwoord opnieuw in te stellen.",
        Sorry: "Sorry, het lijkt erop dat er enkele fouten zijn gedetecteerd, probeer het opnieuw.",
        passwordreset: "Wachtwoord reset verzonden. Controleer uw e-mail",
        Email: "E-mail",
        Password: "Wachtwoord",
        Submit: "Indienen",
        Cancel: "Annuleren",
        Pleasewait: "Even geduld aub...",
        SignInMetronic: "Meld u aan bij Metronic",
        NewHere: "Nieuw hier?",
        CreateAccount: "Account aanmaken",
        Useaccount: "Gebruik account",
        andpassword: "en wachtwoord",
        tocontinue: "doorgaan.",
        Continue: "Doorgaan",
        Select: "SELECTEER",
        selected: "GESELECTEERD",
        RelayPoint: "Afhaalpunt",
        SelectHomeIcon: "Selecteer Startpictogram",
        SelectPointerIcon: "Selecteer aanwijzerpictogram",
        Languages: "Talen",
        DefaultLanguage: "Standaardtaal",
        ManualLanguage: "Handleidingstaal",
        Update: "Update",
        ShowLogoOption: "Optie voor logo tonen",
        View: "Bekijken",
        VerticalAddressSection: "Verticale adressectie",
        HorizontalAddressSection: "Horizontale adressectie",
        RemoveLogo: "Logo verwijderen",
        Map: "Kaart",
        ServicePoint: "Servicepunt",
        Services: "Diensten",
        Sunday: "Zondag",
        Tuesday: "Dinsdag",
        Wednesday: "Woensdag",
        Thursday: "Donderdag",
        Friday: "Vrijdag",
        Saturday: "Zaterdag",
        Monday: "maandag",
        DisplayTopHours: "Topuren weergeven",
        DisplaySideHours: "Zijdeuren weergeven",
        DisplayMapHours: "Kaarturen weergeven",
        PleaseEnterLocationToGetnearByRelayPoints: "Voer een locatie in om nabijgelegen relaispunten te krijgen.",
        DivideMapsIntoTwoParts: "Verdeel kaarten in twee delen",
        UseNumbers : "Gebruik cijfers",  
        UserIcon : "Gebruikerspictogram", 
        RelayPointIcon: "Symbool voor doorschakelpunt", 
        ServiceIcon : "Servicepictogram", 
        SelectIcon : "Selecteer pictogram",
        DistanceWithOnlyANumber : "Afstand met slechts een nummer"
    },
    da: {
        FindLocation: "Find placering",
        SearchLocation: "Søg placering",
        OpenStreetMap: "Åben Street Map",
        GoogleMap: "Google kort",
        BingMap: "Bing kort",
        Products: "Produkter",
        RelayPointLists: "Afhentningssteder lister",
        Recherche: "Forskning",
        DisplayMap: "Vis kort",
        SelectMap: "Vælg Kort",
        SelectDesign: "Vælg Design",
        SelectColorOfMarker: "Vælg markørfarve",
        Limit: "Grænse",
        Carrierservice: "Transportørtjeneste",
        LocationPanel: "Placeringspanel",
        RightLeftPanelOption: "Valg af højre/venstre panel",
        WithImages: "Med billeder",
        TopMapOption: "Topkortmulighed",
        BottomMapOption: "Bundkortindstilling",
        Distance: "Afstand",
        Desktop: "Skrivebord",
        Mobile: "Mobil",
        Theme: "Tema",
        DesktopTheme: "Skrivebordstema",
        DesktopConfiguration: "Skrivebordskonfiguration",
        MobileConfiguration: "Mobil konfiguration",
        Yes: "Ja",
        No: "Ingen",
        Design1: "Design 1",
        Design2: "Design 2",
        Design3: "Design 3",
        BlueMarker: "Blå Marker",
        PinkMarker: "Pink Marker",
        Left: "Venstre",
        Right: "Højre",
        KM: "km",
        Meter: "Målers",
        Miles: "Miles",
        Default: "km",
        Back: "Tilbage",
        Save: "Gem",
        Language: "Sprog",
        MapConfiguration: "Kort konfiguration",
        ProductList: "Produktliste",
        GoToCart: "Gå til indkøbskurv",
        UpdateLogo: "Opdater logo",
        Image: "Billede",
        Name: "Navn",
        Actions: "Handlinger",
        SignOut: "Log ud",
        Title: "Titel",
        Description: "Beskrivelse",
        Price: "Pris",
        Remove: "Fjerne",
        Summary: "Opsamling",
        Contact: "Kontakt",
        Mode: "Tilstand",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Nærmeste afhentningssteder",
        Choosemyrelaypoint: "Vælg mit afhentningssted",
        BackToProducts: "Tilbage til produkter",
        ForgotPassword: "Glemt kodeord",
        resetyourpassword: "Indtast din e-mail for at nulstille din adgangskode.",
        Sorry: "Beklager, det ser ud til, at der er fundet nogle fejl, prøv venligst igen.",
        passwordreset: "Sendt adgangskode nulstillet. Tjek venligst din email",
        Email: "E-mail",
        Password: "Adgangskode",
        Submit: "Indsend",
        Cancel: "Afbestille",
        Pleasewait: "Vent venligst...",
        SignInMetronic: "Log ind på Metronic",
        NewHere: "Ny her?",
        CreateAccount: "Opret en konto",
        Useaccount: "Brug konto",
        andpassword: "og adgangskode",
        tocontinue: "at fortsætte.",
        Continue: "Fortsæt",
        Select: "VÆLG",
        selected: "VALGTE",
        RelayPoint: "Afhentningssted",
        SelectHomeIcon: "Vælg hjemikon",
        SelectPointerIcon: "Vælg pegeikon",
        Languages: "Sprog",
        DefaultLanguage: "Standardsprog",
        ManualLanguage: "Manualens sprog",
        Update: "Opdatering",
        ShowLogoOption: "Vis logoindstilling",
        View: "Vis",
        VerticalAddressSection: "Vertikal adresseafsnit",
        HorizontalAddressSection: "Horisontal adresseafsnit",
        RemoveLogo: "Fjern logo",
        Map: "Kort",
        ServicePoint: "Servicepunkt",
        Services: "Tjenester",
        Sunday: "Søndag",
        Tuesday: "Tirsdag",
        Wednesday: "Onsdag",
        Thursday: "Torsdag",
        Friday: "Fredag",
        Saturday: "Lørdag",
        Monday: "mandag",
        DisplayTopHours: "Vis Top Timer",
        DisplaySideHours: "Vis Side Timer",
        DisplayMapHours: "Vis Kort Timer",
        PleaseEnterLocationToGetnearByRelayPoints: "Indtast placering for at få nærliggende relay-punkter.",
        DivideMapsIntoTwoParts: "Del kortene op i to dele",
        UseNumbers : "Brug tal",  
        UserIcon : "Brugerikon", 
        RelayPointIcon: "Symbol for omstillingspunkt", 
        ServiceIcon : "Serviceikon", 
        SelectIcon : "Vælg ikon",
        DistanceWithOnlyANumber : "Afstand med kun ét nummer"
    },
    fi: {
        FindLocation: "Etsi sijainti",
        SearchLocation: "Hae sijainti",
        OpenStreetMap: "Open Street kartta",
        GoogleMap: "Google-kartta",
        BingMap: "Bing-kartta",
        Products: "Tuotteet",
        RelayPointLists: "Noutopisteiden luettelot",
        Recherche: "Tutkimus",
        DisplayMap: "Näytä kartta",
        SelectMap: "Valitse Kartta",
        SelectDesign: "Valitse Suunnittelu",
        SelectColorOfMarker: "Valitse Markerin väri",
        Limit: "Rajoitus",
        Carrierservice: "Kuljettajan palvelu",
        LocationPanel: "Sijaintipaneeli",
        RightLeftPanelOption: "Oikea / vasen sivupaneeli -valinta",
        WithImages: "Kuvilla",
        TopMapOption: "Yläkartta-vaihtoehto",
        BottomMapOption: "Ala kartta -vaihtoehto",
        Distance: "Etäisyys",
        Desktop: "Työpöytä",
        Mobile: "Mobiili",
        Theme: "Teema",
        DesktopTheme: "Työpöytäteema",
        DesktopConfiguration: "Työpöydän asetukset",
        MobileConfiguration: "Mobiiliasetukset",
        Yes: "Kyllä",
        No: "Ei",
        Design1: "Suunnittelu  1",
        Design2: "Suunnittelu  2",
        Design3: "Suunnittelu  3",
        BlueMarker: "Sininen merkki",
        PinkMarker: "Vaaleanpunainen merkki",
        Left: "Vasen",
        Right: "Oikea",
        KM: "km",
        Meter: "Metrins",
        Miles: "Mailia",
        Default: "km",
        Back: "Takaisin",
        Save: "Tallentaa",
        Language: "Kieli",
        MapConfiguration: "Kartan asetukset",
        ProductList: "Tuotelista",
        GoToCart: "Siirry ostoskoriin",
        UpdateLogo: "Päivitä logo",
        Image: "Kuva",
        Name: "Nimi",
        Actions: "Toiminnot",
        SignOut: "Kirjaudu ulos",
        Title: "Otsikko",
        Description: "Kuvaus",
        Price: "Hinta",
        Remove: "Poista",
        Summary: "Yhteenveto",
        Contact: "Ottaa yhteyttä",
        Mode: "Tila",
        DHLFedex: "DHL / Fedex",
        NearestRelayPoints: "Lähimmät välityspisteet",
        Choosemyrelaypoint: "Valitse välityspisteeni",
        BackToProducts: "Takaisin tuotteisiin",
        ForgotPassword: "Unohtuiko salasana",
        resetyourpassword: "Syötä sähköpostiosoitteesi nollataksesi salasanasi.",
        Sorry: "Valitettavasti näyttää siltä, että virheitä on havaittu. Yritä uudelleen.",
        passwordreset: "Salasanan palautus lähetetty. Ole hyvä ja tarkista sähköpostisi",
        Email: "Sähköposti",
        Password: "Salasana",
        Submit: "Lähetä",
        Cancel: "Peruuttaa",
        Pleasewait: "Odota...",
        SignInMetronic: "Kirjaudu sisään Metroniciin",
        NewHere: "Uusi täällä?",
        CreateAccount: "Luo tili",
        Useaccount: "Käytä tiliä",
        andpassword: "ja salasana",
        tocontinue: "jatkaa.",
        Continue: "Jatkaa",
        Select: "VALITSE",
        selected: "VALITTU",
        RelayPoint: "Noutopiste",
        SelectHomeIcon: "Valitse Kotikuvake",
        SelectPointerIcon: "Valitse osoitinikoni",
        Languages: "Kielet",
        DefaultLanguage: "Oletuskieli",
        ManualLanguage: "Käsikirjan kieli",
        Update: "Päivitys",
        ShowLogoOption: "Näytä logon vaihtoehto",
        View: "Näkymä",
        VerticalAddressSection: "Pystysuuntainen osoitesektio",
        HorizontalAddressSection: "Vaakasuuntainen osoitesektio",
        RemoveLogo: "Poista logo",
        Map: "Kartta",
        ServicePoint: "Palvelupiste",
        Services: "Palvelut",
        Sunday: "sunnuntai",
        Tuesday: "tiistai",
        Wednesday: "keskiviikko",
        Thursday: "torstai",
        Friday: "perjantai",
        Saturday: "lauantai",
        Monday: "maanantai",
        DisplayTopHours: "Näytä Top Hours",
        DisplaySideHours: "Näytä Side Hours",
        DisplayMapHours: "Näytä Map Hours",
        PleaseEnterLocationToGetnearByRelayPoints: "Syötä sijainti saadaksesi lähellä olevia reititpisteitä.",
        DivideMapsIntoTwoParts: "Jaa kartat kahteen osaan",
        UseNumbers : "Käytä numeroita",  
        UserIcon : "Käyttäjäkuvake", 
        RelayPointIcon: "Relepiste-kuvake", 
        ServiceIcon : "Palvelukuvake", 
        SelectIcon : "Valitse kuvake",
        DistanceWithOnlyANumber : "Etäisyys vain yhdellä numerolla"
    }
};