import L from "leaflet";
import "leaflet-routing-machine";
import { createControlComponent } from "@react-leaflet/core";
import { getDistance } from "geolib";
import { config } from "../../../Config/Appsettings";

const createRoutineMachineLayer = ({ routeLocations, handleMarker, markerColour, locations, handleCheckBox, isChecked, routingDistance, specificActive, intl, settings, mapCounts }: any) => {
  let myTime: any;
  const AddEventOnMap = (selectedLocation: any) => {
    if (selectedLocation !== undefined) {
      for (let i = 0; i < selectedLocation.length; i++) {
        let checkBox: any = document.getElementById(`new-checkbox-${selectedLocation[i].pickUpPointId + selectedLocation[i].carrierServiceId}`);
        if (checkBox) {
          let createdId = "new-checkbox-" + isChecked
          if (createdId === checkBox.id) {
            checkBox.checked = true;
          }
          checkBox.addEventListener("click", () => {
            handleCheckBox(selectedLocation, selectedLocation[i], i);
          })
        }
      }
      let isclose = document.getElementById('close-button')
      isclose?.addEventListener("click", () => {
        let tooltipBox: any = document.getElementById('tooltip-box');
        if (tooltipBox.className.includes("d-none")) {
          tooltipBox.parentElement.classList.add("leaflet-tooltip");
          tooltipBox.classList.add("d-block")
        } else {
          tooltipBox.parentElement.classList.remove("leaflet-tooltip");
          tooltipBox.classList.add("d-none");
        }
      });

      let iscloseEndJourney = document.getElementById('endJourney-close-button')
      iscloseEndJourney?.addEventListener("click", () => {
        let tooltipBox: any = document.getElementById('tooltip-box');
        if (tooltipBox.className.includes("d-none")) {
          tooltipBox.parentElement.classList.add("leaflet-tooltip");
          tooltipBox.classList.add("d-block")
        } else {
          tooltipBox.parentElement.classList.remove("leaflet-tooltip");
          tooltipBox.classList.add("d-none");
        }
      });
    }

    clearTimeout(myTime);
  };

 const resetStreetMapPopup = () =>{
  let map_container : any =  document.getElementById("map-container");
  let tooltip_box : any =  document.getElementsByClassName("custom-tooltip-for-multiple-location-streetmap");
  if(map_container && tooltip_box){
    const parentRect = map_container.getBoundingClientRect();
    const childRect = tooltip_box[0].getBoundingClientRect();
  
    const overflowTop = Math.max(parentRect.top - childRect.top, 0);
    const overflowBottom = Math.max(childRect.bottom - parentRect.bottom, 0);

    if (childRect.top < parentRect.top) {
      tooltip_box[0].style.top =    (overflowTop + 14) + "px";
    } else if (childRect.bottom > parentRect.bottom) {
      tooltip_box[0].style.top =  "-" + (overflowBottom + 14) + "px";
    }
  }
 };

  let selectedServices: any[] = [];
  locations.map((i: any) => {
    i.filter((ele: any) => {
      if (ele.pickUpPointId === routeLocations.endJourney.pickUpPointId) {
        selectedServices = i;
      }
    })
  });

  let selectedLocation: any = selectedServices.find(x => x.pickUpPointId === routeLocations.endJourney.pickUpPointId);
  let customStyles;
  const distance = getDistance({ latitude: parseFloat(String(routeLocations.startJourney.latitude)), longitude: parseFloat(String(routeLocations.startJourney.longitude)) }, { latitude: parseFloat(String(routeLocations.endJourney.latitude)), longitude: parseFloat(String(routeLocations.endJourney.longitude)) });
  let distanceType = "";
  let customIcon: any = null;
  if (routingDistance) {
    customIcon = true
    if (distance <= 1000) {
      customStyles = [{ color: "#36A8FF", opacity: 1, weight: 4, dashArray: "2,7" }];
      distanceType = `${Math.round(distance)} ${config.GetLables(intl, "Meter")}`;
      customIcon = true
    }
    else {
      customStyles = [{ color: "#36A8FF" }];
      distanceType = `${(distance / 1000)}  ${config.GetLables(intl, "KM")}`;
      customIcon = false
    }
  } else {
    customStyles = [{ color: "#36A8FF", opacity: 1, weight: 4, dashArray: "2,7" }];
    distanceType = `${(distance * 0.000621371192).toFixed(4)} ${config.GetLables(intl, "Miles")}`;
    
  }

  let waypoints = [
    L.latLng(routeLocations.startJourney.latitude, routeLocations.startJourney.longitude),
    L.latLng(routeLocations.endJourney.latitude, routeLocations.endJourney.longitude)
  ];

  const startIcon = L.divIcon({
    html:
      `
      <div class="position-relative">
        <div class="custom-leaflet-tooltip-start-jorney leaflet-tooltip-top d-none" id="startJourney-tooltip" role="tooltip"><span>Home</span></div>
     <div class="single-location-marker">
     <div style="transform: rotate(45deg);">
         <span class="d-flex justify-content-center align-items-center">
         <img src=${routeLocations.icons.home} class="map-thumbnail">
         </span>
     </div>   
        </div>
      </div>
     `
    ,
    iconSize: [0, 0]
  });
  const endIcon = L.divIcon({
    html:
      `
      <div class="position-relative">
      <div class="custom-leaflet-tooltip-start-jorney leaflet-tooltip-top d-none" id="startJourney-tooltip" role="tooltip"><span>Home</span></div>
      ${settings.isUseNumbers  ? `<div class="single-location-marker-with-just-number">
      <div style="transform: rotate(45deg);">
<span class="d-flex justify-content-center align-items-center text-white">
<div class="text-center">
<p class="text-center location-counter-map"> ${mapCounts}</p>
</div>
</span>

      </div>`  : 
      `<div class="single-location-marker">
      <div style="transform: rotate(45deg);">
          <span class="d-flex justify-content-center align-items-center">
          ${
            settings.isSelectIcon == null ?
            `<img src="${selectedLocation.carrierService && selectedLocation.carrierService.logoName ? `${config.SeriveImageUrl}${selectedLocation.carrierService.logoName}` : "/media/avatars/default_map.png"}" class="map-thumbnail">`:
            settings.isSelectIcon == true ? 
            `<img src="${settings.MapPointerIcon && settings.MapPointerIcon !== "default_map.png" ? settings.MapPointerIcon.includes("http") ? `${settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${settings.MapPointerIcon}` : `/media/avatars/default_map.png`}" class="map-thumbnail">`:
            `<img src="${selectedLocation.pickupPointType && selectedLocation.pickupPointType.logoName ? `${config.ImageUrl}${selectedLocation.pickupPointType.logoName}` : "/media/avatars/default_map.png"}" class="map-thumbnail">`
          }
          
          </span>
      </div>` }
    
        </div>
      </div>
     `
    ,
    iconSize: [0, 0]
  });

  var osrmOptions = {
    serviceUrl: 'https://api.mapbox.com/directions/v5/mapbox',
    profile: 'walking',
    timeout: 30 * 1000,
    routingOptions: {
      alternateRoute: true,
      steps: true
    },
    fitSelectedRoutes: 'smart'
  };

  const instance = L.Routing.control({
    router: distance <= 1000 ? L.Routing.mapbox("pk.eyJ1IjoiYWZ6YWxzaGFpa2gxMjMiLCJhIjoiY2xoaG16Z2UwMXJ6dDNrcGNvc3BqM2owdiJ9.WQf15Xji82Cs7c6eHgrgMg", osrmOptions) : undefined,
    waypoints: waypoints,
    plan: L.Routing.plan(waypoints, {
      createMarker: function (i: any, waypoint: any, n: any) {
        let icon = i === 0 ? startIcon : endIcon;
        let marker = L.marker(waypoint.latLng, {
          draggable: false,
          icon
        });

          if (icon !== startIcon) {
            if(!settings.isDivideMapsIntotwoParts){
              marker.bindTooltip(
                selectedServices.length !== null  ? 
                  `
            <div class="card border-0 routing-street-map" role="button" id="tooltip-box">
            <div class="card-body p-2">
            <div>
            <button class='btn btn-sm btn-icon me-n5 close-button close-tooltip-icon' id="close-button"><i class="fa fa-close text-black"></i></button>
            <div class="d-flex justify-content-between">
  
            <h4 class="text-gray-800 fw-bold mb-1 fs-6 me-2 text-wrap text-ellipsis">
            ${selectedLocation.name}
            </h4>
            ${settings.isLogoShow === true ?
                    `<div class="symbol symbol-circle  symbol-30px">
              <a><div class="symbol-label">
              ${
                settings.isSelectIcon == null ?
                `<img src="${selectedLocation.carrierService && selectedLocation.carrierService.logoName ? `${config.SeriveImageUrl}${selectedLocation.carrierService.logoName}` : "/media/avatars/default_map.png"}" class="rounded-circle w-100">`:
                settings.isSelectIcon == true ? 
                `<img src="${settings.MapPointerIcon && settings.MapPointerIcon !== "default_map.png" ? settings.MapPointerIcon.includes("http") ? `${settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${settings.MapPointerIcon}` : `/media/avatars/default_map.png`}" class="rounded-circle w-100">`:
                `<img src="${selectedLocation.pickupPointType && selectedLocation.pickupPointType.logoName ? `${config.ImageUrl}${selectedLocation.pickupPointType.logoName}` : "/media/avatars/default_map.png"}" class="rounded-circle w-100">`
              }
              </div></a>
              </div>`
                    : ""
                  }         
  
            </div>
            <span class="fw-semibold text-gray-800 mb-1 fs-6 text-start d-block"> <i class=${selectedServices[0].distanceByLatLog <= 1000 ? `"fa-solid fa-person-walking text-gray-800 me-2"` : `"fa fa-car text-gray-800 me-2"`}></i>${distanceType}</span>
            <span class="fw-semibold text-primary fs-6 mb-2 d-block text-start text-wrap line-height-normal text-ellipsis">${selectedServices[0].address1} ${selectedServices[0].address2} ${selectedServices[0].city} ${selectedServices[0].postalCode}</span>
            ${ selectedServices.length !== 1 ? (function locationCheck() {
                    let divString = "";
                    for (let j = 0; j < selectedServices.length; j++) {
                      divString +=
                        `<div  class="mb-1 pointerClose location-checkbox" key=${j}>
                  <div class="form-check pointerClose form-check-form-check form-check-custom form-check-success form-check-sm">
                  <input class="form-check-input h-15px w-15px"
                  type="checkbox"
                   id=${`new-checkbox-${selectedServices[j].pickUpPointId + selectedServices[j].carrierServiceId}`} role="button">
                  <label class="form-check-label text-black" for=${`new-checkbox-${selectedServices[j].pickUpPointId + selectedServices[j].carrierServiceId}`}>  ${selectedServices[j].carrierService?.name}</label>
                  </div>
                  </div>
                  `
                    }
                    return  divString;
                })() : ""} 

                ${settings.isMapHours ? selectedLocation.pickupPointSchedules1 ? 
                 `<table class="table table-striped street-map-time-box-table">
                <tbody>
                ${(function locationCheck() {
                 let divString = "";   
                 for (const property in config.ShopTimeSchedule) {
                   let properties : any  =  config.ShopTimeSchedule[property].split(",");
                     divString +=
                       `<tr>
                       <th scope="row">${ config.GetLables(intl,properties[0])}</th>
                       <td>${selectedLocation.pickupPointSchedules1[properties[1]]} - ${selectedLocation.pickupPointSchedules1[properties[2]]}</td>
                       <td>${selectedLocation.pickupPointSchedules1[properties[3]]} - ${selectedLocation.pickupPointSchedules1[properties[4]]}</td>
                   </tr>`
                 }
                 return divString;
               })()} 
                </tbody>
            </table>
            </div>
     
     </div>`  : `<div class="card-body p-2 h-100"><div class="h-100  text-center w-100"><div class="w-100"> Schedules are not <br />available  </div></div></div>`
        : ""
                  }    
            </div >
    ` :
                  ``
                , {
                  className: "custom-tooltip-for-multiple-location-streetmap",
                  direction: "right",
                  permanent: true
                });
            }
          }
     
        marker.on('click', function () {
    if(settings.isDivideMapsIntotwoParts){
      handleMarker();
        return true;
    }


          let tooltipBox: any = document.getElementById('tooltip-box');
          let endJourney_tooltip: any = document.getElementById('endJourney-tooltip');

          if (tooltipBox === null) {
            if (tooltipBox === null) {
              if (endJourney_tooltip.className.includes("d-none")) {
                endJourney_tooltip.classList.add("d-block");
                endJourney_tooltip.parentElement.classList.add("leaflet-tooltip");
              } else {
                handleMarker();
              }
            } else {
              handleMarker();
            }

          } else {
            if (tooltipBox !== null) {
              if (tooltipBox.className.includes("d-none")) {
                tooltipBox.classList.add("d-block");
                tooltipBox.parentElement.classList.add("leaflet-tooltip");
              } else {
                handleMarker();
              }
              tooltipBox.classList.remove("d-none");
            }
            else {
              handleMarker()
            }

          }
        });

        if (!(selectedServices.length > 1) || specificActive) {
          marker.on('click', function (e) {
            if (e.latlng.lat === routeLocations.startJourney.latitude && e.latlng.lng === routeLocations.startJourney.longitude) {
              let home: any = document.getElementById("startJourney-tooltip");
              if (home)
                home.classList.remove("d-none");
            }
            else {
              let specificLocation: any = document.getElementById("endJourney-tooltip");
              if (specificLocation)
                specificLocation.classList.remove("d-none");
            }
          });
        }
        if (i == 0) {
          setTimeout(() => {
            AddEventOnMap(selectedServices);
            resetStreetMapPopup();
          }, 1000);
        }
        return marker
      },
      routeWhileDragging: true
    }),
    show: false,
    addWaypoints: false,
    routeWhileDragging: false,
    fitSelectedRoutes: true,
    showAlternatives: false,
    lineOptions: {
      styles: customStyles,
      extendToWaypoints: false,
      missingRouteTolerance: 10,
    },
  });

  return instance;
};

export const RoutingMachine = createControlComponent(createRoutineMachineLayer);
