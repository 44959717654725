
import React from "react";
import { config } from "../../../Config/Appsettings";
import { GetLable } from "../../../Config/GetLabels";
import getDistance from 'geolib/es/getDistance';
import { useIntl } from "react-intl";

export const SideLocations = ({ location, Settings, googleMapLocations,latitude, longitude }: any) => {
    const intl = useIntl();
   let  distance : any =  null;
   let newdistanceType :string = "";

 if(googleMapLocations.length > 1){
          distance   = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(location.positionX)), longitude: parseFloat(String(location.positionY)) });
     if (Settings.distanceChanged) {
         if (distance <= 1000) {
            newdistanceType = `${Math.round(distance)} ${config.GetLables(intl, "Meter")}`;
         } else {
            newdistanceType = `${(distance / 1000)}  ${config.GetLables(intl, "KM")}`;
         }
     } else {
        newdistanceType = `${(distance * 0.000621371192).toFixed(4)} ${config.GetLables(intl, "Miles")}`;
     }
 }



    return (
        <>
            <div className="col-xl-6 p-3">
                <div style={{ maxHeight: `450px`, overflow: 'auto' }}>
                {
                    location && googleMapLocations &&
                    <>
                           <h3 className={"fw-bold mb-1  text-ellipsis"}>{location.name}</h3>
                         <div className="d-flex justify-content-between mb-4">

                            <h6 className="location-address fw-normal">
                                {`${location.address1} ${location.address2} ${location.city} ${location.postalCode}`}
                            </h6>
                            <p className="fw-semibold text-center text-white-600 fs-6 d-block text-star text-gray-600 mb-2"><i className={distance <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800 me-2" : "fa fa-car fa-lg text-gray-800 me-2"}></i>{newdistanceType}</p>
                        </div>
                        <table className="table table-lg table-striped fs-6">
                            {
                                location && <tbody>
                                    {Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                                        let properties: any = config.ShopTimeSchedule[key].split(",");
                                        return (<tr key={i}>
                                            <th scope="row" className="fw-bold"> <GetLable label={properties[0]} /></th>
                                            <td align="center">{location.pickupPointSchedules1[properties[1]]} - {location.pickupPointSchedules1[properties[2]]}</td>
                                            <td align="right">{location.pickupPointSchedules1[properties[3]]} - {location.pickupPointSchedules1[properties[4]]}</td>
                                        </tr>)

                                    })}
                                </tbody>
                            }
                        </table>
                    </>
                }
            </div>
            </div>
        </>
    )
};
