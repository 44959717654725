import React, { FC } from "react";
import {PageTitle} from '../../../_metronic/layout/core'
import {PickUpPointTypePage} from './PickUpPointTypePage'

const PickUpPointTypeWrapper : FC = () => (
    <>
        <PageTitle breadcrumbs={[]}>Relay Point Lists</PageTitle>
        <PickUpPointTypePage />
    </>
)

export default PickUpPointTypeWrapper