import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ProductSlice } from "../../../../redux/ProductSlice";
import { config } from "../../../../Config/Appsettings";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Select } from "antd";
import { CarrierService } from "../../../../app/Models/MapModels";
import axios, { AxiosResponse } from "axios";
import { useIntl } from "react-intl";
import { GetLable } from "../../../../Config/GetLabels";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {Button, Modal} from "react-bootstrap";
import { CropperModal } from "../../../../app/common/Utils/CropperModal";

declare var $: any;
export const MapConfiguration = () => {
    const { actions } = ProductSlice;
    const dispatch = useDispatch();
    const [labels, setLabels] = useState<CarrierService[]>([]);
    const [activeTab, setActiveTab] = useState("Desktop");
    const [selectedHomeFile, setSelectedHomeFile] = useState<any>("default_map.png");
    const [selectedFile, setSelectedFile] = useState<any>("");
    const [manageTab, setManageTab] = useState(<GetLable label="Desktop" />);
    const [mobileSelectedFile, setMobileSelectedFile] = useState("default_map.png");
    const [mobileHomeSelectedFile, setMobileHomeSelectedFile] = useState("default_map.png");
    const intl = useIntl();
    let updateTheme: any = useSelector((state: any) => state?.product?.updateTheme, shallowEqual);
    const [characters, updateCharacters] = useState<any>([]);
    const [defaultLang, setDefaultLang] = useState<any | undefined>();
    const [rawImg, setRawImg] = useState<string>("");
    const [showPopup,setShowPopup] = useState<boolean>(false);
    const [fileObj,setFileObj] = useState<any>({fileName:"", isHomeOrPointer:null,config:""});
    var $uploadCrop : any;
    useEffect(() => {
        axios({
            method: 'GET',
            url: `${config.BaseUrl}PickUpPoint/CarrierServiceLabel`
        }).then((response: AxiosResponse<CarrierService[]>) => {
            let lacationsdata = response.data
            setLabels(lacationsdata)
        });
        const storedData = localStorage.getItem("mapConfiguration");
        if (storedData !== null) {
            let data = JSON.parse(storedData);
            dispatch(actions.UpdateTheme({ updateTheme: data }));
            setMobileHomeSelectedFile(data.mobileConfig.HomePointerIcon ? data.mobileConfig.HomePointerIcon : "default_map.png");
            setMobileSelectedFile(data.mobileConfig.MapPointerIcon ? data.mobileConfig.MapPointerIcon : "default_map.png");
            setSelectedHomeFile(data.desktopConfig.HomePointerIcon ? data.desktopConfig.HomePointerIcon : "default_map.png");
            setSelectedFile(data.desktopConfig.MapPointerIcon ? data.desktopConfig.MapPointerIcon : "default_map.png");
            setDefaultLang({ mobile: data.mobileConfig.LanguageSelection, desktop: data.desktopConfig.LanguageSelection });
            updateCharacters(data.mobileConfig.MobileLook);
        } else {
            setDefaultLang({ mobile: updateTheme.mobileConfig.LanguageSelection, desktop: updateTheme.desktopConfig.LanguageSelection });
            updateCharacters(updateTheme.mobileConfig.MobileLook);
        };
        setTimeout(() => {
             $('#upload-demo').croppie({
                viewport: {
                    width: 160,
                    height: 160,
                    type: 'circle'
                },
                enforceBoundary: false,
                enableExif: true
            });
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateHomeImage = (file: any, isHome: boolean, isDesktop: string) => {
        var bodyFormData = new FormData();
        bodyFormData.append('files', file);
        axios({
            method: 'POST',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            url: `${config.BaseUrl}PickUpPoint/UploadImage/true/${isHome}`
        }).then((response: AxiosResponse<any>) => {
            var data = response.data;
            if (isDesktop !== 'desktopConfig') {
                isHome ? setMobileHomeSelectedFile(data) : setMobileSelectedFile(data)
            } else {
                isHome ? setSelectedHomeFile(data) : setSelectedFile(data)
            }
        }).catch(function (error) {
            console.log(error);
        });
    };

    const navigate = useNavigate();
    let Settings: any
    const storedData = localStorage.getItem("mapConfiguration");
    if (storedData !== null) {
        Settings = JSON.parse(storedData);
        if (Object.keys(Settings.desktopConfig).length !== Object.keys(updateTheme.desktopConfig).length
            || Object.keys(Settings.mobileConfig).length !== Object.keys(updateTheme.mobileConfig).length) {
            localStorage.removeItem("mapConfiguration");
        };
    }
    else {
        Settings = updateTheme;
    };
    if (!Settings.desktopConfig.HomePointerIcon) Settings.desktopConfig.HomePointerIcon = "default_map.png";
    if (!Settings.desktopConfig.MapPointerIcon) Settings.desktopConfig.MapPointerIcon = "default_map.png";
    const handleRemoveImages = (isHome: boolean, imageName: string, isDesktop: string) => {
        axios({
            method: 'POST',
            url: `${config.BaseUrl}PickUpPoint/RemoveImage/${imageName}`
        }).then((response: AxiosResponse<any>) => {
            let obj: any = localStorage.getItem('mapConfiguration');
            if (obj !== null)
                obj = JSON.parse(obj);
            if (isDesktop !== "desktopConfig") {
                if (isHome) {
                    obj.mobileConfig.HomePointerIcon = "";
                    setMobileHomeSelectedFile("default_map.png");
                }
                else {
                    obj.mobileConfig.MapPointerIcon = "";
                    setMobileSelectedFile("default_map.png");
                }
            } else {
                if (isHome) {
                    obj.desktopConfig.HomePointerIcon = "";
                    setSelectedHomeFile("default_map.png");
                }
                else {
                    obj.desktopConfig.MapPointerIcon = "";
                    setSelectedFile("default_map.png");
                }
            }
            localStorage.setItem("mapConfiguration", JSON.stringify(obj));
        }).catch(function (error) {
            console.log(error);
        });
    };

    const onSubmit = (values: any) => {
        dispatch(actions.UpdateTheme({ updateTheme: values }));
        localStorage.setItem('mapConfiguration', JSON.stringify(values));
        navigate("/map");
        if (values.mobileConfig.LanguageSelection !== defaultLang.mobile || values.desktopConfig.LanguageSelection !== defaultLang.desktop) {
            window.location.reload();
        };
    };

    function handleOnDragEnd(result: any, setFieldValue: any) {
        if (!result.destination) {
            return;
        };
        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);
        updateCharacters(items);
        setFieldValue("mobileConfig.MobileLook", items);
    };

const saveCropedImage = () =>{
    $('#upload-demo').croppie('result', {
        type: 'canvas',
        enableOrientation: true,
        format: 'png',
        backgroundColor : "#ffffff",
        size: {width: 160, height: 160}
    }).then(function (resp  :any) {
        var arr = resp.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        var file = new File([u8arr],  fileObj.fileName, {type:mime || 'text/plain'});
        updateHomeImage(file,fileObj.isHomeOrPointer,fileObj.config);
        setShowPopup(false);
    });
};

const createCroppedImage = (imageFile : any) =>{
    setShowPopup(true);
    
    let reader: any = new FileReader();
    let rawImg: string = "";
    reader.onload = function (ele: any) {
        rawImg = ele.target.result
    }
    reader.readAsDataURL(imageFile);
    setTimeout(()=>{
        $('#upload-demo').croppie({
            viewport: {
                width: 160,
                height: 160,
                type: 'circle'
            },
            enforceBoundary: false,
            enableExif: true
        });
        $('#upload-demo').croppie('bind', {
            url: rawImg
        }).then(function(){
            console.log('jQuery bind complete');
        });
    },500);
};

    return (
        <>
        <Formik
            initialValues={Settings}
            onSubmit={(values: any) => {
                onSubmit(values);
            }}>
            {({
                values,
                setFieldValue,
                handleSubmit
            }) => (
                <>
                    <ul className="nav nav-tabs nav-pills border-0 flex-row me-5 fs-6 align-items-center fw-bolder">
                        <li className="nav-item w-md-200px me-0">
                            <button className="nav-link active fs-5" data-bs-toggle="tab" onClick={() => {
                                setActiveTab("Desktop");
                                setManageTab(<GetLable label="Desktop" />);
                            }}><GetLable label="DesktopConfiguration" /></button>
                        </li>
                        <li className="nav-item w-md-200px me-0 ms-3">
                            <button className="nav-link fs-5" data-bs-toggle="tab" onClick={() => {
                                setActiveTab("Mobile");
                                setManageTab(<GetLable label="Mobile" />);
                            }}><GetLable label="MobileConfiguration" /></button>
                        </li>
                    </ul>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'>
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>{manageTab} <GetLable label="Theme" /></h3>
                            </div>
                        </div>
                        <>
                            <div id='kt_account_profile_details' className='collapse show'>
                                <form>
                                    {activeTab === "Desktop" ? (
                                        <div className='card-body border-top p-9'>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span>{config.GetLables(intl, "DisplayMap")}</span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisplayMap.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='displayMapShow'
                                                                                value={item.value}
                                                                                id={`DisplayMap${index}`}
                                                                                checked={item.value === values.desktopConfig.displayMap}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.displayMap", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisplayMap${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'><GetLable label="SelectMap" /></label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className='d-flex align-items-center mt-3'>

                                                        {config.selectMap.map((item, index) => {
                                                            return (
                                                                <label key={index} htmlFor={`selectMap${index}`} className='form-check form-check-inline form-check-solid me-5'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id={`selectMap${index}`}
                                                                        type='checkbox'
                                                                        checked={values.desktopConfig[item.propertyName]}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setFieldValue(`desktopConfig.${[item.propertyName]}`, true);
                                                                            } else {
                                                                                setFieldValue(`desktopConfig.${[item.propertyName]}`, false);
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className='fw-bold ps-2 fs-6'>{item.label}</span>
                                                                </label>
                                                            )
                                                        })}

                                                    </div>
                                                </div>



                                                {
                                                    (values.desktopConfig.isBingMap || values.desktopConfig.isGoogleMap || values.desktopConfig.isOpenStreetMap) &&
                                                    <div className="col-xl-8 offset-lg-4 fv-row mt-3">
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <label htmlFor="DivideMapsIntotwoParts" className='form-check form-check-inline form-check-solid me-5'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id='DivideMapsIntotwoParts'
                                                                        type='checkbox'
                                                                        checked={true === values.desktopConfig.isDivideMapsIntotwoParts}
                                                                        onChange={(e) => {
                                                                            setFieldValue("desktopConfig.isDivideMapsIntotwoParts", e.target.checked);
                                                                        }
                                                                        }
                                                                    /><span className="fw-bold ps-2 fs-6"><GetLable label="DivideMapsIntoTwoParts" /></span>
                                                                </label>

                                                            </div>

                                                        </div>
                                                        <span>
                                                        </span>
                                                    </div>
                                                }




                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectDesign" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <label className="color-selector">
                                                        <div className="d-flex align-items-center">
                                                            <span className="circle" style={{ background: values.desktopConfig.selectedThemeColor }}></span><span className="font-weight-500">{values.desktopConfig.selectedThemeColor}</span>
                                                            <input type="color"
                                                                value={values.desktopConfig.selectedThemeColor}
                                                                onChange={(e) => { setFieldValue("desktopConfig.selectedThemeColor", e.target.value); }}
                                                                className="hidden" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectColorOfMarker" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <label className="color-selector">
                                                        <div className="d-flex align-items-center">
                                                            <span className="circle" style={{ background: values.desktopConfig.iconChanged }}></span><span className="font-weight-500">{values.desktopConfig.iconChanged}</span>
                                                            <input type="color"
                                                                value={values.desktopConfig.iconChanged}
                                                                onChange={(e) => { setFieldValue("desktopConfig.iconChanged", e.target.value); }}
                                                                className="hidden" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Limit" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input type="number"
                                                        value={values.desktopConfig.limitchanges}
                                                        name="desktopConfig.limitchanges"
                                                        onChange={(e) => {
                                                            setFieldValue("desktopConfig.limitchanges", e.target.value);
                                                        }} className="form-control" placeholder={config.GetLables(intl, "Limit")} />
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Carrierservice" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={<GetLable label="Carrierservice" />}
                                                        value={values.desktopConfig.serviceChanged === "" ? undefined : values.desktopConfig.serviceChanged}
                                                        options={labels.map(item => ({ label: item.label, value: item.carrierServiceId }))}
                                                        maxTagCount='responsive'
                                                        onChange={(value) => {
                                                            if (value.length === 0) value = undefined;
                                                            setFieldValue("desktopConfig.serviceChanged", value);
                                                        }}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                    />
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="LocationPanel" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="row">
                                                        {
                                                            config.RightLeftOrder.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-4 col-md-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='locationpanel'
                                                                                id={`RightLeftOrder${index}`}
                                                                                value={item.value === null ? "" : item.value}
                                                                                checked={item.value === values.desktopConfig.orderChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.orderChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`RightLeftOrder${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            values.desktopConfig.orderChanged !== null &&
                                                            <div className="col-12 col-lg-6 fv-row mt-3 mt-md-0">
                                                                <div>
                                                                    <label htmlFor="SideMapOptionDistanceWithOnlyNumber" className='form-check form-check-inline form-check-solid ps-md-0'>
                                                                        <input
                                                                            className='form-check-input'
                                                                            name='map'
                                                                            id='SideMapOptionDistanceWithOnlyNumber'
                                                                            type='checkbox'
                                                                            checked={true === values.desktopConfig.sideMapOptionDistanceWithOnlyNumber}
                                                                            onChange={(e) => {
                                                                                setFieldValue("desktopConfig.sideMapOptionDistanceWithOnlyNumber", e.target.checked);
                                                                            }
                                                                            }
                                                                        />
                                                                        <span className="fw-bold fs-6 ms-2"><GetLable label="DistanceWithOnlyANumber" /></span>
                                                                    </label>

                                                                </div>

                                                                <span>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                values.desktopConfig.orderChanged !== "locMenuHide" ?
                                                    < div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            <span className='d-flex flex-column'>
                                                                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                    <GetLable label="RightLeftPanelOption" />
                                                                </span>
                                                                <span className='fs-6 fw-bold text-gray-400'>
                                                                    <GetLable label="WithImages" />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className='col-lg-4 fv-row'>
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {
                                                                    config.ShowImages.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className='col-4 fv-row'>
                                                                                <div className="form-check form-check-custom form-check-solid">
                                                                                    <input className='form-check-input' type='radio' name='showimage'
                                                                                        value={item.value}
                                                                                        id={`ShowImages${index}`}
                                                                                        checked={item.value === values.desktopConfig.showImages}
                                                                                        onChange={(e) => {
                                                                                            setFieldValue("desktopConfig.showImages", item.value);
                                                                                        }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`ShowImages${index}`}>{item.label}</label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : ""
                                            }
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="TopMapOption" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.TopManu.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='topmanu'
                                                                                value={item.value}
                                                                                id={`TopManu${index}`}
                                                                                checked={item.value === values.desktopConfig.topManuChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.topManuChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`TopManu${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            values.desktopConfig.topManuChanged &&
                                                            <div className="col-lg-8 fv-row">
                                                                <label htmlFor="TopMapOptionDistanceWithNumber" className='form-check form-check-inline form-check-solid ps-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id='TopMapOptionDistanceWithNumber'
                                                                        type='checkbox'
                                                                        checked={true === values.desktopConfig.topMapOptionDistanceWithOnlyNumber}
                                                                        onChange={(e) => {
                                                                            setFieldValue("desktopConfig.topMapOptionDistanceWithOnlyNumber", e.target.checked);
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className="fw-bold fs-6 ms-2"><GetLable label="DistanceWithOnlyANumber" /></span>
                                                                </label>
                                                                <span>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="BottomMapOption" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.BottomManu.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='bottomManu'
                                                                                value={item.value}
                                                                                id={`BottomManu${index}`}
                                                                                checked={item.value === values.desktopConfig.bottomManuChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.bottomManuChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`BottomManu${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            values.desktopConfig.bottomManuChanged &&
                                                            <div className="col-lg-8 fv-row">
                                                                <label htmlFor="BottomMapOptionDistanceWithOnlyNumber" className='form-check form-check-inline form-check-solid ps-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id='BottomMapOptionDistanceWithOnlyNumber'
                                                                        type='checkbox'
                                                                        checked={true === values.desktopConfig.bottomMapOptionDistanceWithOnlyNumber}
                                                                        onChange={(e) => {
                                                                            setFieldValue("desktopConfig.bottomMapOptionDistanceWithOnlyNumber", e.target.checked);
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className=" fw-bold fs-6 ms-2"><GetLable label="DistanceWithOnlyANumber" /></span>
                                                                </label>

                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="ShowLogoOption" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.ShowLogo.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='showLogo'
                                                                                value={item.value}
                                                                                id={`ShowLogo${index}`}
                                                                                checked={item.value === values.desktopConfig.isLogoShow}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isLogoShow", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`ShowLogo${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Distance" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.Distance.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='distance'
                                                                                value={item.value}
                                                                                id={`Distance${index}`}
                                                                                checked={item.value === values.desktopConfig.distanceChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.distanceChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`Distance${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectHomeIcon" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <div className='col-lg-7 col-xl-5 fv-row'>
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <a><div className="symbol-label">
                                                                        <img src={`${config.CustomHomeImageUrl}${selectedHomeFile}`} alt={values.desktopConfig.HomePointerIcon} className="w-100"></img>
                                                                    </div></a>
                                                                </div>
                                                                <label htmlFor="file-upload" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> <GetLable label="Update" />
                                                                </label>
                                                                <input id="file-upload"
                                                                    type="file"
                                                                    onChange={(e: any) => {
                                                                        setFileObj({fileName: e.target.files?.[0].name ,isHomeOrPointer : true, config:"desktopConfig"});
                                                                        createCroppedImage(e.target.files?.[0]);
                                                                        setFieldValue("desktopConfig.HomePointerIcon", e.target.files?.[0].name);
                                                                    }} />
                                                                <button type="button" onClick={() => {
                                                                    handleRemoveImages(true, values.desktopConfig.HomePointerIcon, "desktopConfig");
                                                                    setFieldValue("desktopConfig.HomePointerIcon", "");
                                                                }} className="btn btn-sm btn-danger m-3"><GetLable label="Remove" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectPointerIcon" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <div className='col-lg-7 col-xl-5 fv-row'>
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <a><div className="symbol-label">
                                                                        <img src={`${config.CustomPointerImageUrl}${selectedFile}`} alt={selectedFile} className="w-100"></img>
                                                                    </div></a>
                                                                </div>
                                                                <label htmlFor="file-upload1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> <GetLable label="Update" />
                                                                </label>
                                                                <input id="file-upload1"
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                        setFileObj({fileName: e.target.files?.[0].name ,isHomeOrPointer : false, config:"desktopConfig"});
                                                                        createCroppedImage(e.target.files?.[0]);
                                                                        setFieldValue("desktopConfig.MapPointerIcon", e.target.files?.[0].name)
                                                                    }} />
                                                                <button type="button" onClick={() => {
                                                                    handleRemoveImages(false, values.desktopConfig.MapPointerIcon, "desktopConfig");
                                                                    setFieldValue("desktopConfig.MapPointerIcon", "")
                                                                }} className="btn btn-sm btn-danger m-3"><GetLable label="Remove" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Languages" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.LanguagesSelection.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-6 col-xl-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='languagesSelection'
                                                                                value={item.value}
                                                                                id={`LanguagesSelection${index}`}
                                                                                checked={item.value === values.desktopConfig.LanguageSelection}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.LanguageSelection", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`LanguagesSelection${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplayTopHours" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisPlayTopListHours.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DisPlayTopListHours'
                                                                                value={item.value}
                                                                                id={`DisPlayTopListHours${index}`}
                                                                                checked={item.value === values.desktopConfig.isTopHours}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isTopHours", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisPlayTopListHours${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplaySideHours" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisPlaySideListHours.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DisPlaySideListHours'
                                                                                value={item.value}
                                                                                id={`DisPlaySideListHours${index}`}
                                                                                checked={item.value === values.desktopConfig.isSideHours}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isSideHours", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisPlaySideListHours${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplayMapHours" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisPlayMapListHours.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DisPlayMapListHours'
                                                                                value={item.value}
                                                                                id={`DisPlayMapListHours${index}`}
                                                                                checked={item.value === values.desktopConfig.isMapHours}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isMapHours", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisPlayMapListHours${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="UseNumbers" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.UseNumbers.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='UseNumbers'
                                                                                value={item.value}
                                                                                id={`UseNumbers${index}`}
                                                                                checked={item.value === values.desktopConfig.isUseNumbers}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isUseNumbers", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`UseNumbers${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectIcon" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.SelectIcon.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='SelectIcon'
                                                                                value={item.value}
                                                                                id={`SelectIcon${index}`}
                                                                                checked={item.value === values.desktopConfig.isSelectIcon}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isSelectIcon", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`SelectIcon${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span> Duplicate Relay Point </span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DuplicateRelayPoint.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DuplicateRelayPoint'
                                                                                value={item.value}
                                                                                id={`DuplicateRelayPoint${index}`}
                                                                                checked={item.value === values.desktopConfig.isDuplicateRelayPoint}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("desktopConfig.isDuplicateRelayPoint", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DuplicateRelayPoint${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='card-body border-top p-9'>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplayMap" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisplayMap.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='displayMapShow'
                                                                                value={item.value}
                                                                                id={`DisplayMap${index}`}
                                                                                checked={item.value === values.mobileConfig.displayMap}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.displayMap", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisplayMap${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'><GetLable label="SelectMap" /></label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className='d-flex align-items-center mt-3'>
                                                        {config.selectMap.map((item, index) => {
                                                            return (
                                                                <label key={index} htmlFor={`selectMap${index}`} className='form-check form-check-inline form-check-solid me-5'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id={`selectMap${index}`}
                                                                        type='checkbox'
                                                                        checked={values.mobileConfig[item.propertyName]}
                                                                        onChange={(e) => {
                                                                            if (e.target.checked) {
                                                                                setFieldValue(`mobileConfig.${[item.propertyName]}`, true);
                                                                            } else {
                                                                                setFieldValue(`mobileConfig.${[item.propertyName]}`, false);
                                                                            }
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className='fw-bold ps-2 fs-6'>{item.label}</span>
                                                                </label>
                                                            )
                                                        })}
                                                    </div>
                                                </div>



                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectDesign" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <label className="color-selector">
                                                        <div className="d-flex align-items-center">
                                                            <span className="circle" style={{ background: values.mobileConfig.selectedThemeColor }}></span><span className="font-weight-500">{values.mobileConfig.selectedThemeColor}</span>
                                                            <input type="color"
                                                                value={values.mobileConfig.selectedThemeColor}
                                                                onChange={(e) => { setFieldValue("mobileConfig.selectedThemeColor", e.target.value); }}
                                                                className="hidden" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectColorOfMarker" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <label className="color-selector">
                                                        <div className="d-flex align-items-center">
                                                            <span className="circle" style={{ background: values.mobileConfig.iconChanged }}></span><span className="font-weight-500">{values.mobileConfig.iconChanged}</span>
                                                            <input type="color"
                                                                value={values.mobileConfig.iconChanged}
                                                                onChange={(e) => { setFieldValue("mobileConfig.iconChanged", e.target.value); }}
                                                                className="hidden" />
                                                        </div>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Limit" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <input type="number"
                                                        value={values.mobileConfig.limitchanges}
                                                        name="mobileConfig.limitchanges"
                                                        onChange={(e) => {
                                                            setFieldValue("mobileConfig.limitchanges", e.target.value);
                                                        }} className="form-control" placeholder={config.GetLables(intl, "Limit")} />
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Carrierservice" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <Select
                                                        mode="multiple"
                                                        placeholder={<GetLable label="Carrierservice" />}
                                                        value={values.mobileConfig.serviceChanged === "" ? undefined : values.mobileConfig.serviceChanged}
                                                        options={labels.map(item => ({ label: item.label, value: item.carrierServiceId }))}
                                                        maxTagCount='responsive'
                                                        onChange={(value) => {
                                                            setFieldValue("mobileConfig.serviceChanged", value);
                                                        }}
                                                        filterOption={(input, option) =>
                                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                        }
                                                    />
                                                </div>
                                            </div>
                                                      <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="LocationPanel" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="row">
                                                        {
                                                            config.RightLeftOrder.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-4 col-md-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='locationpanel'
                                                                                id={`RightLeftOrder${index}`}
                                                                                value={item.value === null ? "" : item.value}
                                                                                checked={item.value === values.mobileConfig.orderChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.orderChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`RightLeftOrder${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            values.mobileConfig.orderChanged !== null &&
                                                            <div className="col-12 col-lg-6 fv-row mt-3 mt-md-0">
                                                                <div>
                                                                    <label htmlFor="SideMapOptionDistanceWithOnlyNumber" className='form-check form-check-inline form-check-solid ps-md-0'>
                                                                        <input
                                                                            className='form-check-input'
                                                                            name='map'
                                                                            id='SideMapOptionDistanceWithOnlyNumber'
                                                                            type='checkbox'
                                                                            checked={true === values.mobileConfig.sideMapOptionDistanceWithOnlyNumber}
                                                                            onChange={(e) => {
                                                                                setFieldValue("mobileConfig.sideMapOptionDistanceWithOnlyNumber", e.target.checked);
                                                                            }
                                                                            }
                                                                        />
                                                                        <span className="fw-bold fs-6 ms-2"><GetLable label="DistanceWithOnlyANumber" /></span>
                                                                    </label>

                                                                </div>

                                                                <span>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                values.mobileConfig.orderChanged !== "locMenuHide" ?
                                                    <div className='row mb-6'>
                                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                            <span className='d-flex flex-column'>
                                                                <span className='fw-bolder text-gray-800 text-hover-primary fs-5'>
                                                                    <GetLable label="RightLeftPanelOption" />
                                                                </span>
                                                                <span className='fs-6 fw-bold text-gray-400'>
                                                                    <GetLable label="WithImages" />
                                                                </span>
                                                            </span>
                                                        </label>
                                                        <div className='col-lg-4 fv-row'>
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                {
                                                                    config.ShowImages.map((item, index) => {
                                                                        return (
                                                                            <div key={index} className='col-lg-4 fv-row'>
                                                                                <div className="form-check form-check-custom form-check-solid">
                                                                                    <input className='form-check-input' type='radio' name='showimage'
                                                                                        value={item.value}
                                                                                        id={`ShowImages${index}`}
                                                                                        checked={item.value === values.mobileConfig.showImages}
                                                                                        onChange={(e) => {
                                                                                            setFieldValue("mobileConfig.showImages", item.value);
                                                                                        }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`ShowImages${index}`}>{item.label}</label>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div> : ""
                                            }

            
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="TopMapOption" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.TopManu.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='topmanu'
                                                                                value={item.value}
                                                                                id={`TopManu${index}`}
                                                                                checked={item.value === values.mobileConfig.topManuChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.topManuChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`TopManu${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            values.mobileConfig.topManuChanged &&
                                                            <div className="col-lg-8 fv-row">
                                                                <label htmlFor="TopMapOptionDistanceWithNumber" className='form-check form-check-inline form-check-solid ps-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id='TopMapOptionDistanceWithNumber'
                                                                        type='checkbox'
                                                                        checked={true === values.mobileConfig.topMapOptionDistanceWithOnlyNumber}
                                                                        onChange={(e) => {
                                                                            setFieldValue("mobileConfig.topMapOptionDistanceWithOnlyNumber", e.target.checked);
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className="fw-bold fs-6 ms-2"><GetLable label="DistanceWithOnlyANumber" /></span>
                                                                </label>
                                                                <span>
                                                                </span>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="BottomMapOption" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.BottomManu.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-2 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='bottomManu'
                                                                                value={item.value}
                                                                                id={`BottomManu${index}`}
                                                                                checked={item.value === values.mobileConfig.bottomManuChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.bottomManuChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`BottomManu${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                        {
                                                            values.mobileConfig.bottomManuChanged &&
                                                            <div className="col-lg-8 fv-row">
                                                                <label htmlFor="BottomMapOptionDistanceWithOnlyNumber" className='form-check form-check-inline form-check-solid ps-0'>
                                                                    <input
                                                                        className='form-check-input'
                                                                        name='map'
                                                                        id='BottomMapOptionDistanceWithOnlyNumber'
                                                                        type='checkbox'
                                                                        checked={true === values.mobileConfig.bottomMapOptionDistanceWithOnlyNumber}
                                                                        onChange={(e) => {
                                                                            setFieldValue("mobileConfig.bottomMapOptionDistanceWithOnlyNumber", e.target.checked);
                                                                        }
                                                                        }
                                                                    />
                                                                    <span className=" fw-bold fs-6 ms-2"><GetLable label="DistanceWithOnlyANumber" /></span>
                                                                </label>

                                                            </div>
                                                        }
                                                    </div>
                                                </div>

                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="ShowLogoOption" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.ShowLogo.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='showLogo'
                                                                                value={item.value}
                                                                                id={`ShowLogo${index}`}
                                                                                checked={item.value === values.mobileConfig.isLogoShow}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isLogoShow", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`ShowLogo${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Distance" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.Distance.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='distance'
                                                                                value={item.value}
                                                                                id={`Distance${index}`}
                                                                                checked={item.value === values.mobileConfig.distanceChanged}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.distanceChanged", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`Distance${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectHomeIcon" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <div className='col-lg-4 fv-row'>
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <a><div className="symbol-label">
                                                                        <img src={`${config.CustomHomeImageUrl}${mobileHomeSelectedFile}`} alt={mobileHomeSelectedFile} className="w-100"></img>
                                                                    </div></a>
                                                                </div>
                                                                <label htmlFor="file-upload" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> <GetLable label="Update" />
                                                                </label>
                                                                <input id="file-upload"
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                        setFileObj({fileName: e.target.files?.[0].name ,isHomeOrPointer : true, config:"mobileConfig"});
                                                                        createCroppedImage(e.target.files?.[0]);
                                                                        setFieldValue("mobileConfig.HomePointerIcon", e.target.files?.[0].name)
                                                                    }} />
                                                                <button type="button" onClick={() => {
                                                                    handleRemoveImages(true, values.mobileConfig.HomePointerIcon, "mobileConfig");
                                                                    setFieldValue("mobileConfig.HomePointerIcon", "");
                                                                }} className="btn btn-sm btn-danger m-3"><GetLable label="Remove" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectPointerIcon" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        <div className='col-lg-4 fv-row'>
                                                            <div className="form-check form-check-custom form-check-solid">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <a><div className="symbol-label">
                                                                        <img src={`${config.CustomPointerImageUrl}${mobileSelectedFile}`} alt={mobileSelectedFile} className="w-100"></img>
                                                                    </div></a>
                                                                </div>
                                                                <label htmlFor="file-upload1" className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i> <GetLable label="Update" />
                                                                </label>
                                                                <input id="file-upload1"
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                        setFileObj({fileName: e.target.files?.[0].name ,isHomeOrPointer : false, config:"mobileConfig"});
                                                                        createCroppedImage(e.target.files?.[0]);
                                                                        setFieldValue("mobileConfig.MapPointerIcon", e.target.files?.[0].name)
                                                                    }} />
                                                                <button type="button" onClick={() => {
                                                                    handleRemoveImages(false, values.mobileConfig.MapPointerIcon, "mobileConfig");
                                                                    setFieldValue("mobileConfig.MapPointerIcon", "")
                                                                }} className="btn btn-sm btn-danger m-3"><GetLable label="Remove" /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="Languages" /></span>
                                                </label>
                                                <div className='col-lg-8 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.LanguagesSelection.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='languagesSelection'
                                                                                value={item.value}
                                                                                id={`LanguagesSelection${index}`}
                                                                                checked={item.value === values.mobileConfig.LanguageSelection}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.LanguageSelection", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`LanguagesSelection${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <GetLable label="View" />
                                                </label>
                                                <div className='col-md-6 col-lg-4 col-xl-3 fv-row'>
                                                    <DragDropContext onDragEnd={(result) => { handleOnDragEnd(result, setFieldValue); }}>
                                                        <Droppable droppableId="characters">
                                                            {(provided) => (
                                                                <ul className="characters mobile-view-ul" {...provided.droppableProps} ref={provided.innerRef}>
                                                                    {values.mobileConfig.MobileLook.map(({ label, value, id, icon }: any, index: number) => {
                                                                        return (
                                                                            <Draggable key={id} draggableId={id} index={index}>
                                                                                {(provided) => (
                                                                                    <div className="DragItem" ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                                                        <div className="Author">
                                                                                            <div className="symbol symbol-circle symbol-50px overflow-hidden me-3"><a><div className="symbol-label">
                                                                                                <img src={icon} alt={icon} className="w-100" />
                                                                                            </div></a></div>
                                                                                            <span className="fw-bold"><GetLable label={label.split(" ").join("")} /></span>
                                                                                        </div>
                                                                                    </div>
                                                                                )}
                                                                            </Draggable>
                                                                        );
                                                                    })}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            )}
                                                        </Droppable>
                                                    </DragDropContext>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplayTopHours" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisPlayTopListHours.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DisPlayTopListHours'
                                                                                value={item.value}
                                                                                id={`DisPlayTopListHours${index}`}
                                                                                checked={item.value === values.mobileConfig.isTopHours}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isTopHours", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisPlayTopListHours${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplaySideHours" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisPlaySideListHours.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DisPlaySideListHours'
                                                                                value={item.value}
                                                                                id={`DisPlaySideListHours${index}`}
                                                                                checked={item.value === values.mobileConfig.isSideHours}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isSideHours", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisPlaySideListHours${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="DisplayMapHours" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DisPlayMapListHours.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DisPlayMapListHours'
                                                                                value={item.value}
                                                                                id={`DisPlayMapListHours${index}`}
                                                                                checked={item.value === values.mobileConfig.isMapHours}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isMapHours", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DisPlayMapListHours${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="UseNumbers" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.UseNumbers.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='UseNumbers'
                                                                                value={item.value}
                                                                                id={`UseNumbers${index}`}
                                                                                checked={item.value === values.mobileConfig.isUseNumbers}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isUseNumbers", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`UseNumbers${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span><GetLable label="SelectIcon" /></span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.SelectIcon.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='SelectIcon'
                                                                                value={item.value}
                                                                                id={`SelectIcon${index}`}
                                                                                checked={item.value === values.mobileConfig.isSelectIcon}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isSelectIcon", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`SelectIcon${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='row mb-6'>
                                                <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                                    <span> Duplicate Relay Point </span>
                                                </label>
                                                <div className='col-lg-4 fv-row'>
                                                    <div className="form-check form-check-custom form-check-solid">
                                                        {
                                                            config.DuplicateRelayPoint.map((item, index) => {
                                                                return (
                                                                    <div key={index} className='col-lg-4 fv-row'>
                                                                        <div className="form-check form-check-custom form-check-solid">
                                                                            <input className='form-check-input' type='radio' name='DuplicateRelayPoint'
                                                                                value={item.value}
                                                                                id={`DuplicateRelayPoint${index}`}
                                                                                checked={item.value === values.mobileConfig.isDuplicateRelayPoint}
                                                                                onChange={(e) => {
                                                                                    setFieldValue("mobileConfig.isDuplicateRelayPoint", item.value);
                                                                                }} /><label className="form-check-label fw-bolder text-gray-800 text-hover-primary fs-5" htmlFor={`DuplicateRelayPoint${index}`}>{item.label}</label>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    }
                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                        <button style={{ border: "none", background: "none" }}> <Link to="/map" className="btn btn-dark"><GetLable label="Back" /></Link></button>
                                        <button type="button" className="btn btn-primary" onClick={() => handleSubmit()}> <GetLable label="Save" />  </button>
                                    </div>
                                </form>
                            </div>
                        </>
                    </div>
                    
                </>
            )}
        </Formik>
<CropperModal 
saveCropedImage={saveCropedImage} 
setShowPopup={setShowPopup}
showPopup={showPopup}
/>                                                    															
        </>
    );
};