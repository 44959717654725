import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { GoogleMapLocatins } from "../../Interfaces/MapInterfaces";
import { Tooltip as AntdTooltip } from 'antd';
import { config } from "../../../Config/Appsettings";
import { GetLable } from "../../../Config/GetLabels";
import { GoogleMapSetTooltipBox } from "../../common/Utils/DynamicPopupAlignment";
 
export const GoogleLocationPin: FC<GoogleMapLocatins> = ({ locations,  pickUpPointId,  specificLocation, text, key, icon, handleMainBox, isChecked, Settings, locationsLenth, handleCheckBox, distanceType, mapCounts, count, index, carrierService }) => {
    const [isClose, setIsClose] = useState(Settings.isDivideMapsIntotwoParts ? false : true);
    const closePopup = (value: boolean) => {
        if (!Settings.isDivideMapsIntotwoParts) {
            setIsClose(value);
        }
    };

 useEffect(()=>{
    GoogleMapSetTooltipBox();
 });

 
    return (
        <>
            {
                locationsLenth === 2 && specificLocation.length >= 1 && text !== "Home" && specificLocation ?
                    <>
                        {
                            <>
                                <div key={key} className="position-relative">
                                    <div className={Settings.isUseNumbers ? "single-location-marker-with-just-number" : "single-location-marker"}>
                                        <div style={{ transform: 'rotate(45deg)' }}>
                                            {
                                                specificLocation.length > 1 ?
                                                    Settings.isUseNumbers ?
                                                        <span
                                                            onClick={() => {
                                                                GoogleMapSetTooltipBox();
                                                                if (Settings.isDivideMapsIntotwoParts) {
                                                                    handleMainBox(specificLocation)
                                                                } else {
                                                                    isClose && text !== "Home" ? handleMainBox(specificLocation) : closePopup(true);
                                                                }
                                                                var element = document.getElementById(specificLocation[0].pickUpPointId);
                                                                element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                            }}
                                                            className="d-flex justify-content-center align-items-center text-white">
                                                            <div className="text-center">
                                                                <p className="text-center location-counter-map">{mapCounts}</p>
                                                            </div>
                                                        </span>
                                                        :
                                                        <span className="d-block text-center">
                                                            <img src={icon} alt="Error" className="map-thumbnail"
                                                                onClick={() => {
                                                                GoogleMapSetTooltipBox();
                                                                    if (Settings.isDivideMapsIntotwoParts) {
                                                                        handleMainBox(specificLocation)
                                                                    } else {
                                                                        isClose && text !== "Home" ? handleMainBox(specificLocation) : closePopup(true);
                                                                    }
                                                                    var element = document.getElementById(specificLocation[0].pickUpPointId);
                                                                    element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                                }} />
                                                        </span>
                                                    :

                                                    Settings.isUseNumbers ?
                                                        <span
                                                            onClick={() => {
                                                                if (Settings.isDivideMapsIntotwoParts) {
                                                                    handleMainBox(specificLocation)
                                                                } else {
                                                                    isClose && text !== "Home" ? handleMainBox(specificLocation) : closePopup(true);
                                                                }
                                                                var element = document.getElementById(specificLocation[0].pickUpPointId);
                                                                element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                            }}
                                                            className="d-flex justify-content-center align-items-center text-white">
                                                            <div className="text-center">
                                                                <p className="text-center location-counter-map">{mapCounts}</p>
                                                            </div>
                                                        </span>
                                                        :
                                                        <span className="d-flex justify-content-center align-items-center" onClick={() => {
                                                            if (Settings.isDivideMapsIntotwoParts) {
                                                                handleMainBox(specificLocation)
                                                            } else {
                                                                isClose && text !== "Home" ? handleMainBox(specificLocation) : closePopup(true);
                                                            }
                                                            var element = document.getElementById(specificLocation[0].pickUpPointId);
                                                            element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                        }}>
                                                            <img src={icon} alt="Error" className="map-thumbnail" />
                                                        </span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                        {
                            <>
                                {
                                    isClose ? <div  id="googleMapTooltipBox"  className={`card border-0 custom-tooltip-for-multiple-location-googlemap`} role="button">
                                        <div className="card-body p-2">
                                            <button className='btn btn-sm btn-icon me-n5 close-button close-tooltip-icon' id="close-button" onClick={() => closePopup(false)}><i className="fa fa-close text-black"></i></button>
                                            <div className="d-flex justify-content-between align-items-start">
                                                <h4 className="text-gray-800 fw-bold mb-1 me-2 fs-6  text-wrap text-ellipsis">{specificLocation[0].name}</h4>
                                                {
                                                    Settings.isLogoShow ?
                                                        <div className="symbol symbol-circle symbol-30px">
                                                            <span><div className="symbol-label">
                                                                {
                                                                    Settings.isSelectIcon == null ?
                                                                    <img src={specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked) >= 0 ?
                                                                        `${specificLocation[specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].carrierService.logoName !== "" ? `${config.SeriveImageUrl}${specificLocation[specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].carrierService.logoName}` : `/media/avatars/default_map.png`}`
                                                                        :
                                                                        specificLocation[0].carrierService?.logoName ? `${config.SeriveImageUrl}${specificLocation[0].carrierService?.logoName}` : "/media/avatars/default_map.png"} alt="Error" className=" rounded-circle w-100"></img> :
                                                                        Settings.isSelectIcon == true ?
                                                                        <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="Error" className=" rounded-circle w-100"></img> :
                                                                            <img src={specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked) >= 0 ?
                                                                                `${specificLocation[specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].pickupPointType && specificLocation[specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].pickupPointType.logoName !== "" ? `${config.ImageUrl}${specificLocation[specificLocation.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].pickupPointType.logoName}` : `/media/avatars/default_map.png`}`
                                                                                :
                                                                                specificLocation[0].pickupPointType?.logoName ? `${config.ImageUrl}${specificLocation[0].pickupPointType?.logoName}` : "/media/avatars/default_map.png"} alt="Error" className=" rounded-circle w-100"></img> 
                                                                }
                                                                
                                                            </div></span>
                                                        </div>
                                                        :
                                                        null
                                                }
                                            </div>
                                            <span className="fw-semibold text-gray-800 mb-1 fs-6 text-start d-block"><i className={specificLocation[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking text-gray-800 me-2 me-2" : "fa fa-car text-gray-800 me-2 me-2"}></i>{distanceType}</span>
                                            <span className="fw-semibold text-primary fs-6 mb-2  text-start text-wrap line-height-normal text-ellipsis">{specificLocation[0].address1} {specificLocation[0].address2} {specificLocation[0].city} {specificLocation[0].postalCode}</span>
                                            {
                                                specificLocation.map((item: any, num: any) => {
                                                    return (
                                                        <div className="mb-1 pointerClose location-checkbox" key={num}>
                                                            <div className="form-check pointerClose form-check-form-check form-check-custom form-check-success form-check-sm">
                                                                <input className="form-check-input h-15px w-15px" type="checkbox" id={`checkbox-${item.pickUpPointId + item.carrierServiceId}`}
                                                                    checked={isChecked === item.pickUpPointId + item.carrierServiceId}
                                                                    onChange={(e) => {
                                                                        if (e.target === e.currentTarget) {
                                                                            handleCheckBox(specificLocation, item, num, isChecked);
                                                                            GoogleMapSetTooltipBox();
                                                                        };
                                                                    }} />
                                                                <label className="form-check-label text-black" htmlFor={`checkbox-${item.pickUpPointId + item.carrierServiceId}`}>
                                                                    {/* {item.carrierService?.label} */}
                                                                    {item.carrierService?.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }

                                                {
                                                    Settings.isMapHours ? 
                                                     specificLocation[0].pickupPointSchedules1  ? 
                                                     <table className="table table-striped google-map-time-box-table">
                                                     <tbody>
                                                         {Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                                                             let properties: any = config.ShopTimeSchedule[key].split(",");
                                                             return ( 
                                                               <tr key={i}>
                                                                 <th scope="row"> <GetLable label={properties[0]} /></th>
                                                                 <td>{specificLocation[0].pickupPointSchedules1[properties[1]]} - {specificLocation[0].pickupPointSchedules1[properties[2]]}</td>
                                                                 <td>{specificLocation[0].pickupPointSchedules1[properties[3]]} - {specificLocation[0].pickupPointSchedules1[properties[4]]}</td>
                                                             </tr>)
     
                                                         })}
                                                     </tbody>
                                                 </table> : 
                                                     <div className="card-body p-2 h-100"><div className="h-100 d-flex align-items-center text-center w-100"><div className="w-100"> Schedules are not <br />available  </div></div></div> 

                                                     :null
                                                }

                                   
                                        </div>
                                    </div> : ''
                                }
                            </>
                        }
                    </>
                    :
                    <AntdTooltip placement="top" title={text}>
                        <div key={key}>
                            {
                                <>
                                    {!icon.includes("default_map") ?

                                        <div className={Settings.isUseNumbers && text !== "Home" ? "single-location-marker-with-just-number" : "single-location-marker"} data-google-single-location-marker="">
                                            <div style={{ transform: 'rotate(45deg)' }}>
                                                {
                                                    Settings.isUseNumbers && text !== "Home" ?
                                                        <>
                                                            <span className="d-flex justify-content-center align-items-center text-white">
                                                                <div className="text-center" onClick={() => {
                                                                    if (text !== "Home") {
                                                                         GoogleMapSetTooltipBox();
                                                                        let singleServiceMarker: any[] = locations.filter((ele) => ele[0].pickUpPointId === pickUpPointId)[0];
                                                                        handleMainBox(singleServiceMarker);
                                                                        var element = document.getElementById(singleServiceMarker[0].pickUpPointId);
                                                                        element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                                    }
                                                                }
                                                                } >

                                                                    <p className="text-center location-counter-map">{count ? count : mapCounts}</p>
                                                                </div>
                                                            </span>
                                                        </> :
                                                        <>
                                                            <span className="d-flex justify-content-center align-items-center">
                                                                <img alt="Error" src={icon} className="map-thumbnail" onClick={() => {
                                                                    if (text !== "Home") {
                                                                        GoogleMapSetTooltipBox();
                                                                              if(pickUpPointId){
                                                                                let singleServiceMarker: any[] = locations.filter((ele) => ele[0].pickUpPointId === pickUpPointId)[0];
                                                                                handleMainBox(singleServiceMarker);
                                                                                var element = document.getElementById(singleServiceMarker[0].pickUpPointId);
                                                                                element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                                                }
                                                                    }
                                                                }
                                                                } />
                                                            </span>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        :
                                        <div className={Settings.isUseNumbers ? "single-location-marker-with-just-number" : "single-location-marker"} data-google-location-marker-multiservices="">
                                            <div style={{ transform: 'rotate(45deg)' }}>
                                                <span className={`${Settings.isUseNumbers ?  "" : "d-block" } d-flex justify-content-center align-items-center`} 
                                                    onClick={() => {
                                                        GoogleMapSetTooltipBox();
                                                        let multiServiceMarker: any[] = [];
                                                        locations.map((i) => {
                                                            i.filter((ele: any) => {
                                                                if (ele.pickUpPointId === pickUpPointId) {
                                                                    multiServiceMarker = i;
                                                                }
                                                                return null;
                                                            })
                                                            return null;
                                                        });
                                                        handleMainBox(multiServiceMarker);
                                                        var element = document.getElementById(multiServiceMarker[0].pickUpPointId);
                                                        element?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                    }}
                                                >
                                                    {
                                                        Settings.isUseNumbers ?
                                                            <>
                                                                <span className="d-flex justify-content-center align-items-center text-white">
                                                                    <div className="text-center">
                                                                        <p className="text-center location-counter-map">{count ? count : mapCounts}</p>
                                                                    </div>
                                                                </span>
                                                            </>
                                                            :
                                                            <>
                                                            {
                                                                            Settings.isSelectIcon == null ?
                                                                            <img src={carrierService && carrierService.logoName.length > 0 ? config.SeriveImageUrl + carrierService.logoName : '/media/avatars/default_map.png'} alt="Error" className={`map-thumbnail ${index}`} />:
                                                                            Settings.isSelectIcon == true ?
                                                                            <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="Error" className={`map-thumbnail ${index}`} />:
                                                                            <img src={specificLocation.pickupPointType && specificLocation.pickupPointType.logoName.length > 0 ? config.ImageUrl + specificLocation.pickupPointType.logoName : '/media/avatars/default_map.png'} alt="Error" className={`map-thumbnail ${index}`} />
                                                            }
                                                            </>
                                                    }

                                                </span>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </AntdTooltip>
            }
        </>
    )
};