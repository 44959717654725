import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import PichUpPointTypeWrapper from '../pages/Pick-Up-Point-Type/PickUpPintTypePageWrapper'
import { ShopifyPage } from '../pages/Shopify/ShopifyPage'
import { MapsNew } from '../pages/layout-builder/MapsNew'
import { MapConfiguration } from '../../_metronic/partials/layout/header-menus/MapConfiguration'
import ServicePointListPageWrapper from '../pages/Service-Point-List/ServicePointListPageWrapper'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={  <MasterLayout />  }>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/' />} />
        {/* Pages */}
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='PickUpPointType' element={<PichUpPointTypeWrapper />} />
        <Route path='*' element={<ShopifyPage />} />
        <Route path='mapconfiguration' element={<MapConfiguration />} />
        <Route path='map' element={<MapsNew />} />
        <Route path='serviceList' element={<ServicePointListPageWrapper />} />
        <Route path='usermap' element={<MapsNew />} />
        <Route path='thankyou' element={<MapsNew  />} />
      </Route>
    </Routes>
  )
}
export { PrivateRoutes }
