import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { PickupPointType } from "../../Models/MapModels";
import "../../../_metronic/assets/css/custom.css"
import { config } from "../../../Config/Appsettings";
import { GetLable } from "../../../Config/GetLabels";
import { CropperModal } from "../../common/Utils/CropperModal";

declare var $: any;
const PickUpPointTypePage = () => {
    const [pickUpPoint, setPickUpPoint] = useState<PickupPointType[]>([]);
    const [showPopup,setShowPopup] = useState<boolean>(false);
    const [fileObj,setFileObj] = useState<any>({fileName:"", pickupPointTypeId:null, oldLogo:""});
    useEffect(() => {
        axios({
            method: 'GET',
            url: `${config.BaseUrl}PickUpPoint/GetPickUpPointTypeList`,
            headers: { Authorization: config.PickUpPointTypeAuth }
        }).then((response: AxiosResponse<PickupPointType[]>) => {
            setPickUpPoint(response.data);
        }).catch(function (error) {
            console.log(error);
        });
    }, [])

    const UpdateImage = (PickupPointTypeId: any, file: any, oldLogo: "") => {
        var bodyFormData = new FormData();
        bodyFormData.append('files', file);
    axios({
            method: 'POST',  
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
            url: `${config.BaseUrl}PickUpPoint/UploadImage/false/false/${PickupPointTypeId}/${oldLogo}`
        }).then((response: AxiosResponse<any>) => {
            let data = [...pickUpPoint];
            var Index = data.findIndex(x => x.pickupPointTypeId == PickupPointTypeId);
            data[Index].logoName = response.data;
            setPickUpPoint(data);
        }).catch(function (error) {
            console.log(error);
        });
    };
    
    const saveCropedImage = () =>{
        $('#upload-demo').croppie('result', {
            type: 'canvas',
            enableOrientation: true,
            format: 'png',
            backgroundColor : "#ffffff",
            size: {width: 160, height: 160}
        }).then(function (resp  :any) {
            var arr = resp.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[arr.length - 1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            var file = new File([u8arr],  fileObj.fileName, {type:mime || 'text/plain'});
               UpdateImage(fileObj.pickupPointTypeId,file, fileObj.oldLogo);
            setShowPopup(false);
        });
    }
    const createCroppedImage = (imageFile : any) =>{
        setShowPopup(true);
        
        let reader: any = new FileReader();
        let rawImg: string = "";
        reader.onload = function (ele: any) {
            rawImg = ele.target.result
        }
        reader.readAsDataURL(imageFile[0]);
        setTimeout(()=>{
            $('#upload-demo').croppie({
                viewport: {
                    width: 160,
                    height: 160,
                    type: 'circle'
                },
                enforceBoundary: false,
                enableExif: true
            });
            $('#upload-demo').croppie('bind', {
                url: rawImg
            }).then(function(){
                console.log('jQuery bind complete');
            });
        },500);
    };
    return (
        <>
            <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
                <div className="post d-flex flex-column-fluid" id="kt_post">
                    <div className="card col-12">
                        <div className="card-body py-4">
                            <div className="table-responsive">
                                <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" role={"table"}>
                                    <thead>
                                        <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                            <th className="min-w-125px" style={{ cursor: "pointer" }}><GetLable label="Image" /></th>
                                            <th className="min-w-125px" style={{ cursor: "pointer" }}><GetLable label="Name" /></th>
                                            <th className="text-end min-w-100px" style={{ cursor: "pointer" }}><GetLable label="Actions" /></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            pickUpPoint.map((item, index) => {
                                                return (
                                                    <tr role={"row"} key={index}>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                                    <a><div className="symbol-label"><img src={`${config.ImageUrl}${item.logoName}`} alt={item.logoName} className="w-100"></img></div></a>
                                                                </div>
                                                                <div className="d-flex align-items-center" role={"cell"}>{item.logoName}</div>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <div className="badge badge-light-success fw-bolder" role={"cell"}>{item.name}</div>
                                                        </td>
                                                        <td>
                                                            <div className="text-end min-w-100px">
                                                                <label htmlFor={index.toString()} className="custom-file-upload">
                                                                    <i className="fa fa-cloud-upload"></i><GetLable label="UpdateLogo" />
                                                                </label>
                                                                <input id={index.toString()} type="file" accept="image/png, image/gif, image/jpeg" 
                                                                onChange={(e) =>{
                                                                        // UpdateImage(item.pickupPointTypeId, e.target.files);
                                                                        setFileObj({fileName : e.target.files?.[0].name,pickupPointTypeId :item.pickupPointTypeId, oldLogo: item.logoName})
                                                                        createCroppedImage(e.target.files);
                                                                        setShowPopup(true);
                                                                    }} />
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CropperModal 
            saveCropedImage={saveCropedImage}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
            />
        </>
    )
}
export { PickUpPointTypePage }