/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { config } from '../../../../Config/Appsettings'
import { AsideMenuItem } from './AsideMenuItem'

export function AsideMenuMain() {
  const intl = useIntl()

  return (
    <>
      <AsideMenuItem
        to='/'
        icon='/media/icons/duotune/general/gen019.svg'
        title={config.GetLables(intl, "Products")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/PickUpPointType'
        icon='/media/icons/duotune/general/gen019.svg'
        title={config.GetLables(intl, "RelayPoint")}
        fontIcon='bi-layers'
      />
      <AsideMenuItem
        to='/serviceList'
        icon='/media/icons/duotune/general/gen019.svg'
        title={config.GetLables(intl, "ServicePoint")}
        fontIcon='bi-layers'
      />
    </>
  )
}
