import React, { FC } from "react";
import {PageTitle} from '../../../_metronic/layout/core'
import {ServicePointListPage} from './ServicePointListPage'

const ServicePointListPageWrapper : FC = () => (
    <>
        <PageTitle breadcrumbs={[]}>Relay Point Lists</PageTitle>
        <ServicePointListPage />
    </>
)

export default ServicePointListPageWrapper