import {Button, Modal} from "react-bootstrap";
import { ICropperModal } from "../../Interfaces/MapInterfaces";

export const CropperModal = ({saveCropedImage,setShowPopup,showPopup} : ICropperModal) => {
    return (
        <>    <Modal
            show={showPopup}
            size="lg"
            onHide={() => { setShowPopup(false); }}
            id="cropImagePop"
            aria-labelledby="myModalLabel"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="myModalLabel">
                    Update Icon
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body p-0">
                            <div className="modal-header-bg"></div>
                            <div className="up-photo-title">
                                 <h3 className="modal-title text-success">For a polished look, make sure to adjust the icon within the circle</h3>
                            </div>
                            <div className="up-photo-content pb-5">
                                <div id="upload-demo" className="center-block">
                                    <h5>
                                        <i className="fas fa-arrows-alt mr-1"></i> Drag your photo as you
                                        require
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="btn btn-sm btn-primary" onClick={() => { saveCropedImage(); }}>Update</Button>
                <Button className="btn btn-sm btn-danger" onClick={() => { setShowPopup(false); }}>Close</Button>
            </Modal.Footer>
        </Modal>
        </>
    )
};