import { toAbsoluteUrl } from "../_metronic/helpers";
import { GetLable } from "./GetLabels";


interface Iconfig {
  MapKey: string;
  BaseUrl: string;
  PickUpPointTypeAuth: string;
  GoogleMapKey: string;
  ShopifyAccesToken: string;
  ShopifyUrl: string;
  ImageUrl: string;
  SeriveImageUrl: string;
  CustomHomeImageUrl: string;
  CustomPointerImageUrl: string;
  LocationUrl: string;
  AvtarUrl: string;
  DesignMap: any[];
  RightLeftOrder: any[];
  TopManu: any[];
  BottomManu:any[];
  BottomMapOptionDistanceWithNumber : any[];
  TopMapOptionDistanceWithNumber: any[];
  ShowImages: any[];
  DisplayMap: any[];
  Distance: any[];
  Languages: any[];
  LanguagesSelection: any[];
  DisPlayTopListHours: any[];
  DisPlaySideListHours: any[];
  DisPlayMapListHours: any[];
  UseNumbers : any[];
  ShopTimeSchedule : any
  ShowLogo: any[];
  selectMap: any[];
  SelectIcon: any[];
  GetLables: (intl: any, label: string) => string;
  toTitleCase: (value: string) => string;
  DefaultLocationImage : string;
  DuplicateRelayPoint : any[];
}
export const config: Iconfig = {
  MapKey: "AjrA_VMdgQRybZoZEgIW8qSgWRwcFoy0qEXiaKSA-u84uQ53nMW8GoXjVQm17j-2",
  BaseUrl: "https://api.crossdeskmap.com/",// live url
  // BaseUrl: "https://localhost:7217/", // local url
  PickUpPointTypeAuth: '456f54b91c73f12ed21b4db4af222c3c',
  GoogleMapKey: 'AIzaSyA54LGF_f6bz3I99GUpbJDATjwKFNKG_D4',
  ShopifyAccesToken: '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',
  ShopifyUrl: 'https://crossdeskmap.myshopify.com/',
  ImageUrl: 'https://api.crossdeskmap.com/wwwroot/LocationIcons/',
  SeriveImageUrl: 'https://api.crossdeskmap.com/wwwroot/ServiceIcons/',
  CustomHomeImageUrl: 'https://api.crossdeskmap.com/wwwroot/CustomHomeIcons/',
  CustomPointerImageUrl: 'https://api.crossdeskmap.com/wwwroot/CustomPoniterIcons/',
  LocationUrl: 'https://dev.virtualearth.net/',
  AvtarUrl: '/media/avatars/Home.png',
  DefaultLocationImage : "/media/avatars/location-image.png",
  DesignMap: [
    { value: "primary-theme", label: <GetLable label="Design1" /> },
    { value: "orange-theme", label: <GetLable label="Design2" /> },
    { value: "pink-theme", label: <GetLable label="Design3" /> }
  ],
  RightLeftOrder: [
    { value: false, label: <GetLable label="Left" /> },
    { value: true, label: <GetLable label="Right" /> },
    { value: null, label: <GetLable label="No" /> }
  ],
  TopManu: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> }
  ],
  BottomManu: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> }
  ],
  SelectIcon: [
    { value: true, label: <GetLable label="UserIcon" /> },
    { value: null, label: <GetLable label="ServiceIcon" /> },
    { value: false, label: <GetLable label="RelayPointIcon" /> }

  ],
  BottomMapOptionDistanceWithNumber : [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> }
  ],
  TopMapOptionDistanceWithNumber : [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> }
  ],
  ShowLogo: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> }
  ],
  ShowImages: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> }
  ],
  DisplayMap: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> },
  ],
  Distance: [
    { value: true, label: <GetLable label="Default" /> },
    { value: false, label: <GetLable label="Miles" /> },
  ],

  LanguagesSelection: [
    { value: false, label: <GetLable label="DefaultLanguage" /> },
    { value: true, label: <GetLable label="ManualLanguage" /> },
  ],
  DisPlayTopListHours: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> },
  ],
  DisPlaySideListHours: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> },
  ],
  DisPlayMapListHours: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> },
  ],
  UseNumbers: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> },
  ],
  ShopTimeSchedule : {
    Monday : "Monday,mondayOpenHourAM,mondayCloseHourAM,mondayOpenHourPM,mondayCloseHourPM",
    Tuesday : "Tuesday,tuesdayOpenHourAM,tuesdayCloseHourAM,tuesdayOpenHourPM,tuesdayCloseHourPM",
    Wednesday : "Wednesday,wednesdayOpenHourAM,wednesdayCloseHourAM,wednesdayOpenHourPM,wednesdayCloseHourPM",
    Thursday : "Thursday,thursdayOpenHourAM,thursdayCloseHourAM,thursdayOpenHourPM,thursdayCloseHourPM",
    Friday : "Friday,fridayOpenHourAM,fridayCloseHourAM,fridayOpenHourPM,fridayCloseHourPM",
    Saturday : "Saturday,saturdayOpenHourAM,saturdayCloseHourAM,saturdayOpenHourPM,saturdayCloseHourPM",
    Sunday : "Sunday,sundayOpenHourAM,sundayCloseHourAM,sundayOpenHourPM,sundayCloseHourPM"
  },
  selectMap: [
    { value: true, label: <GetLable label="OpenStreetMap" />, propertyName: "isOpenStreetMap" },
    { value: true, label: <GetLable label="GoogleMap" />, propertyName: "isGoogleMap" },
    { value: true, label: <GetLable label="BingMap" />, propertyName: "isBingMap" }
  ],
  DuplicateRelayPoint: [
    { value: true, label: <GetLable label="Yes" /> },
    { value: false, label: <GetLable label="No" /> },
  ],

  Languages: [
    {
      lang: 'en',
      name: 'English',
      flag: toAbsoluteUrl('/media/flags/united-states.svg'),
    },
    {
      lang: 'de',
      name: 'German',
      flag: toAbsoluteUrl('/media/flags/germany.svg'),
    },
    {
      lang: 'fr',
      name: 'French',
      flag: toAbsoluteUrl('/media/flags/france.svg'),
    },
    {
      lang: 'es',
      name: 'Spanish',
      flag: toAbsoluteUrl('/media/flags/spain.svg'),
    },
    {
      lang: 'it',
      name: 'Italy',
      flag: toAbsoluteUrl('/media/flags/italy.svg'),
    },
    {
      lang: 'el',
      name: 'Greek',
      flag: toAbsoluteUrl('/media/flags/greece.svg'),
    },
    {
      lang: 'pt',
      name: 'Portuguese',
      flag: toAbsoluteUrl('/media/flags/portugal.svg'),
    },
    {
      lang: 'bg',
      name: 'Bulgarian',
      flag: toAbsoluteUrl('/media/flags/bulgaria.svg'),
    },
    {
      lang: 'sv',
      name: 'Swedish',
      flag: toAbsoluteUrl('/media/flags/sweden.svg'),
    },
    {
      lang: 'ro',
      name: 'Romanian',
      flag: toAbsoluteUrl('/media/flags/romania.svg'),
    },
    {
      lang: 'hr',
      name: 'Croatian',
      flag: toAbsoluteUrl('/media/flags/croatia.svg'),
    },
    {
      lang: 'lt',
      name: 'Lithuanian',
      flag: toAbsoluteUrl('/media/flags/lithuania.svg'),
    },
    {
      lang: 'cs',
      name: 'Czech',
      flag: toAbsoluteUrl('/media/flags/czech-republic.svg'),
    },
    {
      lang: 'hu',
      name: 'Hungarian',
      flag: toAbsoluteUrl('/media/flags/hungary.svg'),
    },
    {
      lang: 'sk',
      name: 'Slovak',
      flag: toAbsoluteUrl('/media/flags/slovakia.svg'),
    },
    {
      lang: 'sl',
      name: 'Slovenian',
      flag: toAbsoluteUrl('/media/flags/slovenia.svg'),
    },
    {
      lang: 'pl',
      name: 'Polish',
      flag: toAbsoluteUrl('/media/flags/poland.svg'),
    },
    {
      lang: 'nl',
      name: 'Dutch',
      flag: toAbsoluteUrl('/media/flags/netherlands.svg'),
    },
    {
      lang: 'da',
      name: 'Danish',
      flag: toAbsoluteUrl('/media/flags/denmark.svg'),
    },
    {
      lang: 'fi',
      name: 'Finnish',
      flag: toAbsoluteUrl('/media/flags/finland.svg'),
    },
  ],
  GetLables: (intl: any, label: string) => {
    let message_title = ""
    let message = intl.formatMessage(
      { id: label }
    );
    return message_title + " " + message;
  },
  toTitleCase: (value: string) => {
    return value.toLowerCase().split(' ').map((item: any) => { return item.charAt(0).toUpperCase() + item.slice(1); }).join(' ');
  }
}