/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC } from 'react'
import { useLang, setLanguage } from '../../../i18n/Metronici18n'
import { config } from "../../../../Config/Appsettings";
import { GetLable } from '../../../../Config/GetLabels';
import { shallowEqual, useSelector } from 'react-redux';

const Languages: FC = () => {
  const lang = useLang();
  const currentLanguage = config.Languages.find((x) => x.lang === lang);
  const language = [...config.Languages].sort((a, b) => a.name > b.name ? 1 : -1,);
  let updateTheme: any = useSelector((state: any) => state?.product?.updateTheme, shallowEqual);
  return (
    <>
      <div
        className='menu-item px-5'
        data-kt-menu-trigger='hover'
        data-kt-menu-placement='left-start'
        data-kt-menu-flip='bottom'
      >
        <a href='#' className='menu-link px-5'>
          <span className='menu-title position-relative'>
            <GetLable label="Language" />
            <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
              {currentLanguage?.name}{' '}
              <img
                className='w-15px h-15px rounded-1 ms-2'
                src={currentLanguage?.flag}
                alt='metronic' />
            </span>
          </span>
        </a>

        <div className='menu-sub menu-sub-dropdown w-175px py-4 language-scroll' >
          {language.map((l) => (
            <div
              className='menu-item px-3'
              key={l.lang}
              onClick={() => {
           
                let jsonInString: any = localStorage.getItem("mapConfiguration");
                if (jsonInString) {
                  let data: any = JSON.parse(jsonInString);
                  data.desktopConfig.userLanguage = l.lang;
                  data.mobileConfig.userLanguage = l.lang;
                  localStorage.setItem("mapConfiguration",JSON.stringify(data));
                } else {
                  updateTheme.desktopConfig.userLanguage = l.lang;
                  updateTheme.mobileConfig.userLanguage = l.lang;
                  localStorage.setItem("mapConfiguration",JSON.stringify(updateTheme));
                }
                setLanguage(l.lang);
              }}
            >
              <a
                href='#'
                className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
              >
                <span className='symbol symbol-20px me-4'>
                  <img className='rounded-1' src={l.flag} alt='metronic' />
                </span>
                {l.name}
              </a>
            </div>
          ))}
        </div>
      </div>

    </>
  )
}
export { Languages }