import axios, { AxiosResponse } from "axios";
import React, { useEffect, useState } from "react";
import { CarrierService } from "../../Models/MapModels";
import { config } from "../../../Config/Appsettings";
import { GetLable } from "../../../Config/GetLabels";
import { CropperModal } from "../../common/Utils/CropperModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ProductSlice } from "../../../redux/ProductSlice";
import { cloneDeep } from "lodash";
declare var $: any;
export const ServicePointListPage = () => {
   const [carrierService, setCarrierService] = useState<any[]>([]);
   const [fileObj, setFileObj] = useState<any>({ fileName: "", carrierId: null, oldLogo: "" });
   const [showPopup, setShowPopup] = useState<boolean>(false);
   const [isEnableEditing, setEnableEditing] = useState(null);
   const [serviceName, setServiceName] = useState("");
   const dispatch = useDispatch();
   const ServiceDataFromRedux: any = useSelector((state: any) => state?.product?.servicePointList);
   const { GetServicePointList } = ProductSlice.actions;
   useEffect(() => {
      getServiceList();
   }, [])


   const getServiceList = () => {
      axios({
         method: 'GET',
         url: `${config.BaseUrl}PickUpPoint/GetServices`,
         headers: { Authorization: config.PickUpPointTypeAuth }
      }).then((response: AxiosResponse<any[]>) => {
         setCarrierService(response.data);
         dispatch(GetServicePointList(response.data));
      }).catch(function (error) {
         console.log(error);
      });
   };



   const UpdateImage = (CarrierId: any, file: any, oldLogo: "") => {
      var bodyFormData = new FormData();
      bodyFormData.append('files', file);
      axios({
         method: 'POST',
         data: bodyFormData,
         headers: { "Content-Type": "multipart/form-data" },
         url: `${config.BaseUrl}PickUpPoint/UploadServiceImage/${CarrierId}/${oldLogo}`
      }).then((response: AxiosResponse<any>) => {
         let data = [...carrierService];
         data.forEach(relay => {
            relay.forEach((service: any) => {
               if (service.carrierId === CarrierId) { service.logoName = response.data; }
            });
         });
         setCarrierService(data);
      }).catch(function (error) {
         console.log(error);
      });
   }

   const DeleteLogoImage = (imageName: string | undefined, ImageId: string | null = null) => {
      axios({
         method: 'POST',
         headers: { "Content-Type": "multipart/form-data" },
         url: `${config.BaseUrl}PickUpPoint/RemoveServiceImage/${imageName}/${ImageId}`
      }).then((response: AxiosResponse<any>) => {
         let data = [...carrierService];
         data.forEach(relay => {
            relay.forEach((service: any) => {
               if (service.carrierId === ImageId) { service.logoName = ""; }
            });
         });
         setCarrierService(data);
      }).catch(function (error) {
         console.log(error);
      });
   };

   const saveCropedImage = () => {
      $('#upload-demo').croppie('result', {
         type: 'canvas',
         enableOrientation: true,
         format: 'png',
         backgroundColor: "#ffffff",
         size: { width: 160, height: 160 }
      }).then(function (resp: any) {
         var arr = resp.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
         while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
         }
         var file = new File([u8arr], fileObj.fileName, { type: mime || 'text/plain' });
         UpdateImage(fileObj.carrierId, file, fileObj.oldLogo);
         setShowPopup(false);
      });
   }
   const createCroppedImage = (imageFile: any) => {
      setShowPopup(true);

      let reader: any = new FileReader();
      let rawImg: string = "";
      reader.onload = function (ele: any) {
         rawImg = ele.target.result
      }
      reader.readAsDataURL(imageFile[0]);
      setTimeout(() => {
         $('#upload-demo').croppie({
            viewport: {
               width: 160,
               height: 160,
               type: 'circle'
            },
            enforceBoundary: false,
            enableExif: true
         });
         $('#upload-demo').croppie('bind', {
            url: rawImg
         }).then(function () {
            console.log('jQuery bind complete');
         });
      }, 500);
   };

   const saveServiceName = (serviceName: string, carrierId  :string) => {
      setEnableEditing(null);
      setServiceName("");
      axios({
         method: 'PUT',
         headers: { "Content-Type": "multipart/form-data" },
         url: `${config.BaseUrl}PickUpPoint/UpdateServiceName/${carrierId}/${serviceName}`
      }).then((response: AxiosResponse<any>) => {

         let data = cloneDeep(ServiceDataFromRedux);     
      data.map((item:any[])=>{
             item.map((nestedItem :any)=>{
                    if(nestedItem.carrierId === carrierId){
                     nestedItem.name = serviceName;
                     return;
                    }
             });
      });
      dispatch(GetServicePointList(data));
      setCarrierService(data);
      }).catch(function (error) {
         console.log(error);
      });
   };

   const cancelEditServiceName = () => {
      setEnableEditing(null);
      setServiceName("");
   };

   const editserviceName = (item: any) => {
      setEnableEditing(item[0].carrierId);
      setServiceName(item[0].name)
   };


   return (
      <>
         <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="post d-flex flex-column-fluid" id="kt_post">
               <div className="card col-12">
                  <div className="card-body py-4">
                     <div className="table-responsive">
                        <table id="kt_table_users" className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" role={"table"}>
                           <thead>
                              <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                                 <th className="min-w-125px" style={{ cursor: "pointer" }}> NAME </th>
                                 <th className="min-w-125px" style={{ cursor: "pointer" }}><GetLable label="Image" /></th>
                                 <th className="min-w-125px" style={{ cursor: "pointer" }}><GetLable label="Services" /></th>
                                 <th className="text-end min-w-100px" style={{ cursor: "pointer" }}><GetLable label="Actions" /></th>
                              </tr>
                           </thead>
                           <tbody>
                              {
                                 carrierService.map((item, index) => {
                                    return (
                                       <tr role={"row"} key={index}>
                                          <td>
                                             <div className="d-flex  align-items-center">
                                                <div>
                                                   {
                                                      isEnableEditing === item[0].carrierId ?
                                                         <input className={"form-control"} value={serviceName} onChange={(e) => {
                                                            let val = e.target.value;
                                                            setServiceName(val);
                                                         }} />
                                                         : <div> {item[0].name} </div>
                                                   }
                                                </div>
                                                <div>
                                                   {
                                                      isEnableEditing === item[0].carrierId ?
                                                         <div className="d-flex justify-content-between">
                                                            <button className="btn custom-service-edit-btn" onClick={() => { cancelEditServiceName(); }}>
                                                               <i className="fa-solid fa-xmark fa-lg text-danger"></i>
                                                            </button>

                                                            <button className="btn custom-service-edit-btn" onClick={() => { saveServiceName(serviceName, item[0].carrierId); }}>
                                                               <i className="fa-solid fa-check fa-lg text-success"></i>
                                                            </button>
                                                         </div>
                                                         :
                                                         <button className="btn custom-service-edit-btn" onClick={() => { editserviceName(item); }}>
                                                            <i className="fa-regular fa-pen-to-square fa-lg text-primary"></i>
                                                         </button>
                                                   }
                                                </div>
                                             </div>
                                          </td>
                                          <td>
                                             <div className="d-flex align-items-center">
                                                <div className="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                   <a><div className="symbol-label"><img src={item[0].logoName === "" ? '/media/avatars/default_map.png' : `${config.SeriveImageUrl}${item[0].logoName}`} alt={item[0].logoName} className="w-100"></img></div></a>
                                                </div>
                                                {/* <div className="d-flex align-items-center" role={"cell"}>{item[0].label}</div> */}
                                             </div>
                                          </td>
                                          <td>
                                             {item.map((element: any, index: number) => {
                                                return (
                                                   <div key={index}>
                                                      <div className="badge badge-light-success fw-bolder" role={"cell"}>{element.label}</div><br /></div>)
                                             })}

                                          </td>
                                          <td>
                                             <div className="d-flex justify-content-end">
                                                <div className="text-end min-w-100px">
                                                   <label htmlFor={index.toString()} className="custom-file-upload">
                                                      <i className="fa fa-cloud-upload"></i><GetLable label="UpdateLogo" />
                                                   </label>
                                                   <input id={index.toString()} type="file" accept="image/png, image/gif, image/jpeg"
                                                      onChange={(e) => {
                                                         // UpdateImage(item[0].carrierId, e.target.files);
                                                         setFileObj({ fileName: e.target.files?.[0].name, carrierId: item[0].carrierId, oldLogo: item.logoName })
                                                         createCroppedImage(e.target.files);
                                                         setShowPopup(true);
                                                      }} />
                                                </div>

                                                <div className="text-end min-w-100px btn-danger custom-margin-left" onClick={() => { DeleteLogoImage(item[0].logoName, item[0].carrierId); }}>
                                                   <label className="custom-file-upload custom-remove-logo-button text-white">
                                                      <GetLable label="RemoveLogo" />
                                                   </label>
                                                </div>
                                             </div>
                                          </td>
                                       </tr>
                                    )
                                 })
                              }
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <CropperModal
            saveCropedImage={saveCropedImage}
            setShowPopup={setShowPopup}
            showPopup={showPopup}
         />
      </>
   )
}