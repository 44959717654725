export const GoogleMapSetTooltipBox = () =>{
    setTimeout(()=>{
        const googleMapContainer  : any  = document.getElementById("googleMapContainer"); 
        const googleMapTooltipBox  : any  = document.getElementById("googleMapTooltipBox"); 

        if(googleMapTooltipBox && googleMapContainer){
            const parentRect = googleMapContainer.getBoundingClientRect();
            const childRect = googleMapTooltipBox.getBoundingClientRect();
          
            const overflowTop = Math.max(parentRect.top - childRect.top, 0);
            const overflowBottom = Math.max(childRect.bottom - parentRect.bottom, 0);
            if (childRect.top < parentRect.top) {
              googleMapTooltipBox.style.top =   (overflowTop + 10) + "px";
            // We dont need  top property, just bottom property will handle it but still we put here code for security because some popup will open at top.
            } else if (childRect.bottom > parentRect.bottom) {
              googleMapTooltipBox.style.top =  "-" + (overflowBottom + 8) + "px";
            }
        }
    },500);
};


export const BingMapSetTooltipBox = () =>{ 
    const bingMapContainer = document.getElementById("bingMapContainer");
    const tooltipBox = document.getElementById("tooltip-box");
  
    if(bingMapContainer && tooltipBox){
      const parentRect = bingMapContainer.getBoundingClientRect();
      const childRect = tooltipBox.getBoundingClientRect();
    
      // when you want to see hou much space is top of popup that time you can use this overflowTop variable.
      const overflowTop = Math.max(parentRect.top - childRect.top, 0);
      const overflowBottom = Math.max(childRect.bottom - parentRect.bottom, 0);
    
    
      if (childRect.top < parentRect.top) {
        // We dont need  top property, just bottom property will handle it.
      } else if (childRect.bottom > parentRect.bottom) {
        tooltipBox.style.top =  "-" + (overflowBottom + 8) + "px";
      }
    }
}