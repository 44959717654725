import axios, { AxiosResponse } from "axios";
import { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ProductSlice } from "../../../redux/ProductSlice";
import { Product, ShopifyModel } from "../../Interfaces/ShopifyInterface";
import { config } from "../../../Config/Appsettings";
import { Link } from "react-router-dom";
import { GetLable } from "../../../Config/GetLabels";

const { actions } = ProductSlice;

const ShopifyPage = () => {

    const [shopifyList, setShopifyList] = useState<ShopifyModel>();

    const addedProducts: number = useSelector((state: any) => state?.product?.products?.length ?? 0, shallowEqual);
    const dispatch = useDispatch();
    useEffect(() => {
        if (!new URL(window.location.href).searchParams.get('embedded') && new URL(window.location.href).searchParams.get('shop')) {
            axios({
                method: 'GET',
                url: `${config.BaseUrl}PickUpPoint/ShopifyAuth?shop=${new URL(window.location.href).searchParams.get('shop')}`
            }).then((response: AxiosResponse<any>) => {
                window.location.href = response.data
            });

        }
    }, []);

    // useEffect(() => {
    //     axios({
    //         method: 'GET',
    //         url: `${config.BaseUrl}PickUpPoint/GetShopifyProducts`,
    //     }).then((response: AxiosResponse<any>) => {
    //         setShopifyList(JSON.parse(response.data));
    //     });
    // }, []);

    const onSubmit = (data: Product) => {
        dispatch(actions.GetProducts({ data }));
    }

    return (
        <>
            <div className="post d-flex flex-column-fluid" id="kt_post">
                <div className="container" id="kt_content_container">
                    <div className="d-flex flex-wrap flex-stack mb-6">
                        <h3 className="fw-bolder my-2"><GetLable label="ProductList" /></h3>
                        <Link to='/builder'>
                            <button className="btn btn-primary align-self-center">
                                <GetLable label="GoToCart" /> ({addedProducts})</button>
                        </Link>
                    </div>
                    <div className="row g-6 g-xl-9">
                        {
                            shopifyList?.products.map((item, index) => {
                                return (
                                    <div className="col-md-6 col-xl-4" key={index}>
                                        <a className="card border border-2 border-gray-300 border-hover">
                                            <div className="card-header border-0 pt-9">
                                                <div className="card-title m-0">
                                                    <div className="symbol symbol-50px w-50px bg-light">
                                                        {
                                                            item.images.map((item, index) => {
                                                                return (
                                                                    <img src={item.src} className="p-3" key={index}>
                                                                    </img>
                                                                )
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="card-toolbar">
                                                    <span className="badge badge-light-primary fw-bolder me-auto px-4 py-3">In Stock</span>
                                                </div>
                                            </div>
                                            <div className="card-body p-9">
                                                <div className="fs-3 fw-bolder text-dark">
                                                    {item.title}
                                                </div>
                                                <p className="text-gray-400 fw-bold fs-5 mt-1 mb-7">
                                                    {item.body_html}
                                                </p>
                                                {
                                                    item.variants.map((items, index) => {
                                                        return (
                                                            <div className="text-gray-400 fw-bold fs-5 mt-1 mb-7" key={index}>
                                                                ${items.price}
                                                            </div>

                                                        )
                                                    })
                                                }
                                                <div className="card-toolbar">
                                                    <button onClick={() => { onSubmit(item); }} className="btn btn-primary align-self-center">Add to cart</button>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export { ShopifyPage }