import React, { FC } from "react";
import { useIntl } from "react-intl";


export const GetLable  : FC<any> = ({label} : any) => {
    const intl = useIntl();
 const handleLables = () =>{
    let message = intl.formatMessage({ id: label });
    return message
 };
    return (
       <>
       {handleLables()}
       </> 
    );
};