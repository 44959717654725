import { combineReducers } from "redux";
import { Product } from "../app/Interfaces/ShopifyInterface";
import { ProductSlice } from "../redux/ProductSlice";

export const rootReducer = combineReducers({
    product: ProductSlice.reducer
});
export interface IRootState {
    products: Product[];
    isRelayPoints: boolean;
    updateTheme : any;
    updateLang : any;
    localization : any[];
    servicePointList :any[][]
}