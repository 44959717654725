import React, {useEffect} from 'react'
import {getDistance} from 'geolib'
import {config} from '../../../Config/Appsettings'
import { BingMapSetTooltipBox } from '../../common/Utils/DynamicPopupAlignment'
import { useLocation } from 'react-router-dom';

export function BingMap({
  pushPins = [],
  viewOptions,
  handleMainBox,
  locations,
  handleCheckBox,
  iconChanged,
  isChecked,
  distanceType,
  specificActive,
  settings,
  intl
}) {
  /*global Microsoft, */
  /*eslint no-undef: "error"*/
  const microsoft = Microsoft
  useEffect(() => {
    setTimeout(() => {
      onLoad();
    }, 500)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewOptions, pushPins]);

  useEffect(()=>{
    setTimeout(()=>{
      BingMapSetTooltipBox();
    },2000);
  },[]);

  let infobox
  let pin
  const location = useLocation();
  let selectedServices;
  locations.map((i) => {
    i.filter((ele) => {
      if(location.pathname.includes("thankyou")){
        if (ele.pickUpPointId === pushPins[0].pickUpPointId) {
          selectedServices = i
        }
      }else{
        if (ele.pickUpPointId === pushPins[1].pickUpPointId) {
          selectedServices = i
        }
      }
    })
  })

    const tooltipTemplateForHome  = `<div style="background-color: rgba(0, 0, 0, 0.85); color: #fff;min-width: 32px;min-height: 32px; padding: 6px 8px; text-decoration: none; text-align: center; word-wrap: break-word; border-radius: 6px; box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%); margin-top: -5px; position: relative;  right: 20px; ">
    <b>{title}</b>
    </div>`

//  const getTooltipBox = () =>{
//   const bingMapContainer = document.getElementById("bingMapContainer");
//   const tooltipBox = document.getElementById("tooltip-box");

//   if(bingMapContainer && tooltipBox){
//     const parentRect = bingMapContainer.getBoundingClientRect();
//     const childRect = tooltipBox.getBoundingClientRect();
  
//     // when you want to see hou much space is top of popup that time you can use this overflowTop variable.
//     const overflowTop = Math.max(parentRect.top - childRect.top, 0);
//     const overflowBottom = Math.max(childRect.bottom - parentRect.bottom, 0);
  
  
//     if (childRect.top < parentRect.top) {
//       // We dont need  top property, just bottom property will handle it.
//     } else if (childRect.bottom > parentRect.bottom) {
//       tooltipBox.style.top =  "-" + (overflowBottom + 8) + "px";
//     }
//   }
//  };

  const onLoad = () => {
    const map = new microsoft.Maps.Map('#myMap', {})
    infobox = new microsoft.Maps.Infobox()
    let randomLocation = microsoft.Maps.TestDataGenerator.getLocations(
      pushPins.length,
      map.getBounds()
    )
// Here we will remove the one element of randomLocation array because  we want to show one location in bing map and both elements are same that's why.
  //  randomLocation.shift()
    Array.from(randomLocation).map((RI, index) => {
      if (RI) {
        RI.latitude = parseFloat(pushPins[index].center.latitude)
        RI.longitude = parseFloat(pushPins[index].center.longitude)
        RI.anchor = new microsoft.Maps.Point(12, 12)
        const pushpinLocation = new microsoft.Maps.Location(RI.latitude, RI.longitude)
        pin = new microsoft.Maps.Pushpin(pushpinLocation, RI)
        pin.metadata = {
          title: pushPins[index].options.title,
        }
        let multiServiceMarker = []
        locations.map((i) => {
          i.filter((ele) => {
            if (
              pushPins[index].options.title != 'Home' &&
              ele.pickUpPointId === pushPins[index].pickUpPointId
            ) {
              multiServiceMarker = i
            }
          })
        })

let  markerLength =``;
 if(!settings.isDivideMapsIntotwoParts){
  if(multiServiceMarker.length > 3 ){
    markerLength = ``;
  }
  else if(multiServiceMarker.length > 1 && pushPins.length == 2 && pushPins[index].options.title !== "Home"){ 
      markerLength = `<div class="card border mb-2"
       style="color: red; font-size: 40px;position:absolute !important; top :0px; left :24px; width: 300px;opacity :1 !important" role="button" id="tooltip-box"> 
      <div  class="card-body p-4">
          <button class='btn btn-sm btn-icon me-n5 close-button close-tooltip-icon' id="close-button"><i class="fa fa-close text-black" id="close-button"></i></button>
          <div class="d-flex justify-content-between">
              <h4 class="text-gray-800 fw-bold mb-1 fs-6 text-ellipsis">${
                multiServiceMarker[0].name
              }</h4>
              <div class="symbol symbol-circle symbol-30px ll">
          <a>
          ${
            settings.isLogoShow === true ?  
            `
            <div class="symbol-label">
            ${
              settings.isSelectIcon == "" ?
              `<img src="${multiServiceMarker[index].carrierService && multiServiceMarker[index].carrierService.logoName ? `${config.SeriveImageUrl}${multiServiceMarker[index].carrierService.logoName}` : "/media/avatars/default_map.png"}" class="rounded-circle w-100">`:
              settings.isSelectIcon == true ? 
              `<img src="${settings.MapPointerIcon && settings.MapPointerIcon !== "default_map.png" ? settings.MapPointerIcon.includes("http") ? `${settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${settings.MapPointerIcon}` : `/media/avatars/default_map.png`}" class="rounded-circle w-100">`:
              `<img src="${multiServiceMarker[index].pickupPointType && multiServiceMarker[index].pickupPointType.logoName ? `${config.ImageUrl}${multiServiceMarker[index].pickupPointType.logoName}` : "/media/avatars/default_map.png"}" class="rounded-circle w-100">`
            }
            </div>
            `
            : ``
          } 
          </a>
          </div>
          </div>
          ${
            location.pathname.includes("thankyou") ?  `` :
            `
            <span class="fw-semibold text-gray-800 mb-1 fs-6  text-end"><i class=${
              multiServiceMarker[0].distanceByLatLog <= 1000
                ? `"fa-solid fa-person-walking fa-lg text-gray-800 me-2 me-2"`
                : `"fa fa-car fa-lg text-gray-800 me-2 me-2"`
            }></i>${distanceType}</span>
            `
          }
          <span class="fw-semibold text-primary fs-6 mb-2  text-start text-ellipsis">${
            multiServiceMarker[0].address1
          } ${multiServiceMarker[0].address2} ${multiServiceMarker[0].city} ${multiServiceMarker[0].postalCode}</span>
          ${(function locationCheck() {
            let divString = ''
            for (let j = 0; j < multiServiceMarker.length; j++) {
              divString +=
                isChecked ===
                multiServiceMarker[j].pickUpPointId +
                  multiServiceMarker[j].carrierServiceId
                  ? `<div class="mb-1 pointerClose location-checkbox" key=${j}>
                <div class="form-check pointerClose form-check-form-check form-check-custom form-check-success form-check-sm">
                     <input class="form-check-input h-15px w-15px" type="checkbox" checked id=${j}
                     />
                     <label class="form-check-label text-black" htmlFor={checkbox-${
                       multiServiceMarker[j].pickUpPointId +
                       multiServiceMarker[j].carrierServiceId
                     }}>
                         ${multiServiceMarker[j].carrierService?.label}
                     </label>
                 </div>
             </div>`
                  : `<div class="mb-1 pointerClose location-checkbox" key=${j}>
               <div class="form-check pointerClose form-check-form-check form-check-custom form-check-success form-check-sm">
                    <input class="form-check-input h-15px w-15px" type="checkbox" id=${j}
                    />
                    <label class="form-check-label text-black" htmlFor={checkbox-${
                      multiServiceMarker[j].pickUpPointId +
                      multiServiceMarker[j].carrierServiceId
                    }}>
                        ${multiServiceMarker[j].carrierService?.label}
                    </label>
                </div>   
            </div>`
            }
            return divString
          })()}
          ${
            settings.isMapHours
              ?  multiServiceMarker[0].pickupPointSchedules1 ? `  
              <table class="table table-striped bing-map-time-box-table p-4">
              <tbody>
              ${(function locationCheck() {
                let divString = "";
                for (const property in config.ShopTimeSchedule) {
                  let properties  = config.ShopTimeSchedule[property].split(",");
                    divString +=
                      `<tr>
                      <th scope="row">${config.GetLables(intl,properties[0])}</th>
                      <td>${selectedServices[0].pickupPointSchedules1[properties[1]]} - ${selectedServices[0].pickupPointSchedules1[properties[2]]}</td>
                      <td>${selectedServices[0].pickupPointSchedules1[properties[3]]} - ${selectedServices[0].pickupPointSchedules1[properties[4]]}</td>
                  </tr>`
                }
                return divString;
              })()}
              </tbody>
          </table>`
       : `<div class="card-body p-2 h-100"><div class="h-100  text-center w-100"><div class="w-100"> Schedules are not <br />available  </div></div></div>`
          : ''
      }
   
      </div>
      
      </div>
  </div>`
    }
  else if(pushPins.length == 2 && multiServiceMarker.length === 1) {
      markerLength =    `<div class="card border mb-2 position-absolute"
      style="position:absolute; top : 0px; left :24px; width: 300px;opacity : 1
      role="button" id="tooltip-box"> 
      <div class="card-body p-4">
          <button class='btn btn-sm btn-icon me-n5 close-button close-tooltip-icon' id="close-button"><i class="fa fa-close text-black" id="close-button"></i></button>
          <div class="d-flex justify-content-between">
              <h4 class="text-gray-800 fw-bold mb-1 fs-6 text-ellipsis">${
                selectedServices[0].name
              }</h4>
          <div class="symbol symbol-circle symbol-30px rr">
          <a>
          ${
            settings.isLogoShow === true ?  
            `
            <div class="symbol-label">

            ${
              settings.isSelectIcon == null ?
              `<img src="${multiServiceMarker[0].carrierService && multiServiceMarker[0].carrierService.logoName ? `${config.SeriveImageUrl}${multiServiceMarker[0].carrierService.logoName}` : "/media/avatars/default_map.png"}" class="rounded-circle w-100">`:
              settings.isSelectIcon == true ? 
              `<img src="${settings.MapPointerIcon && settings.MapPointerIcon !== "default_map.png" ? settings.MapPointerIcon.includes("http") ? `${settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${settings.MapPointerIcon}` : `/media/avatars/default_map.png`}" class="rounded-circle w-100">`:
              `<img src="${multiServiceMarker[0].pickupPointType && multiServiceMarker[0].pickupPointType.logoName ? `${config.ImageUrl}${multiServiceMarker[0].pickupPointType.logoName}` : "/media/avatars/default_map.png"}" class="rounded-circle w-100">`
            }


            </div>
            `
            : ``
          } 
          </a>
          </div>
          </div>

          ${
            location.pathname.includes("thankyou") ?  `` :  
            ` <span class="fw-semibold text-gray-800 mb-1 fs-6  text-end"><i class=${
              selectedServices[0].distanceByLatLog <= 1000
                ? `"fa-solid fa-person-walking fa-lg text-gray-800 me-2 me-2"`
                : `"fa fa-car fa-lg text-gray-800 me-2 me-2"`
            }></i>${distanceType}</span>`
          }

          <span class="fw-semibold text-primary fs-6 mb-2  text-start text-ellipsis">${
            selectedServices[0].address1
          } ${selectedServices[0].address2} ${selectedServices[0].city} ${selectedServices[0].postalCode}</span>
          ${
                settings.isMapHours
              ?  selectedServices[0].pickupPointSchedules1 ?     `  
              <table class="table table-striped bing-map-time-box-table">
              <tbody>
              ${(function locationCheck() {
                let divString = "";
                for (const property in config.ShopTimeSchedule) {
                  let properties  = config.ShopTimeSchedule[property].split(",");
                    divString +=
                      `<tr>
                      <th scope="row">${config.GetLables(intl,properties[0])}</th>
                      <td>${selectedServices[0].pickupPointSchedules1[properties[1]]} - ${selectedServices[0].pickupPointSchedules1[properties[2]]}</td>
                      <td>${selectedServices[0].pickupPointSchedules1[properties[3]]} - ${selectedServices[0].pickupPointSchedules1[properties[4]]}</td>
                  </tr>`
                }
                return divString;
              })()} 
              </tbody>
          </table>
          `
       : 
       `<div class="card-body p-2 h-100"><div class="h-100  text-center w-100"><div class="w-100"> Schedules are not <br />available  </div></div></div>`
          : ''
      }
      
      </div>
      
      </div>
  </div>` 
    }
 }
  
        infobox = new microsoft.Maps.Infobox(pin.getLocation(), {
          
          htmlContent: pushPins[index].options.icon.includes('default_map.png')
            ? markerLength +
              `
                   ${
                     (randomLocation.length === 2 || randomLocation !== null)
                       ? `<div class=${settings.isUseNumbers ? "single-location-marker-with-just-number" : "single-location-marker"} id="icon2">
                         <div style="transform: rotate(45deg);">

                    ${
                       settings.isUseNumbers   ?  `  
                        <span class="d-flex justify-content-center align-items-center text-white"> 
                        <div class="text-center map-thumbnail">
                        <p class="text-center location-counter-bing-map"> ${pushPins[index].options.mapCount}  </p>
                        </div>
                        </span>
                       `  :  ` <span class="d-block text-center">
                       <img src=${pushPins[index].options.icon ? pushPins[index].options.icon : "/media/avatars/default_map.png"} class="map-thumbnail">
                       </span>`
                    }

                      
                   </div>
               </div>`
                       : `<div class=${settings.isUseNumbers  ? "single-location-marker-with-just-number" : "single-location-marker"} id="icon">
                   <div style="transform: rotate(45deg);">
                   ${
                     settings.isUseNumbers  ?  
                        `
                        <span class="d-flex justify-content-center align-items-center text-white">  
                        
                        <div class="text-center map-thumbnail">
                        <p class="text-center location-counter-bing-map"> ${pushPins[index].options.mapCount}</p>
                        </div>
                        </span>
                        ` : 
                        `
                        <span class="d-flex justify-content-center align-items-center text-white">

                         ${
                          settings.isSelectIcon == null? `<img src='${pushPins[index].options.carrierService && pushPins[index].options.carrierService.logoName.length > 0 ? config.SeriveImageUrl + pushPins[index].options.carrierService.logoName : '/media/avatars/default_map.png'}' alt="error" class="map-thumbnail ${pushPins[index].options.index}"></img>`  : 
                          settings.isSelectIcon == true  ?    `<img src='${settings.MapPointerIcon && settings.MapPointerIcon !== "default_map.png" ? settings.MapPointerIcon.includes("http") ? `${settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${settings.MapPointerIcon}` : `/media/avatars/default_map.png`}' alt="error" class="map-thumbnail ${pushPins[index].options.index}"></img>` :
                           `<img src='${pushPins[index].options.pickupPointType && pushPins[index].options.pickupPointType.logoName.length > 0 ? config.ImageUrl + pushPins[index].options.pickupPointType.logoName : '/media/avatars/default_map.png'}' alt="error" class="map-thumbnail ${pushPins[index].options.index}"></img>` 
                        }
                         `
                       }
                   </div>
               </div>`
                   }  
                    `
            : markerLength +
              `
                    <div class=${ settings.isUseNumbers && pushPins[index].options.title !== "Home"   ?  "single-location-marker-with-just-number" :  "single-location-marker"} id="icon2">
                        <div style="transform: rotate(45deg);">
                        ${
                          settings.isUseNumbers && pushPins[index].options.title !== "Home"  ? `
                          <span class="d-flex justify-content-center align-items-center text-white"> 
                          <div class="text-center map-thumbnail">
                          <p class="text-center location-counter-bing-map"> ${pushPins[index].options.mapCount}  </p>
                          </div>
                          </span>
                          ` :

                           `<span class="d-flex justify-content-center align-items-center">
                           <img src='${pushPins[index].options.icon ? pushPins[index].options.icon : "/media/avatars/default_map.png"}' class="map-thumbnail">
                           </span>
                           `
                          }
                        </div>
                    </div>`,
        })
        infobox.setMap(map)
        infobox.metadata = {
          title: pushPins[index].options.title,
          pickUpPointId: pushPins[index].pickUpPointId,
        }
        microsoft.Maps.Events.addHandler(infobox, 'click', Onclick)
        if (!(multiServiceMarker.length > 1) || specificActive) {
          microsoft.Maps.Events.addHandler(infobox, 'mouseleave', pushpinOut)
          microsoft.Maps.Events.addHandler(infobox, 'mouseenter', pushpinIn)
        }
        if (viewOptions.zoom === 0) {
          let location = pushPins.map(
            (pushpin) =>
              new microsoft.Maps.Location(
                parseFloat(pushpin.center.latitude),
                parseFloat(pushpin.center.longitude)
              )
          )
          const bounds = microsoft.Maps.LocationRect.fromLocations(location)
          map.setView({
            bounds: bounds,
            padding: 50,
          })
        } else {
          map.setView({
            center: new microsoft.Maps.Location(
              parseFloat(viewOptions.center.latitude),
              parseFloat(viewOptions.center.longitude)
            ),
            zoom: viewOptions.zoom,
          })
        }
        if (
          pushPins.length > 0 &&
          pushPins[1].center.latitude === pushPins[pushPins.length - 1].center.latitude
        ) {
          microsoft.Maps.loadModule('Microsoft.Maps.Directions', function () {
            let directionsManager = new microsoft.Maps.Directions.DirectionsManager(map)
            const distance = getDistance(
              {latitude: viewOptions.center.latitude, longitude: viewOptions.center.longitude},
              {
                latitude: pushPins[index].center.latitude,
                longitude: pushPins[index].center.longitude,
              }
            )
            if (distance <= 1000) {
              directionsManager.setRequestOptions({
                routeMode: microsoft.Maps.Directions.RouteMode.walking,
                routeDraggable: false,
              })
            } else {
              directionsManager.setRequestOptions({
                routeMode: microsoft.Maps.Directions.RouteMode.driving,
                routeDraggable: false,
              })
            }
            directionsManager.setRenderOptions({
              waypointPushpinOptions: {visible: false},
              viapointPushpinOptions: {visible: false},
            })
            let waypoint1 = new microsoft.Maps.Directions.Waypoint({
              location: new microsoft.Maps.Location(
                viewOptions.center.latitude,
                viewOptions.center.longitude
              ),
            })
            let waypoint2 = new microsoft.Maps.Directions.Waypoint({
              location: new microsoft.Maps.Location(
                pushPins[index].center.latitude,
                pushPins[index].center.longitude
              ),
            })
            directionsManager.addWaypoint(waypoint1)
            directionsManager.addWaypoint(waypoint2)
            directionsManager.calculateDirections()
          })
        }
      }
      return pin
    })
    infobox = new microsoft.Maps.Infobox(
      new microsoft.Maps.Location(parseFloat(-59.013414), parseFloat(318.080681))
    )
    infobox.setMap(map)
  }
  function pushpinIn(e) {
  if(pushPins.length === 2 && e.target.metadata.title === "Home"){
    if (e.target.metadata && !infobox.getVisible()) {
      infobox.setOptions({
        location: e.target.getLocation(),
        htmlContent:  tooltipTemplateForHome.replace('{title}', e.target.metadata.title),
        visible: true,
      })
    }
  }
  else if(pushPins.length > 2){
    if (e.target.metadata && !infobox.getVisible()) {
      infobox.setOptions({
        location: e.target.getLocation(),
        htmlContent: tooltipTemplateForHome.replace('{title}', e.target.metadata.title) ,
        visible: true,
      })
    }  
  }

 


  }
  function pushpinOut(e) {
    if (e.target.metadata) {
      infobox.setOptions({
        visible: false,
      })
    }
  }
  const multipalOrSingalLocation = (e) => {
    let pickUpPointId = e.target.metadata.pickUpPointId
    let multiServiceMarker = []
    locations.map((i) => {
      i.filter((ele) => {
        if (ele.pickUpPointId === pickUpPointId) {
          multiServiceMarker = i
        }
      })
    })
    if (multiServiceMarker.length > 0) {
      let element = document.getElementById(multiServiceMarker[0].pickUpPointId)
      element?.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
      handleMainBox(multiServiceMarker)
    }
  }
  function Onclick(e) {
    let btn = document.getElementById('tooltip-box')
    setTimeout(()=>{
      // getTooltipBox();
      BingMapSetTooltipBox();

    },2000);
    if (e.originalEvent.target.type === 'checkbox') {
      let pickUpPointId = e.target.metadata.pickUpPointId
      let multiServiceMarker = []
      locations.map((i) => {
        i.filter((ele) => {
          if (ele.pickUpPointId === pickUpPointId) {
            multiServiceMarker = i
          }
        })
      })
      handleCheckBox(
        multiServiceMarker,
        multiServiceMarker[e.originalEvent.target.id],
        e.originalEvent.target.id,
        isChecked
      )
    }
    if (
      e.originalEvent.target.className === 'map-pin-icon' ||
      e.originalEvent.target.className.includes(`map-thumbnail`) ||
      e.originalEvent.target.className === 'text-center location-counter-bing-map'  ||
      e.originalEvent.target.className.includes('map-thumbnail-multiple') ||
      e.originalEvent.target.className === 'frame' 
    ) {
      if (btn !== null) {
        if (!btn.className.includes('d-none')) {
          multipalOrSingalLocation(e)
        }
      } else {
        multipalOrSingalLocation(e)
      }
    }

    if (btn !== null)
      e.originalEvent.target.id === 'close-button'
        ? btn.classList.add('d-none')
        : btn.classList.remove('d-none')
  }
  return (
    <>
      <div id='myMap' style={{position: 'relative', width: '100% ', height: '100%'}}></div>
    </>
  )
}
