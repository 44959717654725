import { FC, useEffect, useState } from 'react';
import { useLang } from './Metronici18n'
import { IntlProvider } from 'react-intl'
import '@formatjs/intl-relativetimeformat/polyfill'
import '@formatjs/intl-relativetimeformat/locale-data/en'
import '@formatjs/intl-relativetimeformat/locale-data/de'
import '@formatjs/intl-relativetimeformat/locale-data/es'
import '@formatjs/intl-relativetimeformat/locale-data/fr'
import '@formatjs/intl-relativetimeformat/locale-data/ja'
import '@formatjs/intl-relativetimeformat/locale-data/zh'
import { WithChildren } from '../helpers'
import { Languages } from "../../Config/Languages";
import { config } from '../../Config/Appsettings';
import { shallowEqual, useSelector } from 'react-redux';
import { useSearchParams } from "react-router-dom";

const I18nProvider: FC<WithChildren> = ({ children }) => {
  const locale = useLang()
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedLang: any = localStorage.getItem("i18nConfig");
  const userLang: any = localStorage.getItem("userLang");
  let paramLanguage: any = searchParams.get("language");
  const storedData: any = localStorage.getItem("mapConfiguration");
  const [language, setLanguage] = useState<string>(selectedLang ? selectedLang : "en");
  const desktopOrMobile = navigator.userAgent;
  let regexp = /android|iphone|kindle|ipad/i;
  let isMobileDevice = regexp.test(desktopOrMobile);
  let updateTheme: any = useSelector((state: any) => state?.product?.updateTheme, shallowEqual);
  let Settings: any;


  useEffect(() => {
    if (paramLanguage) {
      let lang: boolean = paramLanguage in Languages;
      let langcode = lang ? paramLanguage : "en";
      localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: langcode }));
      setLanguage(langcode);
    }
    else if (paramLanguage === null && userLang) {
      let lang: boolean = userLang in Languages;
      let langcode = lang ? userLang : "en";
      localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: langcode }));
      setLanguage(langcode);
    }
    else {
      if (selectedLang) {
        if (storedData !== null) {
          Settings = isMobileDevice ? JSON.parse(storedData).mobileConfig : JSON.parse(storedData).desktopConfig;
          if (!Settings.LanguageSelection) {
            navigator.geolocation.getCurrentPosition(setCountrylanguage);
          };
        };
        let convertInString = JSON.parse(selectedLang);
        setLanguage(convertInString.selectedLang);
        localStorage.setItem("i18nConfig", JSON.stringify(convertInString));
      }
      else {
        navigator.geolocation.getCurrentPosition(setCountrylanguage);
      };
    }
  }, []);
  function setCountrylanguage(position: any) {
    const userLang: any = localStorage.getItem("userLang");
    if (userLang) {
      let lang: boolean = userLang in Languages;
      let langcode = lang ? userLang : "en";
      localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: langcode }));
      setLanguage(langcode);
    }
    else {
      let localValues = isMobileDevice ? updateTheme.mobileConfig : updateTheme.desktopConfig;
      if (!localValues.bingMapKey) {
        localValues.bingMapKey = localValues.bingMapKey;
      };
      const bingMap = 'https://dev.virtualearth.net/REST/v1/Locations/' + position.coords.latitude + ',' + position.coords.longitude + '?o=json&key=' + 'AjrA_VMdgQRybZoZEgIW8qSgWRwcFoy0qEXiaKSA-u84uQ53nMW8GoXjVQm17j-2' + '&include=ciso2';
      fetch(bingMap)
        .then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Request failed. Please check your API key or try again later.');
          };
        })
        .then(function (data) {
          if (!data) {
            localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: "en" }));
            setLanguage("en");
            return;
          };
          let lanCode: any = data.resourceSets[0].resources[0].address.countryRegionIso2;
          if (lanCode !== undefined) {
            let convertInLowerCase = lanCode.toLowerCase();
            let lang: boolean = convertInLowerCase in Languages;
            let langcode = lang ? convertInLowerCase : "en";
            localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: langcode }));
            setLanguage(langcode);
          }
          else {
            localStorage.setItem("i18nConfig", JSON.stringify({ selectedLang: "en" }));
            setLanguage("en");
          };
        })
        .catch(function (error) {
          console.log(error.message);
        });
    }
  }
  return (
    <IntlProvider locale={locale} messages={Languages[language]}>
      {children}
    </IntlProvider>
  )
}
export { I18nProvider }
