import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import GoogleMapReact from "google-map-react";
import { MapContainer, TileLayer, Marker, useMap, Tooltip } from "react-leaflet";
import L from "leaflet";
import axios, { AxiosResponse } from "axios";
import { config } from "../../../Config/Appsettings";
import "../../../_metronic/assets/css/custom.css"
import { Resource, Recenter, GoogleMapLocatins, StreetMapLocations, IRouteLocations, IupdateLocation } from "../../Interfaces/MapInterfaces";
import { AutoComplete } from 'antd';
import { BingMap } from "./BingMap";
import getDistance from 'geolib/es/getDistance'
import { RoutingMachine } from "./RoutingMachine";
import 'leaflet/dist/leaflet.css';
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { GetLable } from "../../../Config/GetLabels";
import { ProductSlice } from "../../../redux/ProductSlice";
import { useSearchParams } from "react-router-dom";
import { SideLocations } from "./SideLocations";
import { GoogleLocationPin } from "./GoogleLocationPin";
import { BingMapSetTooltipBox, GoogleMapSetTooltipBox } from "../../common/Utils/DynamicPopupAlignment";
import {cloneDeep} from "lodash";
import { useLocation } from 'react-router-dom'
let Settings: any;
let HomeIcon: string = `${config.AvtarUrl}`;
export const MapsNew = () => {
    const { actions } = ProductSlice;
    const dispatch = useDispatch();
    const [locations, setLocations] = useState<any[]>([]);
    const [pushPins, setPushPins] = useState<any[]>([]);
    const [oldPushPins, setOldPushPins] = useState<any[]>([]);
    const [selectedItem, setSelectedItem] = useState<any[]>([]);
    const [viewOptions, setViewOptions] = useState<any>();
    const [active, setActive] = useState<string | undefined>("");
    const [resource, setResource] = useState<Resource[]>();
    const [address, setAddress] = useState<string | undefined>("");
    const [options, setOptions] = useState<{ value: string | undefined }[]>();
    const [googleMapLocations, setGoogleMapLocations] = useState<GoogleMapLocatins[]>([]);
    const [oldGoogleMapLocations, setOldGoogleMapLocations] = useState<GoogleMapLocatins[]>([]);
    const [streetMapLocations, setStreetMapLocations] = useState<StreetMapLocations[]>([]);
    const [oldStreetMapLocations, setOldStreetMapLocations] = useState<StreetMapLocations[]>([]);
    const [selectedLat, setSelectedLat] = useState<string>("");
    const [latitude, setLatitude] = useState<DoubleRange | undefined>();
    const [longitude, setLongitude] = useState<DoubleRange | undefined>();
    const [googleDefaultLocations, setGoogleDefaultLocations] = useState<any>({
        address: '1600 Amphitheatre Parkway, Mountain View, california.',
        lat: 14.913254,
        lng: 50.43094
    });
    const [mapLoadder, setMapLoadder] = useState<string>();
    const [isIframe,setIsIframe] = useState<boolean>(false);
    const [myMap, setMyMap] = useState(null);
    const [myMaps, setMyMaps] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isUpdated, setIsUpdated] = useState(false);
    const [bounds, setBounds] = useState<any>([])
    const [isChecked, setIsChecked] = useState("");
    const [specificActive, setSpecificActive] = useState<String | undefined>("")
    const [zoomLevel, setZoomLevel] = useState(17);
    const defaultRouteLocations = {
        startJourney: { latitude: null, longitude: null },
        endJourney: { latitude: null, longitude: null, pickUpPointId: "" },
        icons: { home: null, destination: null }
    }
    const [routeLocations, setRouteLocations] = useState<IRouteLocations>(defaultRouteLocations);
    const key = useMemo(() => JSON.stringify(Math.random()), [Math.random()]);
    const [directionsService, setDirectionsService] = useState<any>(null);
    const [directionsDisplay, setDirectionsDisplay] = useState<any>(null);
    const [servicesHolder, SetservicesHolder] = useState<any[]>([]);
    const [userSettings, setUserSettings] = useState<any | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [userDeniedGeolocation, setUserDeniedGeolocation] = useState<boolean>(true);
    const [isAutoCompleteOpen, setIsAutoCompleteOpen] = useState(true);
    const intl = useIntl();
    const divRef: any = useRef<HTMLDivElement>(null);
    const [isShowTimeBox, setIsTimeBox] = useState({ isShow: false, timeBoxId: "" });
    const [mapCounts, setMapCounts] = useState<number | null>(0);
    const [dynamicHeight,setDynamicHeight] = useState<any>("");
    const [isInitial,setIsisInitial] = useState<boolean>(true);
    
    // Show the Desktop name or Mobile Name currently using
    const desktopOrMobile = navigator.userAgent;
    const location = useLocation();
    let regexp = /android|iphone|kindle|ipad/i;
    let isMobileDevice = regexp.test(desktopOrMobile);
    let updateTheme: any = useSelector((state: any) => state?.product?.updateTheme, shallowEqual);
    let topHorizontalListCount = 0;
    let bottomHorizontalListCount = 0;
    let sideVerticalListCount = 0;

    const storedData = localStorage.getItem("mapConfiguration");
    const [streetDefaultLocations, setStreetDefaultLocations] = useState<any>({
        lat: 14.913254,
        lng: 50.43094
    });
    let counter = -1;
    if (storedData !== null) {
        Settings = userSettings ? (isMobileDevice ? userSettings.config.mobileConfig : userSettings.config.desktopConfig) : (isMobileDevice ? JSON.parse(storedData).mobileConfig : JSON.parse(storedData).desktopConfig);
        Settings = cloneDeep(Settings);

        let selectMap = {
            openStreetMap: Settings.isOpenStreetMap ? "openStreetMap" : "",
            googleMap: Settings.isGoogleMap ? "googleMap" : "",
            bingMap: Settings.isBingMap ? "bingMap" : ""
        };

        if(location.pathname.includes("thankyou") || searchParams.get("ispopup") == "true"){
            selectMap.openStreetMap = "";
            selectMap.googleMap = "";
            selectMap.bingMap = "bingMap";
        }
        Settings["selectMap"] = selectMap;
    }
    else {
        Settings = userSettings ? (isMobileDevice ? userSettings.config.mobileConfig : userSettings.config.desktopConfig) : (isMobileDevice ? updateTheme.mobileConfig : updateTheme.desktopConfig);
        Settings = cloneDeep(Settings);
       
        let selectMap = {
            openStreetMap: Settings.isOpenStreetMap ? "openStreetMap" : "",
            googleMap: Settings.isGoogleMap ? "googleMap" : "",
            bingMap: Settings.isBingMap ? "bingMap" : ""
        };
        if(location.pathname.includes("thankyou") || searchParams.get("ispopup") == "true"){
            selectMap.openStreetMap = "";
            selectMap.googleMap = "";
            selectMap.bingMap = "bingMap";
        }
            Settings["selectMap"] = selectMap;
    }
    document.documentElement.style.setProperty('--dynamic-color', Settings.selectedThemeColor);
    document.documentElement.style.setProperty('--dynamic-icon-color', Settings.iconChanged);

    (function () {
          if(Settings.HomePointerIcon && Settings.HomePointerIcon !== "default_map.png" && Settings.isSelectIcon){
            if(Settings.HomePointerIcon.includes("http")){
                HomeIcon = `${Settings.HomePointerIcon}`
            }else{
                HomeIcon = `${config.CustomHomeImageUrl}${Settings.HomePointerIcon}`
            }
          }  else{
            HomeIcon =  `${config.AvtarUrl}`
          }
    })();
    
    const iconChanger = () => {
        setUserDeniedGeolocation(false);
        if(Settings.isSelectIcon == null){
            locations.map((item: any) => {
                if (item[0].carrierService) {
                    item[0].carrierService.logoes =
                        item.filter((e: any) => e.carrierService != null).map((ele: any) => ele.carrierService.logoName)
                            .filter((item: any, index: number, ownArray: any[]) => ownArray.indexOf(item) === index);
                    return null;
                }
                return null;
            });
            locations.map((item: any, num: number) => {
                let id: any = document.getElementById(`${item[0].carrierService ? item[0].carrierService.carrierId + num : num}`);
                if (id) {
                    if (item[0].carrierService) {
                        let index = item[0].carrierService.logoes.findIndex((j: string) => j === id.src.replace(/^.*[\\\/]/, '')) + 1;
                        id.src = item[0].carrierService.logoes[item[0].carrierService.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.SeriveImageUrl + item[0].carrierService.logoes[item[0].carrierService.logoes.length === index ? 0 : index];
                    }
                }
                return null;
            });
    
            streetMapLocations.map((item: any, num: number) => {
                let id: any = document.getElementsByClassName(`${item.index}`);
                if (id.length > 0 && item.carrierService && item.carrierService.logoes && item.carrierService.logoes.length > 1) {
                    let index = item.carrierService.logoes.findIndex((j: string) => j === $('.' + item.index).attr('src')?.replace(/^.*[\\\/]/, '')) + 1;
                    $('.' + item.index).attr('src', item.carrierService.logoes[item.carrierService.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.SeriveImageUrl + item.carrierService.logoes[item.carrierService.logoes.length === index ? 0 : index]);
                }
            });
    
            googleMapLocations.map((item: any, num: number) => {
                let id: any = document.getElementsByClassName(`${item.index}`);
                if (id.length > 0 && item.carrierService && item.carrierService.logoes && item.carrierService.logoes.length > 1) {
                    let index = item.carrierService.logoes.findIndex((j: string) => j === $('.' + item.index).attr('src')?.replace(/^.*[\\\/]/, '')) + 1;
                    $('.' + item.index).attr('src', item.carrierService.logoes[item.carrierService.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.SeriveImageUrl + item.carrierService.logoes[item.carrierService.logoes.length === index ? 0 : index]);
                }
            });
    
            pushPins.map((item: any, num: number) => {
                let id: any = document.getElementsByClassName(`${item.options.index}`);
                if (id.length > 0 && item.options.carrierService && item.options.carrierService.logoes && item.options.carrierService.logoes.length > 1) {
                    let index = item.options.carrierService.logoes.findIndex((j: string) => j === $('.' + item.options.index).attr('src')?.replace(/^.*[\\\/]/, '')) + 1;
                    $('.' + item.options.index).attr('src', item.options.carrierService.logoes[item.options.carrierService.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.SeriveImageUrl + item.options.carrierService.logoes[item.options.carrierService.logoes.length === index ? 0 : index]);
                }
            });
        }
        else if(Settings.isSelectIcon == false){
            locations.map((item: any) => {
                if (item[0].pickupPointType) {
                    item[0].pickupPointType.logoes =
                        item.filter((e: any) => e.pickupPointType != null).map((ele: any) => ele.pickupPointType.logoName)
                            .filter((item: any, index: number, ownArray: any[]) => ownArray.indexOf(item) === index);
                    return null;
                }
                return null;
            });
            locations.map((item: any, num: number) => {
                let id: any = document.getElementById(`${item[0].pickupPointType ? item[0].carrierService.carrierId + num : num}`);
                if (id) {
                    if (item[0].pickupPointType) {
                        let index = item[0].pickupPointType.logoes.findIndex((j: string) => j === id.src.replace(/^.*[\\\/]/, '')) + 1;
                        id.src = item[0].pickupPointType.logoes[item[0].pickupPointType.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.ImageUrl + item[0].pickupPointType.logoes[item[0].pickupPointType.logoes.length === index ? 0 : index];
                    }
                }
                return null;
            });
    
            streetMapLocations.map((item: any, num: number) => {
                let id: any = document.getElementsByClassName(`${item.index}`);
                if (id.length > 0 && item.pickupPointType && item.pickupPointType.logoes && item.pickupPointType.logoes.length > 1) {
                    let index = item.pickupPointType.logoes.findIndex((j: string) => j === $('.' + item.index).attr('src')?.replace(/^.*[\\\/]/, '')) + 1;
                    $('.' + item.index).attr('src', item.pickupPointType.logoes[item.pickupPointType.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.ImageUrl + item.pickupPointType.logoes[item.pickupPointType.logoes.length === index ? 0 : index]);
                }
            });
    
            googleMapLocations.map((item: any, num: number) => {
                let id: any = document.getElementsByClassName(`${item.index}`);
                if (id.length > 0 && item.pickupPointType && item.pickupPointType.logoes && item.pickupPointType.logoes.length > 1) {
                    let index = item.pickupPointType.logoes.findIndex((j: string) => j === $('.' + item.index).attr('src')?.replace(/^.*[\\\/]/, '')) + 1;
                    $('.' + item.index).attr('src', item.pickupPointType.logoes[item.pickupPointType.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.ImageUrl + item.pickupPointType.logoes[item.pickupPointType.logoes.length === index ? 0 : index]);
                }
            });
    
            pushPins.map((item: any, num: number) => {
                let id: any = document.getElementsByClassName(`${item.options.index}`);
                if (id.length > 0 && item.options.pickupPointType && item.options.pickupPointType.logoes && item.options.pickupPointType.logoes.length > 1) {
                    let index = item.options.pickupPointType.logoes.findIndex((j: string) => j === $('.' + item.options.index).attr('src')?.replace(/^.*[\\\/]/, '')) + 1;
                    $('.' + item.options.index).attr('src', item.options.pickupPointType.logoes[item.options.pickupPointType.logoes.length === index ? 0 : index] == "" ? "/media/avatars/default_map.png" : config.ImageUrl + item.options.pickupPointType.logoes[item.options.pickupPointType.logoes.length === index ? 0 : index]);
                }
            });
        }


    };

    useEffect(() => {


        const handleKeyUp = (e: any) => {
            let locationSearchBar: any = document.getElementById("locationSearchBar");
            if (e.keyCode === 13 && document.activeElement === locationSearchBar) {
                setIsAutoCompleteOpen(false);
                document.getElementById("serachLocation")?.click();
                locationSearchBar.blur();
            }
        }
        window.document.addEventListener('keyup', handleKeyUp);
        return () => {
            window.document.removeEventListener('keyup', handleKeyUp);
        }
    }, []);

 const takeHeight = () =>{
     let height = 107;
    //  if(handlehHideMapHeader()){
    //     height += 70
    //  }
     if(Settings.topManuChanged){
        height += 172
     }
     if(Settings.bottomManuChanged){
        height += 182
     }
    return height; 
 };

 useEffect(()=>{
    if(isIframe){
        if(isInitial){
            document.documentElement.style.setProperty('--dynamic-height-space', (takeHeight()+'px'));
            setIsisInitial(false);
        }
    } 
 },[isIframe]);

useEffect(() => {
        // let iframeHeight : any = localStorage.getItem('iframeHeight');
        // if(iframeHeight !== null)
        // {
        //     document.documentElement.style.setProperty('--dynamic-height', iframeHeight+'px');
        //     setDynamicHeight(iframeHeight);
        //     setIsIframe(true);
        // }
        window.addEventListener('message', function(event) { //event = onmessage event object
                    var height = event.data;
                    if(height !== 0 && !isNaN(event.data)){
                        document.documentElement.style.setProperty('--dynamic-height', location.pathname.includes("thankyou") || searchParams.get("ispopup") == "true" ? "100vh" :  height+'px');
                        //setDynamicHeight(height - 195);
                        setDynamicHeight(height);
                        setIsIframe(true);
                    //  document.documentElement.style.setProperty('--dynamic-height-space', (takeHeight()+'px'));

                    }else{
                         document.documentElement.style.setProperty('--dynamic-height', 450+'px');
                    }
          });
  }, []);
  
    useEffect(() => {
        const storedData = localStorage.getItem("mapConfiguration");
        if (storedData !== null) {
            dispatch(actions.UpdateTheme({ updateTheme: JSON.parse(storedData) }));
        };

        // Separate code
        let isTooltip: any =  searchParams.get("ispopup");
        let customer_id: any = searchParams.get("customer_id");
        let pickUpPointId: any = searchParams.get("id");
        let address: any = searchParams.get("address");
        let zipcode: any = searchParams.get("zipcode");
        let language: any = searchParams.get("language");
        if (address || zipcode ) {
            address = address == null ?  address = "" : address;
            zipcode =   zipcode == null ?  zipcode = "" : zipcode;
            onSearch({ search : `${address} ${zipcode}`, isFirst : true});
        }
        setLatitude(resource?.[0].point.coordinates[0]);
        setLongitude(resource?.[0].point.coordinates[1]);
        if (pickUpPointId !== null) {
            axios({
                method: 'GET',
                url: `${config.BaseUrl}PickUpPoint/GetPickUpPointById/${pickUpPointId}`
            }).then((response: AxiosResponse<any>) => {
                let lacationsdata: any = response.data
                // HandleZipcode(lacationsdata.positionX, lacationsdata.positionY);
                HandleZipcode({lat : lacationsdata.positionX, long : lacationsdata.positionY});
                setTimeout(() => {
                    if (divRef.current) {
                        divRef.current.click();
                        let ele = document.getElementById(pickUpPointId);
                        ele?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    }
                }, 5000);
            })
        }
            if (customer_id !== null) {
                axios({
                    method: 'POST',
                    url: `https://maps.crossdesk.com/api/crossdesk/map/config`,
                    params: { customer_id: customer_id },
                    headers: { 'Content-Type': 'application/json' }
                }).then((response: AxiosResponse<any[] | null>) => {
                    let lacationsData: any = response.data;
                    let deviceConfiguration = isMobileDevice ? lacationsData.config.mobileConfig : lacationsData.config.desktopConfig;
                    let localValues = isMobileDevice ? updateTheme.mobileConfig : updateTheme.desktopConfig;
    
                    if (!deviceConfiguration.bingMapKey && !deviceConfiguration.googleMapKey) {
                        deviceConfiguration.bingMapKey = localValues.bingMapKey;
                        deviceConfiguration.googleMapKey = localValues.googleMapKey;
                    }
    
                    let selectMap = {
                        openStreetMap: deviceConfiguration.isOpenStreetMap ? "openStreetMap" : "",
                        googleMap: deviceConfiguration.isGoogleMap ? "googleMap" : "",
                        bingMap: deviceConfiguration.isBingMap ? "bingMap" : ""
                    };

                    if(location.pathname.includes("thankyou")){
                        selectMap.openStreetMap  = "";
                        selectMap.googleMap  = "";
                        selectMap.bingMap  = "bingMap";
                        ThankYouPageLocation(); 
                    }
    
                    deviceConfiguration.selectMap = selectMap;

                        var my_awesome_script = document.createElement('script');
        
                        my_awesome_script.setAttribute('defer', "");
                        my_awesome_script.setAttribute('src', `https://www.bing.com/api/maps/mapcontrol?key=${deviceConfiguration.bingMapKey ? deviceConfiguration.bingMapKey : config.MapKey}`);
                        my_awesome_script.setAttribute('type', `text/javascript`);
        
                        my_awesome_script.setAttribute('async', '');
                        document.head.appendChild(my_awesome_script);
                 
    
                    const currentFirstMap: any = Object.keys(deviceConfiguration.selectMap).find((key) => deviceConfiguration.selectMap[key] !== "");
                    if (location.pathname.includes("thankyou")) {
                        setMapLoadder("BingMap");
                    }
                   else if (currentFirstMap === 'openStreetMap') {
                        setMapLoadder("StreetMap");
                    }
                    else if (currentFirstMap === 'googleMap') {
                        setMapLoadder("GoogleMapReact");
                    }
                    else if (currentFirstMap === 'bingMap') {
                        setMapLoadder("BingMap");
                    }
                    
                    if (language) {
                        localStorage.setItem("userLang", language);
                    } else if (language && deviceConfiguration.userLanguage !== undefined) {
                        localStorage.setItem("userLang", deviceConfiguration.userLanguage);
                    }
    
                    setUserSettings(lacationsData);
                    if(!location.pathname.includes("thankyou") && !(address || zipcode)){
                            navigator.geolocation.getCurrentPosition(success, failed);
                    }
                }).catch(()=>{
                    if (location.pathname.includes("thankyou")) {
                        setMapLoadder("BingMap");
                    }
                   else if (Settings.selectMap.openStreetMap === 'openStreetMap') {
                        setMapLoadder("StreetMap");
                    }
                    else if (Settings.selectMap.googleMap === 'googleMap') {
                        setMapLoadder("GoogleMapReact");
                    }
                    else if (Settings.selectMap.bingMap === 'bingMap') {
                        setMapLoadder("BingMap");
                    }
                });
            } else {
                if (Settings.selectMap.openStreetMap === 'openStreetMap') {
                    setMapLoadder("StreetMap");
                }
                else if (Settings.selectMap.googleMap === 'googleMap') {
                    setMapLoadder("GoogleMapReact");
                }
                else if (Settings.selectMap.bingMap === 'bingMap') {
                    setMapLoadder("BingMap");
                }
                    if(!(address || zipcode)){
                        navigator.geolocation.getCurrentPosition(success, failed);
                    }
            };
        // Separate code
        //navigator.geolocation.getCurrentPosition(success, failed);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (locations.length) {
            const interval = setInterval(() => {
                if (locations) {
                    iconChanger();
                }
            }, 2000);
            return () => {
                clearInterval(interval);
            };
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [locations]);

    const ThankYouPageLocation = () => {
     let customer_id =  searchParams.get("customer_id");
     let relayPoint =  searchParams.get("relayPoint");
     let language =   searchParams.get("language");
     setIsLoading(true);
     setIsLoaded(true);
     setIsUpdated(true);
     setRouteLocations(defaultRouteLocations);
        axios({
            method: 'GET',
            url: `${config.BaseUrl}PickUpPoint/Thankyou/${customer_id}/${relayPoint}/${language}`
        }).then((response: AxiosResponse<any>) => {
            let record: any = response.data.locations[0][0];
            let lstData : any[] = [];
            lstData.push(response.data);
            setLatitude(record.positionY);
            setLongitude(record.positionX);

            var iconImage = Settings.isSelectIcon == true ? 
            Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png"
             && Settings.MapPointerIcon.includes("http") 
            ? `${Settings.MapPointerIcon}` : Settings.MapPointerIcon.length != 0 ? `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : "/media/avatars/map_single.png" :Settings.isSelectIcon == false 
            ? `${config.ImageUrl}${record.pickupPointType?.logoName}` :
            record.carrierService?.logoName.length != 0  ? `${config.SeriveImageUrl}${record.carrierService?.logoName}` : "/media/avatars/map_single.png";
            
            // We are adding here two same data in array because inside bing map we need two records fro shwo the location accroding to bing map.
            let bingmap: any[] = [
                {
                    center: {
                        latitude: parseFloat(record.positionX),
                        longitude: parseFloat(record.positionY),
                    },
                    options: {
                        title: record.name,
                        icon: iconImage,
                        carrierService: record.carrierService,
                        pickupPointType: record.pickupPointType,
                        index: "bingmap" + 0
                    },
                    pickUpPointId: record.pickUpPointId
                },    {
                    center: {
                        latitude: parseFloat(record.positionX),
                        longitude: parseFloat(record.positionY),
                    },
                    options: {
                        title: record.name,
                        icon: iconImage,
                        carrierService: record.carrierService,
                        pickupPointType: record.pickupPointType,
                        index: "bingmap" + 1
                    },
                    pickUpPointId: record.pickUpPointId
                }
            ];

            setPushPins(bingmap)
            setViewOptions({
            center: { latitude: parseFloat(record.positionX), longtitude : parseFloat(record.positionY) },
            zoom: 0
            });
            setIsLoading(false);
            setIsLoaded(false);
            setMapLoadder("BingMap");
            setLocations(response.data.locations);
        }) 
    };

    // Top Option List Start Here.
    const scrollContainer = document.getElementById('scrollContainerRight')

    scrollContainer?.addEventListener("wheel", (event) => {
        event.preventDefault();
        if (event.deltaY > 1) {
            scrollContainer.scrollLeft += 700;
        } else {
            scrollContainer.scrollLeft += -700;
        }
    });

    const slider: any = document.getElementById('scrollContainerRight');
    let isDown: boolean = false;
    let startX: any;
    let scrollLeft: any;

    // Mobile Scroll For Top Option List.

    slider?.addEventListener('touchstart', (e: any) => {
        isDown = true;
        startX = e.changedTouches[0].pageX  - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    });

    slider?.addEventListener('touchend', () => {
        isDown = false;
    });

    slider?.addEventListener('touchmove', (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.changedTouches[0].pageX - slider.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
    });

    // Desktop Scroll For Top Option List.

    slider?.addEventListener('mousedown', (e: any) => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
    });
    slider?.addEventListener('mouseleave', () => {
        isDown = false;
    });
    slider?.addEventListener('mouseup', () => {
        isDown = false;
    });
    slider?.addEventListener('mousemove', (e: any) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 2; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
    });


    // Bottom Option List Start Here.
    const bottomscrollContainer = document.getElementById('bottomScrollContainerRight')

    bottomscrollContainer?.addEventListener("wheel", (event) => {
        event.preventDefault();
        if (event.deltaY > 1) {
            bottomscrollContainer.scrollLeft += 700;
        } else {
            bottomscrollContainer.scrollLeft += -700;
        }
    });

    const bottomSlider: any = document.getElementById('bottomScrollContainerRight');
    let bottomIsDown: boolean = false;
    let bottomStartX: any;
    let bottomScrollLeft: any;

// Mobile Scroll For Bottom Option List 
    bottomSlider?.addEventListener('touchstart', (e: any) => {
        bottomIsDown = true;
        bottomStartX = e.changedTouches[0].pageX  - bottomSlider.offsetLeft;
        bottomScrollLeft = bottomSlider.scrollLeft;
    });

    bottomSlider?.addEventListener('touchend', () => {
        bottomIsDown = false;
    });

    bottomSlider?.addEventListener('touchmove', (e: any) => {
        if (!bottomIsDown) return;
        e.preventDefault();
        const x = e.changedTouches[0].pageX - bottomSlider.offsetLeft;
        const walk = (x - bottomStartX) * 2; //scroll-fast
        bottomSlider.scrollLeft = bottomScrollLeft - walk;
    });


// Desktop Scroll For Bottom Option List 
    bottomSlider?.addEventListener('mousedown', (e: any) => {
        bottomIsDown = true;
        bottomStartX = e.pageX - bottomSlider.offsetLeft;
        bottomScrollLeft = bottomSlider.scrollLeft;
    });
    bottomSlider?.addEventListener('mouseleave', () => {
        bottomIsDown = false;
    });
    bottomSlider?.addEventListener('mouseup', () => {
        bottomIsDown = false;
    });
    bottomSlider?.addEventListener('mousemove', (e: any) => {
        if (!bottomIsDown) return;
        e.preventDefault();
        const x = e.pageX - bottomSlider.offsetLeft;
        const walk = (x - bottomStartX) * 2; //scroll-fast
        bottomSlider.scrollLeft = bottomScrollLeft - walk;
    });


    let distance = getDistance({ latitude: parseFloat(String(routeLocations.startJourney.latitude)), longitude: parseFloat(String(routeLocations.startJourney.longitude)) }, { latitude: parseFloat(String(routeLocations.endJourney.latitude)), longitude: parseFloat(String(routeLocations.endJourney.longitude)) });
    let distanceType = "";
    if (Settings.distanceChanged) {
        if (distance <= 1000) {
            distanceType = `${Math.round(distance).toFixed(1)}${config.GetLables(intl, "Meter")}`;
        }
        else {
            distanceType = `${(distance / 1000).toFixed(1)} ${config.GetLables(intl, "KM")} `;
        };
    } else {
        distanceType = `${(distance * 0.000621371192).toFixed(1)} ${config.GetLables(intl, "Miles")}`;

    };

    const fitBounds = (map: any, maps: any) => {
        const bounds = new window.google.maps.LatLngBounds();
        googleMapLocations.forEach(({ lat, lng }) => {
            bounds.extend(new maps.LatLng(lat, lng));
            google.maps.event.trigger(map, 'resize')
        });
        map.fitBounds(bounds);
    };
    const handleOnChange = (bounds: any) => {
        if (isLoaded) {
            if (myMap != null && myMaps != null) {
                fitBounds(myMap, myMaps);
            }
            setIsLoaded(false);
            return;
        }
        if (googleMapLocations.length === 2) {
            if (directionsService == null) {
                setDirectionsService(new google.maps.DirectionsService());
                setDirectionsDisplay(new google.maps.DirectionsRenderer({ preserveViewport: true }));
            }
            let origin = { lat: parseFloat(String(googleMapLocations[0].lat)), lng: parseFloat(String(googleMapLocations[0].lng)) };
            let destination = { lat: parseFloat(String(googleMapLocations[1].lat)), lng: parseFloat(String(googleMapLocations[1].lng)) };
            if (directionsDisplay !== null) {
                directionsDisplay.setMap(null);
            }
            const distance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(googleMapLocations[1].lat)), longitude: parseFloat(String(googleMapLocations[1].lng)) });
            const travelMode = distance <= 1000 ? google.maps.TravelMode.WALKING : google.maps.TravelMode.DRIVING
            if (directionsDisplay !== null) {
                directionsDisplay.setMap(myMap);
                directionsService.route({
                    origin: origin,
                    destination: destination,
                    travelMode: travelMode
                }, function (response: any, status: google.maps.DirectionsStatus) {
                    if (status === google.maps.DirectionsStatus.OK) {
                        directionsDisplay.setDirections(response);
                        directionsDisplay.setOptions({ suppressMarkers: true });
                    }
                });
            }
        }
        if (myMap != null && myMaps != null && isUpdated) {
            fitBounds(myMap, myMaps);
            setIsUpdated(false)
        }
    };
    const handleApiLoaded = (map : any, maps :any) => {
        setMyMap(map);
        setMyMaps(maps);
        fitBounds(map, maps);
        if (directionsService == null) {
            setDirectionsService(new google.maps.DirectionsService());
            setDirectionsDisplay(new google.maps.DirectionsRenderer({ preserveViewport: true }));
        }
    };


    const success = (position: any) => {
        let pickUpPointId: any = searchParams.get("id");
        let address: any = searchParams.get("address");
        let zipcode: any = searchParams.get("zipcode");

        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);
        if (pickUpPointId) return;
        axios({
            method: 'GET',
            // url: `${config.BaseUrl}PickUpPoint/GetAddressFromPoint/${position.coords.latitude}/${position.coords.longuserDeniedGeolocationitude}`
            url: `${config.BaseUrl}PickUpPoint/GetAddressFromPoint/${position.coords.latitude}/${position.coords.longitude}`

        }).then((response: AxiosResponse<string>) => {
            let data = JSON.parse(response.data);
            let AddressLocation = data.Response.ResourceSets.ResourceSet.Resources.Location.Name;
            if(!(address ||  zipcode)){
                setAddress(AddressLocation.replace("/", " "));
            }
            // HandleZipcode(position.coords.latitude, position.coords.longitude, AddressLocation);
            HandleZipcode({lat : position.coords.latitude, long : position.coords.longitude, currentAddress : AddressLocation});
        });
    };

    const  failed = (err: any) => {
        console.log(err.message);
        setUserDeniedGeolocation(true);
    };
    
    const Recenter: FC<Recenter> = ({ lat, lng }) => {
        const map = useMap();
        map.setView([lat, lng]);
        if (bounds.length > 0) {
            map.fitBounds(bounds);
        }
        return null;
    }

    const handleSpecificLocations = (item: any, serviceIndex: number, isSpecificLocations: boolean | string, mapCount: number) => {
        if(mapLoadder === "GoogleMapReact"){
            GoogleMapSetTooltipBox();
        }else if(mapLoadder === "BingMap"){
            setTimeout(()=>{
                BingMapSetTooltipBox();
            },2000)
        };
    
        if (!isSpecificLocations) {
            let selectedPoint = {...item[serviceIndex]};
                selectedPoint.pickupPointSchedules1 = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                    let properties: any = config.ShopTimeSchedule[key].split(",");
                    const newKey = config.GetLables(intl, properties[0]);
                    return { [newKey]: [selectedPoint.pickupPointSchedules1[properties[1]] + " - " + selectedPoint.pickupPointSchedules1[properties[2]] , selectedPoint.pickupPointSchedules1[properties[3]] +" - "+ selectedPoint.pickupPointSchedules1[properties[4]]] };
                });

                let weekObj :any = {}
                selectedPoint.pickupPointSchedules = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                    let properties: any = config.ShopTimeSchedule[key].split(",");
                    let translateString = config.GetLables(intl,properties[0]);
                    let dynamicProp = translateString.trim().replace(/['"]+/g, '');
                    let openTime =  selectedPoint.pickupPointSchedules[key][0].trim().length > 1;
                    let clsoeTime =  selectedPoint.pickupPointSchedules[key][1].trim().length > 1;

                    if(openTime && clsoeTime ){
                        weekObj[dynamicProp]  =   [selectedPoint.pickupPointSchedules[key][0] , selectedPoint.pickupPointSchedules[key][1]];
                       }
                       else if(openTime){
                        weekObj[dynamicProp] = [selectedPoint.pickupPointSchedules[key][0] , ""];
                       }
                       else if(clsoeTime){
                        weekObj[dynamicProp] = ["" , selectedPoint.pickupPointSchedules[key][1]];
                       }
                       else{
                        weekObj[dynamicProp] = ["" , ""];
                       }


                });
                selectedPoint.pickupPointSchedules = weekObj;
            window.top?.postMessage(`{ "type": "crossdesk-map-relaypoint-selection", "point": ${JSON.stringify(selectedPoint)} }`, '*')
        }
        setIsUpdated(true);
        setMapCounts(mapCount);
        setZoomLevel(zoomLevel + 1);
        let origin = { lat: parseFloat(String(googleMapLocations[0].lat)), lng: parseFloat(String(googleMapLocations[0].lng)) };
        let destination = { lat: parseFloat(String(item[0].positionX)), lng: parseFloat(String(item[0].positionY)) };
        if (directionsDisplay !== null) {
            directionsDisplay.setMap(null);
        }
        let streetMapBound: (DoubleRange | undefined | number)[][] = [];
        if (isSpecificLocations) {
            streetMapBound = oldStreetMapLocations.map((ele: any) => [ele.lat, ele.lng]);
            setBounds(streetMapBound);
            setGoogleMapLocations(oldGoogleMapLocations);
            setStreetMapLocations(oldStreetMapLocations);
            setPushPins(oldPushPins);
            setRouteLocations(defaultRouteLocations);
            setViewOptions({
                center: { latitude: item[0].positionX, longitude: item[0].positionY },
                zoom: 0
            })
            return;
        }
        let googlemap: any[] = [
            {
                text: "Home",
                lat: latitude === undefined ? item[0].positionX : latitude,
                lng: longitude === undefined ? item[0].positionY : longitude,
                icon: HomeIcon
            }
        ];
        let streetmap: any[] = [
            {
                text: "Home",
                lat: latitude === undefined ? item[0].positionX : latitude,
                lng: longitude === undefined ? item[0].positionY : longitude,
                icon: HomeIcon
            }
        ];
        let bingmap: any[] = [
            {
                center: {
                    latitude: latitude === undefined ? item[0].positionX : latitude,
                    longitude: longitude === undefined ? item[0].positionY : longitude,
                },
                options: {
                    title: "Home",
                    icon: HomeIcon
                }
            }
        ];
        var iconImage = Settings.isSelectIcon == true ? 
                            Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png"
                             && Settings.MapPointerIcon.includes("http") 
                            ? `${Settings.MapPointerIcon}` : Settings.MapPointerIcon.length != 0 ? `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : "/media/avatars/map_single.png" :Settings.isSelectIcon == false 
                            ? `${config.ImageUrl}${item[serviceIndex].pickupPointType?.logoName}` :
                            item[serviceIndex].carrierService?.logoName.length != 0  ? `${config.SeriveImageUrl}${item[serviceIndex].carrierService?.logoName}` : "/media/avatars/map_single.png";
        googlemap.push({
            text: item[serviceIndex].name,
            lat: item[serviceIndex].positionX,
            lng: item[serviceIndex].positionY,
            icon: iconImage,
            pickUpPointId: item[serviceIndex].pickUpPointId
        });
        streetmap.push({
            text: item[serviceIndex].name,
            lat: item[serviceIndex].positionX,
            lng: item[serviceIndex].positionY,
            icon: iconImage,
            pickUpPointId: item[serviceIndex].pickUpPointId
        });
        bingmap.push({
            center: {
                latitude: item[serviceIndex].positionX,
                longitude: item[serviceIndex].positionY,
            },
            options: {
                title: item[serviceIndex].name,
                icon: iconImage,
                mapCount: item[serviceIndex].mapCount
            },
            pickUpPointId: item[serviceIndex].pickUpPointId
        });
        streetMapBound = streetmap.map(ele => [parseFloat(ele.lat), parseFloat(ele.lng)]);
        setBounds(streetMapBound);
        setStreetMapLocations(streetmap);
        setGoogleMapLocations(googlemap);
        setPushPins(bingmap)
        setRouteLocations(
            {
                startJourney: { latitude: streetmap[0].lat, longitude: streetmap[0].lng },
                endJourney: { latitude: parseFloat(streetmap[1].lat), longitude: parseFloat(streetmap[1].lng), pickUpPointId: streetmap[1].pickUpPointId },
                icons: { home: streetmap[0].icon, destination: streetmap[1].icon }
            }
        );
        setViewOptions({
            center: { latitude: item[0].positionX, longitude: item[0].positionY },
            zoom: 0
        })
        const distance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
        if (directionsDisplay !== null && window.google !== undefined && directionsDisplay.setMap) {
            const travelMode = distance <= 1000 ? google.maps.TravelMode.WALKING : google.maps.TravelMode.DRIVING
            directionsDisplay.setMap(myMap);
            directionsService.route({
                origin: origin,
                destination: destination,
                travelMode: travelMode
            }, function (response: any, status: google.maps.DirectionsStatus) {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setOptions({ suppressMarkers: true });
                }
            });
        }
    };

    const updateLocation = ({item, serviceIndex, showSpecific, isChecke} : IupdateLocation) => {
        setIsUpdated(true)
        setZoomLevel(zoomLevel + 1);
        //if (mapLoadder == "StreetMap") 
        if(Settings.isUseNumbers && Settings.sideMapOptionDistanceWithOnlyNumber)
            setMapCounts(item[serviceIndex].mapCount);
        else
            setMapCounts(item.mapCount);

        let origin = { lat: parseFloat(String(googleMapLocations[0].lat)), lng: parseFloat(String(googleMapLocations[0].lng)) };
        let destination = { lat: parseFloat(String(item[0].positionX)), lng: parseFloat(String(item[0].positionY)) };
        if (directionsDisplay !== null) {
            directionsDisplay.setMap(null);
        }
        let streetMapBound: (DoubleRange | undefined | number)[][] = [];
        if (active !== "" && isChecke) {
            streetMapBound = oldStreetMapLocations.map((ele: any) => [ele.lat, ele.lng]);
            setBounds(streetMapBound);
            setGoogleMapLocations(oldGoogleMapLocations);
            setStreetMapLocations(oldStreetMapLocations);
            setPushPins(oldPushPins);
            setRouteLocations(defaultRouteLocations);
            setViewOptions({
                center: { latitude: item[0].positionX, longitude: item[0].positionY },
                zoom: 0
            })
            return;
        }
        let googlemap: any[] = [
            {
                text: "Home",
                lat: latitude === undefined ? item[0].positionX : latitude,
                lng: longitude === undefined ? item[0].positionY : longitude,
                icon: HomeIcon
            }
        ];
        let streetmap: any[] = [
            {
                text: "Home",
                lat: latitude === undefined ? item[0].positionX : latitude,
                lng: longitude === undefined ? item[0].positionY : longitude,
                icon: HomeIcon
            }
        ];
        let bingmap: any[] = [
            {
                center: {
                    latitude: latitude === undefined ? item[0].positionX : latitude,
                    longitude: longitude === undefined ? item[0].positionY : longitude,
                },
                options: {
                    title: "Home",
                    icon: HomeIcon
                }
            }
        ];
        var count = showSpecific ? item.length : 0;
        var iconImage = count > 1 ? "/media/avatars/default_map.png" : Settings.isSelectIcon == true ? 
                            Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" && Settings.MapPointerIcon.includes("http") 
                            ? `${Settings.MapPointerIcon}` : Settings.MapPointerIcon.length != 0 ? `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : "/media/avatars/map_single.png" :Settings.isSelectIcon == false 
                            ? `${config.ImageUrl}${item[serviceIndex].pickupPointType?.logoName}` :
                            item[serviceIndex].carrierService?.logoName.length != 0  ? `${config.SeriveImageUrl}${item[serviceIndex].carrierService?.logoName}` : "/media/avatars/map_single.png";
        googlemap.push({
            text: item[serviceIndex].name,
            lat: item[serviceIndex].positionX,
            lng: item[serviceIndex].positionY,
            icon: iconImage,
            pickUpPointId: item[serviceIndex].pickUpPointId,
            mapCount: item.mapCount
        });
        streetmap.push({
            text: item[serviceIndex].name,
            lat: item[serviceIndex].positionX,
            lng: item[serviceIndex].positionY,
            icon: iconImage,
            pickUpPointId: item[serviceIndex].pickUpPointId,
            mapCount: item[serviceIndex].mapCount
        });
        bingmap.push({
            center: {
                latitude: item[serviceIndex].positionX,
                longitude: item[serviceIndex].positionY,
            },
            options: {
                title: item[serviceIndex].name,
                icon: iconImage,
                mapCount: Settings.isUseNumbers ? item[serviceIndex].mapCount : item.mapCount
            },
            pickUpPointId: item[serviceIndex].pickUpPointId
        });
        streetMapBound = streetmap.map(ele => [parseFloat(ele.lat), parseFloat(ele.lng)]);
        setRouteLocations(
            {
                startJourney: { latitude: streetmap[0].lat, longitude: streetmap[0].lng },
                endJourney: { latitude: parseFloat(streetmap[1].lat), longitude: parseFloat(streetmap[1].lng), pickUpPointId: streetmap[1].pickUpPointId },
                icons: { home: streetmap[0].icon, destination: streetmap[1].icon }
            }
        );
        setBounds(streetMapBound);
        setStreetMapLocations(streetmap);
        setGoogleMapLocations(googlemap);
        setPushPins(bingmap)
        setSpecificActive("");
        setViewOptions({
            center: { latitude: item[0].positionX, longitude: item[0].positionY },
            zoom: 0
        })
        const distance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
        if (directionsDisplay !== null && window.google !== undefined && directionsDisplay.setMap) {
            const travelMode = distance <= 1000 ? google.maps.TravelMode.WALKING : google.maps.TravelMode.DRIVING
            directionsDisplay.setMap(myMap);
            directionsService.route({
                origin: origin,
                destination: destination,
                travelMode: travelMode
            }, function (response: any, status: google.maps.DirectionsStatus) {
                if (status === google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setOptions({ suppressMarkers: true });
                }
            });
        }
    };

    const onSearch = ({search, isFirst, stopFindingData=false} :any) => {
        let _address: any = searchParams.get("address");
        setOptions([]);
        if(search.trim() === ""){
             return;
        }
        search = search ? search.replace("/", " ") : _address.replace("/", " ");
        const currentLocations: any = [];
        if (search.length) {
            axios({
                method: 'GET',
                url: `${config.BaseUrl}PickUpPoint/GetNearestLocation/${search}`
            }).then((response: AxiosResponse<any>) => {
                let searchdata = JSON.parse(response.data);
                setResource(searchdata.resourceSets[0].resources[0].value);
                searchdata.resourceSets[0].resources[0].value.forEach((element: any) => {
                    if (currentLocations.findIndex((x: any) => x.value === element.address.formattedAddress) === -1) {
                        currentLocations.push({
                            value: element.address.formattedAddress
                        });
                    }
                });
                if (isFirst) {
                    let coordinates = searchdata.resourceSets[0].resources?.[0].point;
                    coordinates = coordinates === undefined ?  coordinates = undefined : coordinates;
                    setLatitude(coordinates === undefined ? undefined : searchdata.resourceSets[0].resources?.[0].point.coordinates[0]);
                    setLongitude(coordinates === undefined ? undefined : searchdata.resourceSets[0].resources?.[0].point.coordinates[1]);

                    if(currentLocations.length === 0 && !stopFindingData){
                        onSearch({ search : _address, isFirst: true,  stopFindingData : true});
                    }else{
                        setAddress(currentLocations.length > 0 ? currentLocations[0].value : _address);
                        HandleZipcode({address : currentLocations.length > 0 ? currentLocations[0].value : _address});
                    }
            
                    setIsAutoCompleteOpen(false);
                }
                setOptions(currentLocations);
            });
        }
    };

    const onSelect = (selectedData: string | undefined) => {
        if (isAutoCompleteOpen) {
            setIsAutoCompleteOpen(false);
        }
        setAddress(selectedData);
        HandleZipcode({currentAddress : selectedData});
    };

    const handleUrl = (lat: any = null, long: any = null, currentAddress: any = null, parAddress : string) => {
        let service: any = searchParams.get("service");
        if(service){
            const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
            let isValidService = guidPattern.test(service);
            if(!isValidService) Settings.serviceChanged = service;
        }
        
        if (!Settings.serviceChanged) Settings.serviceChanged = undefined;
        if (currentAddress) currentAddress = currentAddress.replace("/", " ");
        Settings.isDuplicateRelayPoint = Settings.isDuplicateRelayPoint == 0 ? false : true;
        let _address   = address ? address : parAddress;

        return lat !== null && long !== null ? `${config.BaseUrl}PickUpPoint/${Settings.serviceChanged}/${Settings.showImages && false}/${Settings.isDuplicateRelayPoint}/${Settings.limitchanges}/${currentAddress ? currentAddress : address}/${lat}/${long}`
            : `${config.BaseUrl}PickUpPoint/${Settings.serviceChanged}/${Settings.showImages && false}/${Settings.isDuplicateRelayPoint}/${Settings.limitchanges}/${currentAddress ? currentAddress : _address}/${latitude}/${longitude}`
    };

    const makeListOrderLikeVerticalList = (data: any[], lat: any, long: any) => {

        data.map((item: any) => {
            let getDistanceByLatLog = getDistance({ latitude: parseFloat(String(latitude === undefined ? lat : latitude,)), longitude: parseFloat(String(longitude === undefined ? long : longitude)) }, { latitude: parseFloat(String(item.lat)), longitude: parseFloat(String(item.lng)) });
            item.distanceByLatLog = getDistanceByLatLog;
            return null;
        });

        let list: any[] = data.sort((a, b) => {
            return a.distanceByLatLog - b.distanceByLatLog;
        });
        let counterList: any[] = [];
        let number = 0;
        list.filter((e: any, num: number) => e.text !== "Home").map((item, num) => {
            if (!counterList.includes(item.text)) {
                counterList.push(item.text);
                item.mapCount = ++number;
            }
            else {
                item.mapCount = number;
            }
        });
        return list;
    };
    const makeListOrderLikeVerticalListBingMap = (data: any[], lat: any, long: any) => {

        data.map((item: any) => {
            let getDistanceByLatLog = getDistance({ latitude: parseFloat(String(latitude === undefined ? lat : latitude,)), longitude: parseFloat(String(longitude === undefined ? long : longitude)) }, { latitude: parseFloat(String(item.center.latitude)), longitude: parseFloat(String(item.center.longitude)) });
            item.distanceByLatLog = getDistanceByLatLog;
            return null;
        });

        let list: any[] = data.sort((a, b) => {
            return a.distanceByLatLog - b.distanceByLatLog;
        });
        let counterList: any[] = [];
        let number = 0;
        list.filter((e: any, num: number) => e.options.title !== "Home").map((item, num) => {
            if (!counterList.includes(item.options.title)) {
                counterList.push(item.options.title);
                item.options.mapCount = ++number;
            }
            else {
                item.options.mapCount = number;
            }
        });
        return list;
    };
    const HandleZipcode = ({lat = null,  long = null, currentAddress = null, address = ""} : any) => {
        // handleInitialHeight();
        setIsLoading(true);
        setIsLoaded(true);
        setIsUpdated(true);
        setRouteLocations(defaultRouteLocations);
        axios({
            method: 'GET',
            url: handleUrl(lat, long, currentAddress,address),
        }).then((response: AxiosResponse<any>) => {
            
            let lstLocations: any[] = response.data.locations;
            setLatitude(response.data.latitude);
            setLongitude(response.data.longtitude);
            if (lstLocations.length !== 0) {
                let bingmap: any[] = [
                    {
                        center: {
                            latitude: response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude,
                            longitude: response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude,
                        },
                        options: {
                            title: "Home",
                            icon: HomeIcon,
                            mapCount: null
                        }
                    }
                ];
                setSelectedLat(lat);
                let googlemap: any[] = [
                    {
                        text: "Home",
                        lat: response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude,
                        lng: response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude,
                        icon: HomeIcon,
                        mapCount: null
                    }
                ];
                let streetmap: any[] = [
                    {
                        text: "Home",
                        lat: response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude,
                        lng: response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude,
                        icon: HomeIcon,
                        mapCount: null
                    }
                ];
                let streetMapBound: (DoubleRange | undefined)[][] = [];

                lstLocations.forEach((element, num) => {
                    let count = element.length;
                    element.map((item: any) => {
                        if (item.carrierService) {
                            var iconImage = count > 1 ? "/media/avatars/default_map.png" : Settings.isSelectIcon == true ? 
                            Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" && Settings.MapPointerIcon.includes("http") 
                            ? `${Settings.MapPointerIcon}` : Settings.MapPointerIcon.length != 0 ? `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : "/media/avatars/map_single.png" :Settings.isSelectIcon == false 
                            ? `${config.ImageUrl}${item.pickupPointType?.logoName}` :
                            item.carrierService?.logoName.length != 0  ? `${config.SeriveImageUrl}${item.carrierService?.logoName}` : "/media/avatars/map_single.png";
                          
                            item.name = config.toTitleCase(item.name);
                            item.address1 = config.toTitleCase(item.address1);
                            item.address2 = config.toTitleCase(item.address2);
                            item.postalCode = config.toTitleCase(item.postalCode);
                            bingmap.push({
                                center: {
                                    latitude: item.positionX,
                                    longitude: item.positionY,
                                },
                                options: {
                                    title: item.name,
                                    icon: iconImage,
                                    carrierService: item.carrierService,
                                    pickupPointType: item.pickupPointType,
                                    index: "bingmap" + num
                                },
                                pickUpPointId: item.pickUpPointId
                            });
                            googlemap.push({
                                text: item.name,
                                lat: item.positionX,
                                lng: item.positionY,
                                icon: iconImage,
                                pickUpPointId: item.pickUpPointId,
                                carrierService: item.carrierService,
                                pickupPointType: item.pickupPointType,
                                index: "googlemap" + num,
                                distanceByLatLog: element[0].distanceByLatLog
                            })
                            streetmap.push({
                                text: item.name,
                                lat: parseFloat(String(item.positionX)),
                                lng: parseFloat(String(item.positionY)),
                                icon: iconImage,
                                pickUpPointId: item.pickUpPointId,
                                carrierService: item.carrierService,
                                pickupPointType: item.pickupPointType,
                                index: "streetmap" + num,
                                distanceByLatLog: element[0].distanceByLatLog
                            });
                        }
                        return null;
                    })
                });
                streetMapBound = streetmap.map(item => [item.lat, item.lng])
                setBounds(streetMapBound);
                setOldGoogleMapLocations(makeListOrderLikeVerticalList(googlemap, lat, long));
                setOldStreetMapLocations(makeListOrderLikeVerticalList(streetmap, lat, long));
                setGoogleMapLocations(makeListOrderLikeVerticalList(googlemap, lat, long));
                setStreetMapLocations(makeListOrderLikeVerticalList(streetmap, lat, long));
                setPushPins(makeListOrderLikeVerticalListBingMap(bingmap, lat, long));
                setOldPushPins(makeListOrderLikeVerticalListBingMap(bingmap, lat, long));
                setViewOptions({
                    center: { latitude: response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude, longitude: response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude, },
                    zoom: 0
                });
                setGoogleDefaultLocations({
                    address: lstLocations[0].name,
                    lat: parseFloat(String(response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude)),
                    lng: parseFloat(String(response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude))
                });
                setStreetDefaultLocations({
                    lat: response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude,
                    lng: response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude
                });
            }
            lstLocations.map((item) => {
                let getDistanceByLatLog = getDistance({ latitude: parseFloat(String(response.data.latitude ? response.data.latitude : latitude === undefined ? lat : latitude)), longitude: parseFloat(String(response.data.longtitude ? response.data.longtitude : longitude === undefined ? long : longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
                item[0].distanceByLatLog = getDistanceByLatLog;
                return null;
            });
            let sortedLocationslst = lstLocations.sort((a, b) => {
                return a[0].distanceByLatLog - b[0].distanceByLatLog;
            });

            setLocations(sortedLocationslst);
            setIsLoading(false)
            setIsLoaded(false)
        });
    }
    const handleMainBox = (item: any) => {
        if (item && item.length > 0) {

            if (active === item[0].pickUpPointId) {
                setActive("");
                updateLocation({item :item, serviceIndex : 0, showSpecific : true, isChecke : true});
            }
            else {
                setActive(item[0].pickUpPointId);
                updateLocation({item : item , serviceIndex : 0, showSpecific : true, isChecke : false});
                let selectedPoint = {...item[0]};
                selectedPoint.pickupPointSchedules1 = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                    let properties: any = config.ShopTimeSchedule[key].split(",");
                    const newKey = config.GetLables(intl, properties[0]);
                    return { [newKey]: [selectedPoint.pickupPointSchedules1[properties[1]] + " - " + selectedPoint.pickupPointSchedules1[properties[2]] , selectedPoint.pickupPointSchedules1[properties[3]] +" - "+ selectedPoint.pickupPointSchedules1[properties[4]]] };
                });

                let weekObj :any = {}
                selectedPoint.pickupPointSchedules = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                    let properties: any = config.ShopTimeSchedule[key].split(",");
                    let translateString = config.GetLables(intl,properties[0]);
                    let dynamicProp = translateString.trim().replace(/['"]+/g, '');
                    let openTime =  selectedPoint.pickupPointSchedules[key][0].trim().length > 1;
                    let clsoeTime =  selectedPoint.pickupPointSchedules[key][1].trim().length > 1;

                    if(openTime && clsoeTime ){
                        weekObj[dynamicProp]  =   [selectedPoint.pickupPointSchedules[key][0] , selectedPoint.pickupPointSchedules[key][1]];
                       }
                       else if(openTime){
                        weekObj[dynamicProp] = [selectedPoint.pickupPointSchedules[key][0] , ""];
                       }
                       else if(clsoeTime){
                        weekObj[dynamicProp] = ["" , selectedPoint.pickupPointSchedules[key][1]];
                       }
                       else{
                        weekObj[dynamicProp] = ["" , ""];
                       }

                });
                selectedPoint.pickupPointSchedules = weekObj;
                window.top?.postMessage(`{ "type": "crossdesk-map-relaypoint-selection", "point": ${JSON.stringify(selectedPoint)} }`, '*')
            }
            setIsChecked("");
        }
    };
    const handleCheckBox = (item: any, element: any, num: number, isBingMapChecked: any = null, mapCount: number | null = null) => {
        setMapCounts(mapCount);
        let selectedPoint = {...item[num]};
        selectedPoint.pickupPointSchedules1 = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
            let properties: any = config.ShopTimeSchedule[key].split(",");
            const newKey = config.GetLables(intl, properties[0]);
            return { [newKey]: [selectedPoint.pickupPointSchedules1[properties[1]] + " - " + selectedPoint.pickupPointSchedules1[properties[2]] , selectedPoint.pickupPointSchedules1[properties[3]] +" - "+ selectedPoint.pickupPointSchedules1[properties[4]]] };
        });

        let weekObj :any = {}
        selectedPoint.pickupPointSchedules = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
            let properties: any = config.ShopTimeSchedule[key].split(",");
            let translateString = config.GetLables(intl,properties[0]);
            let dynamicProp = translateString.trim().replace(/['"]+/g, '');
            
            let openTime =  selectedPoint.pickupPointSchedules[key][0].trim().length > 1;
            let clsoeTime =  selectedPoint.pickupPointSchedules[key][1].trim().length > 1;

            if(openTime && clsoeTime ){
                weekObj[dynamicProp]  =   [selectedPoint.pickupPointSchedules[key][0] , selectedPoint.pickupPointSchedules[key][1]];
               }
               else if(openTime){
                weekObj[dynamicProp] = [selectedPoint.pickupPointSchedules[key][0] , ""];
               }
               else if(clsoeTime){
                weekObj[dynamicProp] = ["" , selectedPoint.pickupPointSchedules[key][1]];
               }
               else{
                weekObj[dynamicProp] = ["" , ""];
               }


        });
        selectedPoint.pickupPointSchedules = weekObj;

        setSelectedItem(selectedPoint);
    if(mapLoadder === "GoogleMapReact"){
        GoogleMapSetTooltipBox();
    }else if(mapLoadder === "BingMap"){
        setTimeout(()=>{
            BingMapSetTooltipBox();
        },2000)
    }


        if (mapLoadder !== "StreetMap") {
            if (!servicesHolder.some((ele) => (ele.pickUpPointId === item[num].pickUpPointId)))
                SetservicesHolder((state) => ([...state, item[num]]));
        }
        if (isBingMapChecked) {
            updateLocation({item : item, serviceIndex : num, showSpecific : isBingMapChecked === element.pickUpPointId + element.carrierServiceId, isChecke : false});
            if (isBingMapChecked !== element.pickUpPointId + element.carrierServiceId) {
                setIsChecked(element.pickUpPointId + element.carrierServiceId);
                setActive(item[0].pickUpPointId);
            } else {
                setIsChecked("");
                setActive(item[0].pickUpPointId);
            }
        }
        else {
            updateLocation({item:item , serviceIndex : num, showSpecific : isChecked === element.pickUpPointId + element.carrierServiceId, isChecke : false});
            if (isChecked !== element.pickUpPointId + element.carrierServiceId) {
                setIsChecked(element.pickUpPointId + element.carrierServiceId);
                setActive(item[0].pickUpPointId);

                // window.top?.postMessage(`{ "type": "crossdesk-map-relaypoint-selection", "point": ${JSON.stringify(item[num])} }`, '*')

            } else {
                setIsChecked("");
                setActive(item[0].pickUpPointId);
                handleMainBox(item);
            }
        };
    }

    const isDisableSelectButton = (item: any, isChecked: string, active: string | undefined) => {
        let result: boolean = true;
        if (active === item[0].pickUpPointId && item.length === 1) {
            result = false;
        } else {
            item.map((element: any, num: number) => {
                if (isChecked === element.pickUpPointId + element.carrierServiceId) {
                    result = false
                }
            });
        }
        return result;
    };
    const getSideLocation = (locations: any[], pickUpPointId: string | undefined) => {
        let obj: any;
        locations.map((item: any[], num: number) => {
            item.map((ele: any, index: number) => {
                if (ele.pickUpPointId === pickUpPointId) {
                    obj = ele;
                }
            });
        });
        return obj;
    }

    const handlehHideMapHeader = () => {
        let count: number = 0;
        for (const key in Settings.selectMap) {
            if (Settings.selectMap[key] !== "") {
                ++count;
            }
        }
        return count > 1;
    };
    return (
        <>
            <div className="dynamic-theme">
           { !location.pathname.includes("thankyou") && searchParams.get("ispopup") != "true" ? 
           <div className="row g-5 g-xl-10 m-0">
           <div className="col-lg-12 mt-0">
       <div className="card mb-1">
           <div className="card-body py-md-3 search-bar-padding">
               <div className="row">
               
                       <div className="col-md-10 col-lg-10 mb-2 mb-md-0">
                       <AutoComplete
                           options={options}
                           onSelect={onSelect}
                           onSearch={(val)=>{  onSearch({search : val, isFirst : false}); }}
                           id="locationSearchBar"
                           placeholder={<GetLable label="SearchLocation" />}
                           value={address}
                           open={isAutoCompleteOpen}
                           onChange={(value) => {
                               setAddress(value);
                               setIsAutoCompleteOpen(true);
                           }}
                       />
                       {/* {userDeniedGeolocation ? <span className="text-danger">  <GetLable label="PleaseEnterLocationToGetnearByRelayPoints" /></span> : null} */}
                       {/* {!address ? <span className="text-danger">  <GetLable label="PleaseEnterLocationToGetnearByRelayPoints" /></span> : null} */}
                   </div>
                   <div className="col-md-2 col-lg-2">
                       <button id="serachLocation" onClick={() => {
                           if (isAutoCompleteOpen) {
                               setIsAutoCompleteOpen(false);
                           }
                           if(address){
                               HandleZipcode({});
                           }
                       }} className="btn btn-sm fw-bold btn-primary w-100 text-uppercase d-flex justify-content-center"><span><GetLable label="Recherche" />{' '}</span> {isLoading ? <span className='spinner-border spinner-border-sm align-middle ms-2 custom-mt-2'></span> : null}</button>
                   </div>
               </div>
           </div>
       </div>
           </div>
         </div>
            : 
           null 
           }
                <div className="row g-5 g-xl-10 m-0">
                    <div className="col-lg-12 mt-0">
                        {!locations ? null :
                            <div className="card card-flush h-100">
                                {
                                    handlehHideMapHeader() && !location.pathname.includes("thankyou") && searchParams.get("ispopup") != "true" &&
                                    <div className="card-header map-container-header-padding">
                                        <h3 className="card-title align-items-start flex-column">
                                            {/* <span className="card-label fw-bold text-dark">{<GetLable label="FindLocation" />} </span> */}
                                        </h3>
                                        {Settings.displayMap && <div className="card-toolbar">
                                            <ul className="nav nav-pills nav-pills-custom">
                                                {Settings.selectMap.openStreetMap === "openStreetMap" && <li className="nav-item me-3"
                                                    onClick={() => { setMapLoadder("StreetMap"); }}>
                                                    <a className="nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pt-3 pb-4 active" id="kt_stats_widget_16_tab_link_1" data-bs-toggle="pill" href="#kt_stats_widget_16_tab_1">
                                                        <span
                                                            className="nav-text text-gray-800 fw-bold fs-6 lh-1"><GetLable label="OpenStreetMap" /></span>
                                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-3px bg-primary"></span>
                                                    </a>
                                                </li>}
                                                {Settings.selectMap.googleMap === "googleMap" && <li className="nav-item"
                                                    onClick={() => { setMapLoadder("GoogleMapReact"); }}
                                                >
                                                    <a className={Settings.selectMap.openStreetMap === '' ? "nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pt-3 pb-4 active" : "nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pt-3 pb-4"}
                                                        id="kt_stats_widget_16_tab_link_2"
                                                        data-bs-toggle="pill"
                                                        href="#kt_stats_widget_16_tab_2"
                                                    >
                                                        <span
                                                            className="nav-text text-gray-800 fw-bold fs-6 lh-1"><GetLable label="GoogleMap" /></span>
                                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-3px bg-primary"></span>
                                                    </a>
                                                </li>}
                                                {Settings.selectMap.bingMap === "bingMap" && <li className="nav-item"
                                                    onClick={() => {
                                                        setMapLoadder("BingMap");
                                                    }}
                                                >
                                                    <a className={Settings.selectMap.openStreetMap === '' && Settings.selectMap.googleMap === '' ? "nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pt-3 pb-4 active" : "nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden pt-3 pb-4"}
                                                        id="kt_stats_widget_16_tab_link_3"
                                                        data-bs-toggle="pill"
                                                        href="#kt_stats_widget_16_tab_3"
                                                    >
                                                        <span className="nav-text text-gray-800 fw-bold fs-6 lh-1"><GetLable label="BingMap" /></span>
                                                        <span className="bullet-custom position-absolute bottom-0 w-100 h-3px bg-primary"></span>
                                                    </a>
                                                </li>}
                                            </ul>
                                        </div>}
                                    </div>
                                }
                                <span className={`text-center ${isLoaded ? "" : "d-none"}`}>
                                    <img style={{ height: `calc(100vh)`, width: "auto" }} alt="Error" src="/media/gif/Map-loader.gif" />
                                    {/* <img style={{ height: `calc(100vh - 304px)`, width: "auto" }} alt="Error" src="/media/gif/Map-loader.gif" /> */}
                                </span>
                                <div className={`card ${isLoaded ? "d-none" : ""}`}>
                                    <div className="card-body pt-0 map-container-padding">
                                        <div className="row">
                                            {
                                                !location.pathname.includes("thankyou") && searchParams.get("ispopup") != "true" && Settings.topManuChanged ? <div className={`col-lg-12 order-${Settings.MobileLook.findIndex((x: any) => x.label === "Horizontal Address Section") + 1} order-md-1  ${ !location.pathname.includes("thankyou") ?  `mb-3` : ``  }`}>
                                                {
                                                    <div className={`p-0 pt-0 pb-0 mb-0 d-flex align-items-start h-100 scrollContainerRight`} id="scrollContainerRight" style={{ overflowX: "auto", scrollBehavior: "smooth" }}>
                                                        {
                                                            locations.map((item, num) => {
                                                                item.getDistance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
                                                                let distanceType = "";
                                                                if (Settings.distanceChanged) {
                                                                    if (item.getDistance <= 1000) {
                                                                        distanceType = `${Math.round(item.getDistance).toFixed(1)} ${config.GetLables(intl, "Meter")}`;
                                                                    } else {
                                                                        distanceType = `${(item.getDistance / 1000).toFixed(1)}  ${config.GetLables(intl, "KM")}`;
                                                                    }
                                                                } else {
                                                                    distanceType = `${(item.getDistance * 0.000621371192).toFixed(4)} ${config.GetLables(intl, "Miles")}`;
                                                                }
                                                                return item.map((element: any, index: number) => {
                                                                    element.mapCount = ++topHorizontalListCount;
                                                                    if (element.carrierService) {
                                                                        return (
                                                                            <div id={`${element.pickUpPointId}horizontalList`} horizontal-list-data={JSON.stringify(element)} key={index} onClick={() => {
                                                                                setActive("");
                                                                                setIsChecked("");
                                                                                if (specificActive === `${element.pickUpPointId}(${num})`) {
                                                                                    if (!Settings.topMapOptionDistanceWithOnlyNumber) {
                                                                                        setSpecificActive("")
                                                                                    }
                                                                                }
                                                                                else {
                                                                                    setSpecificActive(`${element.pickUpPointId}(${num})`);
                                                                                }
                                                                                if (Settings.topMapOptionDistanceWithOnlyNumber) {
                                                                                    if (!(specificActive === `${element.pickUpPointId}(${num})`)) {
                                                                                        handleSpecificLocations(item, index, specificActive === `${element.pickUpPointId}(${num})`, element.mapCount);
                                                                                    }
                                                                                } else {
                                                                                    handleSpecificLocations(item, index, specificActive === `${element.pickUpPointId}(${num})`, element.mapCount);
                                                                                }
                                                                            }}
                                                                                className={((!Settings.topMapOptionDistanceWithOnlyNumber || specificActive) ? specificActive === `${element.pickUpPointId}(${num})` : `${locations[0][0].pickUpPointId}(0)` === `${element.pickUpPointId}(${num})`) ? "btn btn-outline btn-sm border-primary bg-light-primary me-2 px-2 text-start top-location h-100" : "btn btn-outline btn-sm btn-outline-dashed btn-outline-default me-2 mb-2 px-2 text-start top-location h-100"}
                                                                                style={{ borderRadius: "0.25rem" }}>

                                                                                {
                                                                                    Settings.topMapOptionDistanceWithOnlyNumber ?
                                                                                        <>
                                                                                            <div className="text-center mb-3 mt-3 d-flex justify-content-center">
                                                                                                <p className="text-center location-counter"> {element.mapCount}</p>
                                                                                            </div>
                                                                                            <span className="fw-semibold text-center text-white-600 fs-6 d-block text-star text-gray-600 mb-2"><i className={item[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800" : "fa fa-car fa-lg text-gray-800"}></i>{distanceType}</span>
                                                                                        </>
                                                                                        :
                                                                                        <div className="d-flex justify-content-between flex-column h-100">
                                                                                            <div className="d-flex justify-content-between align-items-start w-100 h-100">
                                                                                                <div className="me-2 d-flex flex-column justify-content-between h-100">
                                                                                                    <div className="d-flex justify-content-between">
                                                                                                        <div>
                                                                                                            <span className={specificActive === `${element.pickUpPointId}(${num})` ? "text-gray-800 fw-bold mb-1 fs-6 text-wrap text-ellipsis" : "text-gray-800 fw-bold mb-0 fs-6 text-start text-wrap text-ellipsis"}>{item[0].name}</span>
                                                                                                            <span className={active === item[0].pickUpPointId ? "fw-semibold text-primary fs-6 text-start active text-ellipsis" : "fw-semibold text-primary fs-6 mb-2  text-start text-ellipsis"}>{`${item[0].address1} ${item[0].address2} ${item[0].city} ${item[0].postalCode}`}</span>
                                                                                                        </div>
                                                                                                        {
                                                                                                            Settings.isLogoShow ?
                                                                                                                <>
                                                                                                                    <div className="symbol symbol-circle symbol-30px">
                                                                                                                        <span><div className="symbol-label">
                                                                                                                            {
                                                                                                                                Settings.isSelectIcon == null ?
                                                                                                                                <img src={element.carrierService && element.carrierService.logoName.length > 0 ? `${config.SeriveImageUrl}${element.carrierService.logoName}` : `/media/avatars/default_map.png`} alt="error" className=" w-100  rounded-circle"></img> :
                                                                                                                                Settings.isSelectIcon == true ?
                                                                                                                                <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="error" className=" w-100  rounded-circle"></img>:
                                                                                                                                <img src={element.pickupPointType && element.pickupPointType.logoName.length > 0 ? `${config.ImageUrl}${element.pickupPointType.logoName}` : `/media/avatars/default_map.png`} alt="error" className=" w-100  rounded-circle"></img>
                                                                                                                            }
                                                                                                                            
                                                                                                                        </div></span>
                                                                                                                    </div>


                                                                                                                </>
                                                                                                                : null
                                                                                                        }

                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className="position-relative mb-2">
                                               
                                                                                                            <div className={`card border-0 time-box ${isShowTimeBox.isShow && isShowTimeBox.timeBoxId === `${element.pickUpPointId}timeBox${num}` ? "d-block" : "d-none"}`}>
                                                                                                                <div className="card-body p-2">
                                                                                                                    {
                                                                                                                        item[0].pickupPointSchedules1 ?
                                                                                                                            <table className="table table-striped horizontal-list-time-box-table">
                                                                                                                                <tbody>
                                                                                                                                    {Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                                                                                                                                        let properties: any = config.ShopTimeSchedule[key].split(",");
                                                                                                                                        return <tr key={i}>
                                                                                                                                            <th scope="row"> <GetLable label={properties[0]} /></th>
                                                                                                                                            <td>{item[0].pickupPointSchedules1[properties[1]]} - {item[0].pickupPointSchedules1[properties[2]]}</td>
                                                                                                                                            <td>{item[0].pickupPointSchedules1[properties[3]]} - {item[0].pickupPointSchedules1[properties[4]]}</td>
                                                                                                                                        </tr>
                                                                                                                                    })}
                                                                                                                                </tbody>
                                                                                                                            </table>
                                                                                                                            :
                                                                                                                            <div className="card-body p-2 h-100"><div className="h-100 d-flex align-items-center text-center w-100"><div className="w-100"> Schedules are not <br />available  </div></div></div>
                                                                                                                    }
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div className="fw-semibold text-white-600 fs-6 d-block text-star text-gray-600 mb-2 d-flex">
                                                                                                            {
                                                                                                                Settings.isTopHours &&
                                                                                                                <span className="d-inline-block" onTouchStart={()=>{ setIsTimeBox({ isShow: true, timeBoxId: `${element.pickUpPointId}timeBox${num}` }); }} onMouseEnter={() => { setIsTimeBox({ isShow: true, timeBoxId: `${element.pickUpPointId}timeBox${num}` }); }} onTouchEnd={()=>{ setIsTimeBox({ isShow: false, timeBoxId: "" }); }} onMouseOut={() => { setIsTimeBox({ isShow: false, timeBoxId: "" }); }} >
                                                                                                                    <i className="fa-solid fa-circle-info line-height-normal fa-lg"></i>
                                                                                                                </span>
                                                                                                            }
                                                                                                                <i className={item[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800 mt-1" : "fa fa-car fa-lg text-gray-800 mt-1"}></i>
                                                                                                                <span>{distanceType}</span>
                                                                                                            </div>
                                                                                                            {
                                                                                                                Settings.isUseNumbers &&
                                                                                                                <div className="text-center">
                                                                                                                    <p className="text-center location-counter mb-2"> {element.mapCount}  </p>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <span className="fw-semibold fs-6 text-start btn-primary rounded text-white px-2 text-ellipsis custom-min-height">
                                                                                                {/* {element.carrierService?.label} */}
                                                                                                {element.carrierService?.name}
                                                                                                </span>
                                                                                        </div>
                                                                                }


                                                                            </div>
                                                                        )
                                                                    }
                                                                    return null;
                                                                });

                                                            })
                                                        }
                                                    </div>
                                                }
                                            </div> : null
                                            }
                                            
                                            {Settings.displayMap && <div className={Settings.orderChanged === null ? `col-sm-12 locMenuHide order-1` : `${!location.pathname.includes("thankyou") && searchParams.get("ispopup") != "true" ? `col-sm-9`  : `col-sm-12` } order-${Settings.MobileLook.findIndex((x: any) => x.label === "Map") + 1} ${Settings.orderChanged === false ? "order-md-1" : "order-md-2"} `}>
                                                <div className="tab-content">
                                                    {
                                                        Settings.selectMap.openStreetMap === "openStreetMap" ? <div className="tab-pane fade show active" id="kt_stats_widget_16_tab_1">
                                                            <div className="row">
                                                                {
                                                                    Settings.isDivideMapsIntotwoParts &&
                                                                    <SideLocations
                                                                        location={locations[0] && getSideLocation(locations, routeLocations.endJourney.pickUpPointId ? routeLocations.endJourney.pickUpPointId : locations[0][0].pickUpPointId)}
                                                                        Settings={Settings}
                                                                        googleMapLocations={googleMapLocations}
                                                                        latitude={latitude}
                                                                        longitude={longitude}
                                                                    />
                                                                }

                                                                <div className={`${Settings.isDivideMapsIntotwoParts ? "col-xl-6" : "col-lg-12"} p-0`}>
                                                                    <div className={`test-sonu-street table-responsive ${dynamicHeight  ? "map-container" : "" }`} style={{ height: `${ (typeof dynamicHeight === "number" ) && dynamicHeight  ?  dynamicHeight  : 450 }px`, width: '100%' }} id='map-container'>
                                                                        {
                                                                            (mapLoadder === "StreetMap") &&
                                                                            <MapContainer
                                                                                center={streetDefaultLocations}
                                                                                zoom={17}
                                                                                scrollWheelZoom={true}
                                                                                style={{ height: "100%" }}
                                                                                attributionControl={false}
                                                                            >
                                                                                <TileLayer
                                                                                    attribution="&copy; <a href='http://osm.org/copyright'>OpenStreetMap</a> contributors"
                                                                                    url="https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}{r}.png"
                                                                                />
                                                                                {
                                                                                    routeLocations.endJourney.latitude ?
                                                                                        <RoutingMachine
                                                                                            key={key}
                                                                                            routeLocations={routeLocations}
                                                                                            markerColour={Settings.iconChanged}
                                                                                            locations={locations}
                                                                                            handleCheckBox={(item: any, element: any, num: number) => { handleCheckBox(item, element, num) }}
                                                                                            isChecked={isChecked}
                                                                                            settings={Settings}
                                                                                            handleMarker={() => {
                                                                                                let selectedServices: any[] = [];
                                                                                                locations.map((i: any) => {
                                                                                                    i.filter((ele: any) => {
                                                                                                        if (ele.pickUpPointId === routeLocations.endJourney.pickUpPointId) {
                                                                                                            selectedServices = i;
                                                                                                        };
                                                                                                        return null;
                                                                                                    });
                                                                                                    return null;
                                                                                                });
                                                                                                handleMainBox(selectedServices);
                                                                                            }}
                                                                                            distanceType={distanceType}
                                                                                            routingDistance={Settings.distanceChanged}
                                                                                            specificActive={specificActive}
                                                                                            intl={intl}
                                                                                            mapCounts={mapCounts}
                                                                                        /> :
                                                                                        streetMapLocations.map((item: any, index) => {
                                                                                            return (
                                                                                                <Marker
                                                                                                    eventHandlers={{
                                                                                                        click: () => {
                                                                                                            let obj: any[] = [];
                                                                                                            locations.map((i) => {
                                                                                                                i.filter((ele: any) => {
                                                                                                                    if (ele.pickUpPointId === item.pickUpPointId) {
                                                                                                                        obj = i;
                                                                                                                    };
                                                                                                                    return null;
                                                                                                                });
                                                                                                                return null;
                                                                                                            });
                                                                                                            handleMainBox(obj);
                                                                                                            setMapCounts(item.mapCount);
                                                                                                            if (obj.length > 0) {
                                                                                                                let ele = document.getElementById(obj[0].pickUpPointId);
                                                                                                                ele?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                                                                                                            }
                                                                                                        }
                                                                                                    }}
                                                                                                    position={[
                                                                                                        item.lat,
                                                                                                        item.lng
                                                                                                    ]}
                                                                                                    key={index}
                                                                                                    icon={
                                                                                                        item.icon.includes("default_map") ?
                                                                                                            L.divIcon({
                                                                                                                className: 'custom icon',
                                                                                                                html:

                                                                                                                    Settings.isUseNumbers ? `<div class="single-location-marker-with-just-number">
                                                                                                  <div style="transform: rotate(45deg);">
                                                                                                      <span class="d-flex justify-content-center align-items-center text-white">
                                                                                                      <div class="text-center">
                                                                                                      <p class="text-center location-counter-map">${item.mapCount}</p>
                                                                                                      </div>
                                                                                                      </span>
                                                                                                  </div>`
                                                                                                                        : 
                                                                                                `<div id="location-marker${index}" data-location-marker-multiservices='${JSON.stringify(item)}' class="single-location-marker">
                                                                                                  <div style="transform: rotate(45deg);">
                                                                                                      <span class="d-flex justify-content-center align-items-center">
                                                                                                      ${   
                                                                                                        Settings.isSelectIcon == null ?  `<img src='${item.carrierService && item.carrierService.logoName.length > 0 ? config.SeriveImageUrl + item.carrierService.logoName : '/media/avatars/default_map.png'}' alt="error" class="map-thumbnail ${item.index}"></img>`  :
                                                                                                        Settings.isSelectIcon === true ?
                                                                                                        `<img src='${Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`}' alt="error" class="map-thumbnail ${item.index}"></img>`:
                                                                                                        Settings.isSelectIcon === false ? `<img src='${item.pickupPointType && item.pickupPointType.logoName.length > 0 ? config.ImageUrl + item.pickupPointType.logoName : '/media/avatars/default_map.png'}' alt="error" class="map-thumbnail ${item.index}"></img>` :
                                                                                                        `<img src='${item.carrierService && item.carrierService.logoName.length > 0 ? config.SeriveImageUrl + item.carrierService.logoName : '/media/avatars/default_map.png'}' alt="error" class="map-thumbnail ${item.index}"></img>`
                                                                                                      }  
                                                                                                      </span>
                                                                                                  </div>
                                                                                                  </div> `,
                                                                                                            })
                                                                                                            : L.divIcon({
                                                                                                                className: 'custom icon',
                                                                                                                html: Settings.isUseNumbers && item.text !== "Home" ? `<div class="single-location-marker-with-just-number">
                                                                                                                <div style="transform: rotate(45deg);">
                                                                                                                    <span class="d-flex justify-content-center align-items-center text-white">
                                                                                                                    <div class="text-center">
                                                                                                                    <p class="text-center location-counter-map">${item.mapCount}</p>
                                                                                                                    </div>
                                                                                                                    </span>
                                                                                                                </div>` :
                                                                                                                    `
                                                                                                  <div id="single-location-marker${index}" data-single-location-marker='${JSON.stringify(item)}' class="single-location-marker">
                                                                                                  <div style="transform: rotate(45deg);">
                                                                                                      <span class="d-flex justify-content-center align-items-center">
                                                                                                      <img src="${item.icon}" class="map-thumbnail">
                                                                                                      </span>
                                                                                                  </div>
                                                                                                  </div>`,
                                                                                                            })
                                                                                                    }
                                                                                                >
                                                                                                    <Tooltip direction='top' offset={[12, -3]} opacity={1}>
                                                                                                        <span>{item.text}</span>
                                                                                                    </Tooltip>
                                                                                                </Marker>
                                                                                            )
                                                                                        })
                                                                                }
                                                                                <Recenter lat={streetDefaultLocations.lat} lng={streetDefaultLocations.lng} />
                                                                            </MapContainer>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ''
                                                    }
                                                    {
                                                        Settings.selectMap.googleMap === "googleMap" ? <div className={Settings.selectMap.openStreetMap === '' ? "tab-pane fade show active" : "tab-pane fade"} id="kt_stats_widget_16_tab_2">
                                                            <div className="row">

                                                                {
                                                                    Settings.isDivideMapsIntotwoParts &&
                                                                    <SideLocations
                                                                        location={locations[0] && getSideLocation(locations, routeLocations.endJourney.pickUpPointId ? routeLocations.endJourney.pickUpPointId : locations[0][0].pickUpPointId)}
                                                                        Settings={Settings}
                                                                        googleMapLocations={googleMapLocations}
                                                                    />
                                                                }

                                                                <div className={`${Settings.isDivideMapsIntotwoParts ? "col-xl-6" : "col-lg-12"}`}>
                                                                    <div className={`test-sonu-google table-responsive ${dynamicHeight   ? "map-container" : "" }`} id="googleMapContainer" style={{ height: `${(typeof dynamicHeight === "number" ) && dynamicHeight ?  dynamicHeight  : 450 }px`, width: '100%' }}>
                                                                        {
                                                                            (mapLoadder === "GoogleMapReact") &&
                                                                            (
                                                                                <GoogleMapReact
                                                                                    yesIWantToUseGoogleMapApiInternals={true}
                                                                                    options={{ styles: [{ "featureType": "administrative", "elementType": "geometry", "stylers": [{ "visibility": "off" }] }, { "featureType": "poi", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "stylers": [{ "visibility": "off" }] }] }}
                                                                                    bootstrapURLKeys={{ key: Settings.googleMapKey }}
                                                                                    center={googleDefaultLocations}
                                                                                    zoom={zoomLevel}
                                                                                    defaultCenter={{ lat: 14.913254, lng: 50.43094 }}
                                                                                    onGoogleApiLoaded={({ map, maps }) => handleApiLoaded( map, maps)}
                                                                                    onChange={({ bounds }) => handleOnChange(bounds)}
                                                                                >
                                                                                    {
                                                                                        googleMapLocations.map((item: any, index: number) => {
                                                                                            let multiServiceMarker1: any[] = [];
                                                                                            locations.map((i) => {
                                                                                                i.filter((ele: any) => {
                                                                                                    if (ele.pickUpPointId === item.pickUpPointId) {
                                                                                                        multiServiceMarker1 = i;
                                                                                                    };
                                                                                                    return null;
                                                                                                });
                                                                                                return null;
                                                                                            });
                                                                                            return (
                                                                                                // <LocationPin
                                                                                                //     locations={locations}
                                                                                                //     pickUpPointId={item.pickUpPointId}
                                                                                                //     specificLocation={multiServiceMarker1}
                                                                                                //     lat={item.lat}
                                                                                                //     lng={item.lng}
                                                                                                //     text={item.text}
                                                                                                //     key={index}
                                                                                                //     selectedLat={selectedLat}
                                                                                                //     icon={item.icon}
                                                                                                //     handleMainBox={handleMainBox}
                                                                                                //     Settings={Settings}
                                                                                                //     locationsLenth={googleMapLocations.length}
                                                                                                //     handleCheckBox={handleCheckBox}
                                                                                                //     isChecked={isChecked}
                                                                                                //     distanceType={distanceType}
                                                                                                //     mapCounts={mapCounts}
                                                                                                //     count={item.mapCount}
                                                                                                //     index={item.index}
                                                                                                //     carrierService={item.carrierService}
                                                                                                // />
                                                                                                <GoogleLocationPin 
                                                                                                    locations={locations}
                                                                                                    pickUpPointId={item.pickUpPointId}
                                                                                                    specificLocation={multiServiceMarker1}
                                                                                                    lat={item.lat}
                                                                                                    lng={item.lng}
                                                                                                    text={item.text}
                                                                                                    key={index}
                                                                                                    selectedLat={selectedLat}
                                                                                                    icon={item.icon}
                                                                                                    handleMainBox={handleMainBox}
                                                                                                    Settings={Settings}
                                                                                                    locationsLenth={googleMapLocations.length}
                                                                                                    handleCheckBox={handleCheckBox}
                                                                                                    isChecked={isChecked}
                                                                                                    distanceType={distanceType}
                                                                                                    mapCounts={mapCounts}
                                                                                                    count={item.mapCount}
                                                                                                    index={item.index}
                                                                                                    carrierService={item.carrierService}
                                                                                                />
                                                                                            )
                                                                                        })
                                                                                    }
                                                                                </GoogleMapReact>
                                                                            )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> : ''
                                                    }
                                                    <div className={Settings.selectMap.openStreetMap === '' && Settings.selectMap.googleMap === '' ? "tab-pane fade show active" : "tab-pane fade"} id="kt_stats_widget_16_tab_3">
                                                        <div className="row">
                                                            {
                                                                Settings.isDivideMapsIntotwoParts &&
                                                                <SideLocations
                                                                    location={locations[0] && getSideLocation(locations, routeLocations.endJourney.pickUpPointId ? routeLocations.endJourney.pickUpPointId : locations[0][0].pickUpPointId)}
                                                                    Settings={Settings}
                                                                    googleMapLocations={googleMapLocations}
                                                                />
                                                            }

                                                            <div className={`${Settings.isDivideMapsIntotwoParts ? "col-xl-6" : "col-lg-12"}`}>
                                                                <div className={` test-sonu-bingmap table-responsive ${dynamicHeight   ? "map-container" : "" }`} id="bingMapContainer" style={{ height: `${location.pathname.includes("thankyou") || searchParams.get("ispopup") == "true" ? '100vh' : `${(typeof dynamicHeight === "number" ) && dynamicHeight ?  dynamicHeight  : 450 }px`}`  , width: '100%' }}>
                                                                    {(mapLoadder === "BingMap") &&
                                                                        <BingMap
                                                                            pushPins={pushPins}
                                                                            intl={intl}
                                                                            viewOptions={viewOptions}
                                                                            handleMainBox={handleMainBox}
                                                                            locations={locations}
                                                                            iconChanged={Settings.iconChanged}
                                                                            handleCheckBox={handleCheckBox}
                                                                            isChecked={isChecked}
                                                                            distanceType={distanceType}
                                                                            specificActive={specificActive}
                                                                            settings={Settings}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>}
                                              {

                                            !(location.pathname.includes("thankyou")) && searchParams.get("ispopup") != "true" ?
                                            <div className={Settings.displayMap ? `col-sm-3 pe-0 ps-0 order-${Settings.MobileLook.findIndex((x: any) => x.label === "Vertical Address Section") + 1} order-md-1` : "col-sm-12"}>
                                            {Settings.orderChanged !== null && !Settings.sideMapOptionDistanceWithOnlyNumber &&
                                                <div className={ isIframe ? "iframe-address-section" : "address-section"}>
                                                    {
                                                        locations.map((item, index) => {
                                                            item.mapCount = ++sideVerticalListCount;
                                                            if(Settings.isSelectIcon == null){
                                                                if (item[0].carrierService) item[0].carrierService.logoes = item.map((ele: any) => ele.carrierService ? ele.carrierService.logoName : "default_map.png");
                                                            }else if(Settings.isSelectIcon === false){
                                                                if (item[0].pickupPointType) item[0].pickupPointType.logoes = item.map((ele: any) => ele.pickupPointType ? ele.pickupPointType.logoName : "default_map.png");
                                                            }
                                                            
                                                            item.getDistance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
                                                            let distanceType = "";
                                                            if (Settings.distanceChanged) {
                                                                if (item.getDistance <= 1000) {
                                                                    distanceType = `${Math.round(item.getDistance).toFixed(1)} ${config.GetLables(intl, "Meter")}`;
                                                                } else {
                                                                    distanceType = `${(item.getDistance / 1000).toFixed(1)}  ${config.GetLables(intl, "KM")}`;
                                                                }
                                                            } else {
                                                                distanceType = `${(item.getDistance * 0.000621371192).toFixed(1)} ${config.GetLables(intl, "Miles")}`;
                                                            };
                                                            return (
                                                                <div id={item[0].pickUpPointId} key={index} className={active === item[0].pickUpPointId ? "card border mb-2 active border-primary bg-light-primary" : "card border mb-2"}>
                                                                    <div className="card-body p-4">
                                                                        <div className={Settings.isUseNumbers ? "d-flex" : ""}>
                                                                            {
                                                                                Settings.isUseNumbers && <div className="text-center me-3">
                                                                                    <p className="text-center location-counter"> {item.mapCount}  </p>
                                                                                </div>
                                                                            }

                                                                            <div className="w-100">
                                                                                <div className="d-flex justify-content-between align-items-start mb-2">
                                                                                    <div className="w-100">
                                                                                        <h4 className={active === item[0].pickUpPointId ? "text-gray-800 fw-bold mb-1 fs-6 active text-ellipsis" : "text-gray-800 fw-bold mb-1 fs-6 text-ellipsis"}>{item[0].name}</h4>
                                                                                        <span className="fw-semibold text-gray-800 mb-1 fs-6  text-end"><i className={item[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800 me-2 me-2" : "fa fa-car fa-lg text-gray-800 me-2 me-2"}></i> {distanceType}</span>
                                                                                    </div>
                                                                                    {Settings.isLogoShow ?
                                                                                        (item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked) >= 0) ?
                                                                                            <div className="symbol symbol-circle symbol-45px overflow-hidden me-3">
                                                                                                <span><div className="symbol-label">
                                                                                                    {
                                                                                                        Settings.isSelectIcon == null ?
                                                                                                        <img src={item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked) >= 0 ?
                                                                                                            `${item[item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].carrierService.logoName.length > 0 ? config.SeriveImageUrl + item[item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].carrierService.logoName : `/media/avatars/default_map.png`}   `
                                                                                                            :
                                                                                                            item[0].carrierService && item[0].carrierService?.logoName.length > 0 ?
                                                                                                                `${config.SeriveImageUrl}${item[0].carrierService?.logoName}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img> :
                                                                                                        Settings.isSelectIcon === true ?
                                                                                                        <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img>:

                                                                                                        <img src={item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked) >= 0 ?
                                                                                                            `${item[item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].pickupPointType &&  item[item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].pickupPointType.logoName.length > 0 ? config.ImageUrl + item[item.findIndex((x: any) => x.pickUpPointId + x.carrierServiceId === isChecked)].pickupPointType.logoName : `/media/avatars/default_map.png`}   `
                                                                                                            :
                                                                                                            item[0].pickupPointType && item[0].pickupPointType?.logoName.length > 0 ?
                                                                                                                `${config.ImageUrl}${item[0].pickupPointType?.logoName}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img>
                                                                                                    }
                                                                                                    
                                                                                                </div></span>
                                                                                            </div>
                                                                                            :
                                                                                            (item.length === 1) ?
                                                                                                <div className="symbol symbol-circle symbol-45px overflow-hidden me-3 ccc">
                                                                                                    <span><div className="symbol-label">
                                                                                                        {
                                                                                                            Settings.isSelectIcon == null ?
                                                                                                            <img src={item[0].carrierService && item[0].carrierService.logoName.length > 0 ? `${config.SeriveImageUrl}${item[0].carrierService.logoName}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img> :
                                                                                                            Settings.isSelectIcon === true ?
                                                                                                            <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img>:
                                                                                                            <img src={item[0].pickupPointType && item[0].pickupPointType.logoName.length > 0 ? `${config.ImageUrl}${item[0].pickupPointType.logoName}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img>
                                                                                                        }
                                                                                                        
                                                                                                    </div></span>
                                                                                                </div>
                                                                                                :
                                                                                                    <div className="symbol symbol-circle symbol-45px overflow-hidden me-3 aaa">
                                                                                                        <span><div className="symbol-label">
                                                                                                            {
                                                                                                                Settings.isSelectIcon == null ?
                                                                                                                <img id={`${item[0].carrierService ? item[0].carrierService.carrierId + index : index}`} src={item[0].carrierService && item[0].carrierService.logoName.length > 0 ? `${config.SeriveImageUrl}${item[0].carrierService.logoName}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img> :
                                                                                                                Settings.isSelectIcon === true ?
                                                                                                                <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img>:
                                                                                                                <img id={`${item[0].pickupPointType ? item[0].carrierService.carrierId + index : index}`} src={item[0].pickupPointType && item[0].pickupPointType.logoName.length > 0 ? `${config.ImageUrl}${item[0].pickupPointType.logoName}` : `/media/avatars/default_map.png`} alt="error" className="custom-image rounded-circle"></img>
                                                                                                            }
                                                                                                            
                                                                                                        </div></span>
                                                                                                    </div>
                                                                                        : null}
                                                                                </div>
                                                                                <span className={active === item[0].pickUpPointId ? "fw-semibold text-primary fs-6 mb-6  text-start active text-ellipsis" : "fw-semibold text-primary fs-6 mb-6  text-start text-ellipsis"}>{`${item[0].address1} ${item[0].address2} ${item[0].city} ${item[0].postalCode}`}</span>
                                                                            </div>

                                                                        </div>
                                                                        
                                                                        {
                                                                            item.length > 1 ?
                                                                            <div className="d-flex justify-content-between">
                                                                                <div>
                                                                                    {
                                                                                        item.map((element: any, num: number) => {
                                                                                        if (element.carrierService) {
                                                                                            counter++;
                                                                                            return (
                                                                                                <div key={num}>
                                                                                                    <div className="mb-4 location-checkbox d-inline-block">
                                                                                                        <div className="form-check form-check-form-check form-check-custom form-check-success form-check-sm">
                                                                                                            <input
                                                                                                                data-checkbox={JSON.stringify(element)}
                                                                                                                data-custom-id={`checkbox-${element.pickUpPointId + element.carrierServiceId + counter}`}
                                                                                                                className="form-check-input" type="checkbox"
                                                                                                                id={`checkbox-${element.pickUpPointId + element.carrierServiceId}`}
                                                                                                                checked={active === item[0].pickUpPointId && item.length === 1 ? true : isChecked === element.pickUpPointId + element.carrierServiceId}
                                                                                                                onChange={(e) => {
                                                                                                                    e.stopPropagation();
                                                                                                                    handleCheckBox(item, element, num, null, item.mapCount);
                                                                                                                }} />
                                                                                                            <label className="form-check-label text-black" htmlFor={`checkbox-${element.pickUpPointId + element.carrierServiceId}`}>
                                                                                                                {/* {element.carrierService?.label} */}
                                                                                                                {element.carrierService?.name}
                                                                                                            </label>
                                                                                                        </div>
                                                                                                    </div></div>
                                                                                            )
                                                                                        };
                                                                                        return null;
                                                                                        })
                                                                                    }
                                                                                </div>

                                                                            </div>
                                                                            : null
                                                                        }

                                                                        {Settings.showImages && false && <div className="text-center"><img src={item[0].locationImage === null ? `${config.DefaultLocationImage}` : item[0].locationImage} alt="Error" className="mb-4 rounded" style={item[0].locationImage === null ? { width: '50%' } : { width: '100%', height: '100%' }} /></div>}
                                                                        {
                                                                            Settings.isSideHours ?
                                                                            item[0].pickupPointSchedules1 ?
                                                                                    <table className="table table-striped vertical-list-time-box-table">
                                                                                        <tbody>
                                                                                            {Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                                                                                                let properties: any = config.ShopTimeSchedule[key].split(",");
                                                                                                return (
                                                                                                    <tr key={i}>
                                                                                                        <th scope="row"> <GetLable label={properties[0]} /></th>
                                                                                                        <td>{item[0].pickupPointSchedules1[properties[1]]} - {item[0].pickupPointSchedules1[properties[2]]}</td>
                                                                                                        <td>{item[0].pickupPointSchedules1[properties[3]]} - {item[0].pickupPointSchedules1[properties[4]]}</td>
                                                                                                    </tr>

                                                                                                )
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table> : 
                                                                                 <div className="card-body p-2 h-100"><div className="h-100 d-flex align-items-center text-center w-100"><div className="w-100"> Schedules are not <br />available  </div></div></div>

                                                                                : null

                                                                        }
                                                                        {item.length === 1 ?
                                                                        <button
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                // let selectedPoint = {...item};
                                                                                //      selectedPoint.pickupPointSchedules1 = Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                                                                                //          let properties: any = config.ShopTimeSchedule[key].split(",");
                                                                                //          const newKey = config.GetLables(intl, properties[0]);
                                                                                //          return { [newKey]: [selectedPoint.pickupPointSchedules1[properties[1]] + " - " + selectedPoint.pickupPointSchedules1[properties[2]] , selectedPoint.pickupPointSchedules1[properties[3]] +" - "+ selectedPoint.pickupPointSchedules1[properties[4]]] };
                                                                                //      });
                                                                                // window.top?.postMessage(`{ "type": "crossdesk-map-relaypoint-selection", "point": ${JSON.stringify(selectedPoint)} }`, '*')
                                                                                handleMainBox(item);
                                                                            }}
                                                                            id={item[0].pickUpPointId + "button"}
                                                                            data-vertical-list={JSON.stringify(item[0])}
                                                                            ref={item[0].pickUpPointId === searchParams.get("id") ? divRef : null}
                                                                            className={`btn btn-sm fw-bold  w-100 text-uppercase p-2 ${isDisableSelectButton(item, isChecked, active) ? "btn-secondary" : "btn-primary"}`}>{active === item[0].pickUpPointId ? <GetLable label="selected" /> : <GetLable label="Select" />}</button>

                                                                            :
                                                                        <button disabled={isDisableSelectButton(item, isChecked, active)}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                                window.top?.postMessage(`{ "type": "crossdesk-map-relaypoint-selection", "point": ${JSON.stringify(selectedItem)} }`, '*')
                                                                                // handleMainBox(item);
                                                                            }}
                                                                            id={item[0].pickUpPointId + "button"}
                                                                            data-vertical-list={JSON.stringify(item[0])}
                                                                            ref={item[0].pickUpPointId === searchParams.get("id") ? divRef : null}
                                                                            className={`btn btn-sm fw-bold  w-100 text-uppercase p-2 ${isDisableSelectButton(item, isChecked, active) ? "btn-secondary" : "btn-primary"}`}>{active === item[0].pickUpPointId ? <GetLable label="selected" /> : <GetLable label="Select" />}</button>
                                                                        }
                                                                        </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>}

                                            {Settings.orderChanged !== null && Settings.sideMapOptionDistanceWithOnlyNumber &&
                                                <div className={ isIframe ? "iframe-address-section" : "address-section"}>
                                                    {
                                                        locations.map((item, index) => {
                                                            if(Settings.isSelectIcon == null){
                                                                if (item[0].carrierService) item[0].carrierService.logoes = item.map((ele: any) => ele.carrierService ? ele.carrierService.logoName : "default_map.png");
                                                            }else if(Settings.isSelectIcon == false){
                                                                if (item[0].pickupPointType) item[0].pickupPointType.logoes = item.map((ele: any) => ele.pickupPointType ? ele.pickupPointType.logoName : "default_map.png");
                                                            }
                                                            item.getDistance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
                                                            let distanceType = "";
                                                            if (Settings.distanceChanged) {
                                                                if (item.getDistance <= 1000) {
                                                                    distanceType = `${Math.round(item.getDistance).toFixed(1)} ${config.GetLables(intl, "Meter")}`;
                                                                } else {
                                                                    distanceType = `${(item.getDistance / 1000).toFixed(1)}  ${config.GetLables(intl, "KM")}`;
                                                                }
                                                            } else {
                                                                distanceType = `${(item.getDistance * 0.000621371192).toFixed(1)} ${config.GetLables(intl, "Miles")}`;
                                                            };
                                                            return (
                                                                <div id={item[0].pickUpPointId} key={index} className={active === item[0].pickUpPointId ? "card border mb-2 active border-primary bg-light-primary" : "card border mb-2"}>
                                                                    <div className="card-body p-4 cursor-pointer" onClick={(e) => {
                                                                        if (Settings.sideMapOptionDistanceWithOnlyNumber) {
                                                                            if (!(active === item[0].pickUpPointId)) {
                                                                                e.stopPropagation();
                                                                                handleMainBox(item);
                                                                            }
                                                                        } else {
                                                                            e.stopPropagation();
                                                                            handleMainBox(item);
                                                                        }
                                                                    }}>
                                                                        <div className="text-center mb-3 mt-3 d-flex justify-content-center">
                                                                            <p className="text-center location-counter"> {item[0].mapCount}  </p>
                                                                        </div>
                                                                        <span className="fw-semibold text-center text-white-600 fs-6 d-block text-star text-gray-600 mb-2"><i className={item[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800 m-3" : "fa fa-car fa-lg text-gray-800 m-3"}></i>{distanceType}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            }
                                        </div>
                                            :
                                            null

                                              }                          
                                     
                                            {
                                                Settings.bottomManuChanged &&
                                                <div className={`col-lg-12 order-${Settings.MobileLook.findIndex((x: any) => x.label === "Horizontal Address Section") + 1} order-md-2 mt-3`}>

                                                    <div className={`p-0 pt-0 pb-0 mb-0 d-flex align-items-start h-100 scrollContainerRight`} id="bottomScrollContainerRight" style={{ overflowX: "auto", scrollBehavior: "smooth" }}>
                                                        {
                                                            locations.map((item, num) => {

                                                                item.getDistance = getDistance({ latitude: parseFloat(String(latitude)), longitude: parseFloat(String(longitude)) }, { latitude: parseFloat(String(item[0].positionX)), longitude: parseFloat(String(item[0].positionY)) });
                                                                let distanceType = "";
                                                                if (Settings.distanceChanged) {
                                                                    if (item.getDistance <= 1000) {
                                                                        distanceType = `${Math.round(item.getDistance).toFixed(1)} ${config.GetLables(intl, "Meter")}`;
                                                                    } else {
                                                                        distanceType = `${(item.getDistance / 1000).toFixed(1)}  ${config.GetLables(intl, "KM")}`;
                                                                    }
                                                                } else {
                                                                    distanceType = `${(item.getDistance * 0.000621371192).toFixed(1)} ${config.GetLables(intl, "Miles")}`;
                                                                };
                                                                    return item.map((element: any, index: number) => {
                                                                        element.mapCount = ++bottomHorizontalListCount;
                                                                        if (element.carrierService) {
                                                                            return (
                                                                                <div id={`${element.pickUpPointId}horizontalList`} horizontal-list-data={JSON.stringify(element)} key={index} onClick={() => {
                                                                                    setActive("");
                                                                                    setIsChecked("");
                                                                                    if (specificActive === `${element.pickUpPointId}(${num})`) {
                                                                                        if (!Settings.bottomMapOptionDistanceWithOnlyNumber) {
                                                                                            setSpecificActive("")
                                                                                        }
                                                                                    }
                                                                                    else {
                                                                                        setSpecificActive(`${element.pickUpPointId}(${num})`);
                                                                                    }
                                                                                    if (Settings.bottomMapOptionDistanceWithOnlyNumber) {
                                                                                        if (!(specificActive === `${element.pickUpPointId}(${num})`)) {
                                                                                            handleSpecificLocations(item, index, specificActive === `${element.pickUpPointId}(${num})`, element.mapCount);
                                                                                        }
                                                                                    } else {
                                                                                        handleSpecificLocations(item, index, specificActive === `${element.pickUpPointId}(${num})`, element.mapCount);
                                                                                    }
                                                                                }}
                                                                                    className={((!Settings.bottomMapOptionDistanceWithOnlyNumber || specificActive) ? specificActive === `${element.pickUpPointId}(${num})` : `${locations[0][0].pickUpPointId}(0)` === `${element.pickUpPointId}(${num})`) ? "btn btn-outline btn-sm border-primary bg-light-primary me-2 px-2 text-start top-location h-100" : "btn btn-outline btn-sm btn-outline-dashed btn-outline-default me-2 mb-2 px-2 text-start top-location h-100"}
                                                                                    style={{ borderRadius: "0.25rem" }}>
                                                                                    {
                                                                                        Settings.bottomMapOptionDistanceWithOnlyNumber ?
                                                                                            <>
                                                                                                <div className="text-center mb-3 mt-3 d-flex justify-content-center">
                                                                                                    <p className="text-center location-counter"> {element.mapCount}</p>
                                                                                                </div>
                                                                                                <span className="fw-semibold text-center text-white-600 fs-6 d-block text-star text-gray-600 mb-2"><i className={item[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800" : "fa fa-car fa-lg text-gray-800"}></i>{distanceType}</span>
                                                                                            </>
                                                                                            :
                                                                                            <div className="d-flex justify-content-between flex-column h-100">
                                                                                                <div className="d-flex justify-content-between align-items-start w-100 h-100">
                                                                                                    <div className="me-2 d-flex flex-column justify-content-between h-100">
                                                                                                        <div className="d-flex justify-content-between">
                                                                                                            <div>
                                                                                                                <span className={specificActive === `${element.pickUpPointId}(${num})` ? "text-gray-800 fw-bold mb-1 fs-6 text-wrap text-ellipsis" : "text-gray-800 fw-bold mb-0 fs-6 text-start text-wrap text-ellipsis"}>{item[0].name}</span>
                                                                                                                <span className={active === item[0].pickUpPointId ? "fw-semibold text-primary fs-6 text-start active text-ellipsis" : "fw-semibold text-primary fs-6 mb-2  text-start text-ellipsis"}>{`${item[0].address1} ${item[0].address2} ${item[0].city} ${item[0].postalCode}`}</span>
                                                                                                            </div>
                                                                                                            {
                                                                                                                Settings.isLogoShow ?
                                                                                                                    <>
                                                                                                                        <div className="symbol symbol-circle symbol-30px">
                                                                                                                            <span><div className="symbol-label">
                                                                                                                            {
                                                                                                                                Settings.isSelectIcon == null ?
                                                                                                                                <img src={element.carrierService && element.carrierService.logoName.length > 0 ? `${config.SeriveImageUrl}${element.carrierService.logoName}` : `/media/avatars/default_map.png`} alt="error" className=" w-100  rounded-circle"></img> :
                                                                                                                                Settings.isSelectIcon == true ?
                                                                                                                                <img src={Settings.MapPointerIcon && Settings.MapPointerIcon !== "default_map.png" ? Settings.MapPointerIcon.includes("http") ? `${Settings.MapPointerIcon}` : `${config.CustomPointerImageUrl}${Settings.MapPointerIcon}` : `/media/avatars/default_map.png`} alt="error" className=" w-100  rounded-circle"></img>:
                                                                                                                                <img src={element.pickupPointType && element.pickupPointType.logoName.length > 0 ? `${config.ImageUrl}${element.pickupPointType.logoName}` : `/media/avatars/default_map.png`} alt="error" className=" w-100  rounded-circle"></img>
                                                                                                                            }
                                                                                                                            </div></span>
                                                                                                                        </div>


                                                                                                                    </>
                                                                                                                    : null
                                                                                                            }

                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div className="position-relative mb-2">
                                                                                                        

                                                                                                                <div className={`card border-0 time-box ${isShowTimeBox.isShow && isShowTimeBox.timeBoxId === `${element.pickUpPointId}timeBox${num}` ? "d-block" : "d-none"}`}>
                                                                                                                    <div className="card-body p-2">
                                                                                                                        {
                                                                                                                            item[0].pickupPointSchedules1 ?
                                                                                                                                <table className="table table-striped horizontal-list-time-box-table">
                                                                                                                                    <tbody>
                                                                                                                                        {Object.entries(config.ShopTimeSchedule).map(([key, value], i) => {
                                                                                                                                            let properties: any = config.ShopTimeSchedule[key].split(",");
                                                                                                                                            return <tr key={i}>
                                                                                                                                                <th scope="row"> <GetLable label={properties[0]} /></th>
                                                                                                                                                <td>{item[0].pickupPointSchedules1[properties[1]]} - {item[0].pickupPointSchedules1[properties[2]]}</td>
                                                                                                                                                <td>{item[0].pickupPointSchedules1[properties[3]]} - {item[0].pickupPointSchedules1[properties[4]]}</td>
                                                                                                                                            </tr>
                                                                                                                                        })}
                                                                                                                                    </tbody>
                                                                                                                                </table>
                                                                                                                                :
                                                                                                                                <div className="card-body p-2 h-100"><div className="h-100 d-flex align-items-center text-center w-100"><div className="w-100"> Schedules are not <br />available  </div></div></div>
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className="d-flex justify-content-between">
                                                                                                      
                                                                                                                <div className="fw-semibold text-white-600 fs-6 d-block text-star text-gray-600 mb-2 d-flex">
                                                                                                                {
                                                                                                                    Settings.isTopHours &&
                                                                                                                    <span className="d-inline-block"
                                                                                                                    onTouchStart={()=>{ setIsTimeBox({ isShow: true, timeBoxId: `${element.pickUpPointId}timeBox${num}` });}}
                                                                                                                    onTouchEnd={()=>{ setIsTimeBox({ isShow: false, timeBoxId: "" });}}
                                                                                                                    onMouseEnter={() => { setIsTimeBox({ isShow: true, timeBoxId: `${element.pickUpPointId}timeBox${num}` }); }} onMouseOut={() => { setIsTimeBox({ isShow: false, timeBoxId: "" }); }} >
                                                                                                                        <i className="fa-solid fa-circle-info line-height-normal fa-lg"></i>
                                                                                                                    </span>
                                                                                                                }
                                                                                                                    <i className={item[0].distanceByLatLog <= 1000 ? "fa-solid fa-person-walking fa-lg text-gray-800 mt-1" : "fa fa-car fa-lg text-gray-800 mt-1"}></i>
                                                                                                                    <span>{distanceType}</span>
                                                                                                                </div>
                                                                                                                {
                                                                                                                    Settings.isUseNumbers &&
                                                                                                                    <div className="text-center">
                                                                                                                        <p className="text-center location-counter mb-2"> {element.mapCount}  </p>
                                                                                                                    </div>
                                                                                                                }

                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <span className="fw-semibold fs-6 text-start btn-primary rounded text-white px-2 text-ellipsis custom-min-height">
                                                                                                    {/* {element.carrierService?.label} */}
                                                                                                    {element.carrierService?.name}
                                                                                                    </span>
                                                                                            </div>
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }
                                                                        return null;
                                                                });
                                                            })
                                                        }
                                                    </div>

                                                </div>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>

        </>
    )
};
